"use strict";

//#region Eager loading
pos.run(function (posConfig, $injector, $log, eventBus) {
  loadConfig();
  function loadConfig() {
    posConfig.whenReady().then(function () {
      var sfShopFeature = posConfig.getShopFeatures().swedishFiscalisation;
      if (sfShopFeature && sfShopFeature.values.active) {
        $log.debug("Eager loading Swedish Fiscalisation Service.");
        return $injector.get("swedishFiscalisation");
      }
    });
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
});
//#endregion

pos.service("swedishFiscalisation", ["eventBus", "$log", "posConfig", function (eventBus, $log, posConfig) {
  var config = null;
  posConfig.whenReady().then(function () {
    init();
    loadConfig();
  });
  function init() {
    eventBus.subscribe(eventBus.events.pos.newConfig, null, function () {
      return loadConfig();
    });
    eventBus.subscribe(eventBus.events.sale.reprint, null, function (ev, receipt) {
      return onReprint(ev, receipt);
    });
    eventBus.subscribe(eventBus.events.sale.reprintOffline, null, function (ev) {
      return onAttemptReprintOffline(ev);
    });
  }
  function loadConfig() {
    var sf = posConfig.getShopFeatures().swedishFiscalisation;
    var newConfig = sf && sf.values ? sf.values : null;
    if (config && config.active != newConfig.active) {
      init();
    }
    config = newConfig;
  }
  function onReprint(ev, receipt) {
    if (config.active && receipt.reprinted >= config.reprintsAllowed) {
      $log.debug("Prevented reprint due to Swedish reprint policy.");
      ev.preventDefault();
    } else {
      $log.debug("Allowing reprint");
    }
  }
  function onAttemptReprintOffline(ev) {
    if (config.active) {
      $log.debug("Prevented offline reprint due to Swedish reprint policy.");
      ev.preventDefault();
    }
  }
  return {};
}]);
