"use strict";

pos.value("events", {
  sale: {
    completing: "sale.completing",
    isPrinting: "sale.isPrinting",
    donePrinting: "sale.donePrinting",
    completed: "sale.completed",
    reprint: "sale.reprint",
    reprintOffline: "sale.reprintoffline",
    itemDeleted: "sale.itemDeleted",
    cancelled: "sale.cancelled",
    completingOnDevice: "sale.completing-on-device",
    downPaymentPrint: "sale.downPaymentPrint",
    close: "sale.close"
  },
  customer: {
    attach: "customer.attach",
    detach: "customer.detach",
    updated: "customer.updated",
    beforeSelect: "customer.beforeSelect",
    afterSelect: "customer.afterSelect",
    selected: "customer.selected"
  },
  quagga: {
    enable: "quagga.enable",
    disable: "quagga.disable"
  },
  pos: {
    itemSelected: "pos.itemSelected",
    cashStatementStart: "pos.cashStatementStart",
    newConfig: "pos.newConfig",
    login: "pos.login",
    showingQty: "pos.showingQty",
    managerVerificationRequested: "pos.managerVerificationRequested",
    managerVerificationResponse: "pos.managerVerificationResponse",
    clerkVerificationRequested: "pos.clerkVerificationRequested",
    clerkVerificationResponse: "pos.clerkVerificationResponse",
    modalInputFocusRequested: "pos.modalInputFocusRequested",
    promotionBarcodeScanned: "pos.promotionBarcodeScanned",
    rangedValueInputRequested: "pos.rangedValueInputRequested",
    rangedValueInputResponse: "pos.rangedValueInputResponse",
    pinRequested: "pos.pinRequested",
    pinResponse: "pos.pinResponse"
  },
  voucher: {
    sale: "voucher.sale",
    deactivate: "voucher.deactivate",
    retryDeactivation: "voucher.retryDeactivation",
    printFailure: "voucher.printFailure",
    retryAction: "voucher.retryAction",
    printAction: "voucher.printAction"
  },
  payment: {
    cancel: "payment.cancel",
    verifone: {
      voiceReferralApproval: "payment.verifone.voiceReferralApproval",
      voiceReferralApprovalResponse: "payment.verifone.voiceReferralApprovalResponse",
      signatureApproval: "payment.verifone.signatureApproval",
      signatureApprovalResponse: "payment.verifone.signatureApprovalResponse"
    },
    paymentInputRequired: "payment.paymentInputRequired"
  },
  article: {
    addToReceipt: "article.addToReceipt",
    addToReceiptBarcode: "article.addToReceiptBarcode",
    increase: "article.increase"
  },
  downpayment: {
    finishTransaction: "downpayment.finishTransaction",
    openBooking: "downpayment.openbooking"
  },
  keyboard: {
    hidden: "keyboard.hidden"
  },
  toaster: {
    clicked: "toaster.clicked"
  }
});
