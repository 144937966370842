"use strict";

pos.factory("infrasecRepository", function (backend, posConfig) {
  var repository = {};
  repository.api = {
    signReceipt: function signReceipt(receipt, registerId, organizationNumber, storePayload, copy) {
      var query = "";
      if (storePayload) query += (query === "" ? "?" : "&") + "storePayload=" + storePayload;
      return backend.api.post("api/infrasec" + query, {
        items: receipt.items,
        receiptId: receipt.receiptId,
        registerId: registerId,
        organizationNumber: organizationNumber,
        isCopy: copy,
        date: receipt.date,
        shopId: posConfig.shopId,
        posId: posConfig.posId
      });
    }
  };
  return repository;
});
