"use strict";

pos.factory("zreportService", function (backend, zreportRepository) {
  var service = {};
  service.reprintLastZReport = function () {
    return zreportRepository.api.getZReport(backend.getCurrentDevice().mac).then(function (response) {
      return zreportRepository.device().printZReport({
        xzReport: response.data
      });
    });
  };
  return service;
});
