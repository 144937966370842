"use strict";

pos.controller("DebugController", function ($scope, $log) {
  $scope.clearLocalStorage = function () {
    localStorage.clear();
  };
  $scope.localStorage = [];
  $scope.getLocalStorage = function () {
    var ls = [];
    for (var i = 0; i < localStorage.length; i++) {
      var key = localStorage.key(i);
      $log.debug(key);
      var value = localStorage.getItem(localStorage.key(i));
      ls.push({
        key: key,
        value: value
      });
    }
    $scope.localStorage = ls;
    $log.debug($scope.localStorage);
  };
  $scope.getLocalStorage();
});
