"use strict";

pos.factory("discountOptionsRepository", function (backend) {
  var discountOptions = {};
  discountOptions.api = {
    getDiscountOptions: function getDiscountOptions() {
      return backend.api.get("api/discountoptions/posdiscountoptions");
    }
  };
  return discountOptions;
});
