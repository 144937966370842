"use strict";

pos.value("promptEveryType", Object.freeze({
  transaction: 1,
  line: 2
}));
pos.value("itemInfoType", Object.freeze({
  info: 1,
  question: 2
}));
