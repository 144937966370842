"use strict";

pos.directive("toggleNavigation", function (toggleNavigation, backdrop) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function () {
        toggleNavigation.toggle(angular.element(attrs.toggleNavigation), attrs.navigationClass);
      });
    }
  };
});
pos.directive("openNavigation", function (toggleNavigation, backdrop) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function (event) {
        toggleNavigation.open(angular.element(attrs.toggleNavigation), attrs.navigationClass);
      });
    }
  };
});
pos.directive("closeNavigation", function (toggleNavigation, backdrop) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function () {
        toggleNavigation.close(angular.element(attrs.closeNavigation), attrs.navigationClass);
      });
    }
  };
});
