"use strict";

pos.factory("terminalActionService", function (terminalActionRepository) {
  var service = {};
  createAction("terminalEndOfDay");
  createAction("terminalCancellationRequest");
  createAction("terminalXReport");
  createAction("terminalSendOfflineTransactions");
  createAction("terminalDownloadApplication");
  createAction("terminalEmptyPrintBuffer");
  createAction("terminalPrintLatestReceipt");
  createAction("terminalZReport");
  createAction("terminalPrintStoresTransactions");
  createAction("terminalDownloadCardAgreements");
  service.getTerminalStatus = function (deviceId, sessionId) {
    return terminalActionRepository.getTerminalStatus(deviceId, sessionId);
  };
  function createAction(action) {
    service[action] = function () {
      return terminalActionRepository.device()[action]();
    };
  }
  service.printTerminalJournal = function (from, to) {
    return terminalActionRepository.device().printTerminalJournal(from, to);
  };
  service.printReceiptJournal = function (from, to) {
    return terminalActionRepository.device().printReceiptJournal(from, to);
  };
  service.printSAFT = function (company, from, to) {
    return terminalActionRepository.device().printSAFT(company, from, to);
  };
  service.getSAFTFileName = function (company, from, to) {
    return terminalActionRepository.device().getSAFTFileName(company, from, to);
  };
  return service;
});
