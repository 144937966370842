"use strict";

pos.directive("buttonState", function () {
  "use strict";

  return {
    restrict: "A",
    controller: function controller($scope, posConfig, clerkLoginService, eventBus) {
      var clerkButtonStates = clerkLoginService.getButtonStates();
      if (!_.isEmpty(clerkButtonStates)) {
        $scope.buttonStatesList = clerkButtonStates;
      } else {
        var loadConfig = function loadConfig() {
          $scope.buttonStatesList = posConfig.getOptions().buttonStates;
        };
        loadConfig();
        eventBus.subscribe(eventBus.events.pos.newConfig, $scope, loadConfig);
      }
    },
    link: function link(scope, ele, attrs) {
      scope.buttonStatesList && scope.buttonStatesList[attrs.buttonState] === false && ele.addClass("button-state__disabled");
    }
  };
});
