"use strict";

pos.factory("defaultCardPaymentType", function (receipt, sales, $q, $timeout, $translate, $log, notification, verifoneService, inputValidationService, continuationResults, posConfig, $rootScope, keypadManager, cardTransactionStatus) {
  var service = {};
  var i18nSurcharge;
  var i18nTranslations;
  var i18nPopupHeading;
  $translate(["label/surcharge", "terminal/requestSignature", "terminal/requestMerchantSignature", "terminal/requestIdentification", "terminal/requestIdentificationAndRequestSignature", "terminal/popupTextHeading", "terminal/checkOriginalReceiptForEftTransaction"]).then(function (translations) {
    i18nSurcharge = translations["label/surcharge"];
    i18nPopupHeading = translations["terminal/popupTextHeading"];
    i18nTranslations = translations;
  });
  service.handleCardPayment = function handleCardPayment(paymentState) {
    $log.debug("defaultCard: in handleCardPayment");
    $log.debug(paymentState);
    cardTransactionStatus.start();
    keypadManager.hide();
    var deferred = $q.defer();
    var cardPayment = paymentState.response.data.d;
    cardPayment.cardReceipt = "";
    // make sure that we always have a card status no matter which case we return from
    paymentState.cardStatus = {};

    // we use the closure to keep the cardPayment reference in the scope throughout the recursion
    function getTerminalStatus() {
      cardPayment.awaitingResponse = true;
      sales.getTerminalStatus(cardPayment.paymentDeviceId, cardPayment.message).then(function (response) {
        var status = response.data.d;
        $timeout(function () {
          $log.debug('current: "' + cardPayment.displayText + '" status: "' + status.displayText + '"');
          cardPayment.displayText = status.displayText || cardPayment.displayText;
        });
        if (cardPayment.cardReceipt && status.bonText) cardPayment.cardReceipt += "<CLIP>";
        cardPayment.cardReceipt += status.bonText;
        cardPayment.cardNumber = status.cardId || cardPayment.cardNumber;
        cardPayment.cardName = status.cardName || cardPayment.cardName;
        // delete because we don't want to persist this temporary property in localStorage
        delete cardPayment.awaitingResponse;

        // The terminal has sent a popup message show it to the clerk
        if (status.popupText && status.popupText !== "0") {
          var popupText = i18nTranslations[status.popupText] || status.popupText;
          notification.pop("info", i18nPopupHeading, popupText, 6000);
        }
        if (status.result === 0) {
          if (status.surchargeAmount) {
            var surcharge = new Decimal(status.surchargeAmount).dividedBy(100);
            cardPayment.amount = cardPayment.amount.plus(surcharge);
            receipt.addItemGroup(surcharge, i18nSurcharge, 9918);
          }
          cardPayment.completed = true;
          receipt.persist();
          cardTransactionStatus.stop();
          deferred.resolve(paymentState);
        } else if (status.result > 0) {
          cardPayment.rejected = true;
          receipt.persist();
          paymentState.cardStatus = status;
          cardTransactionStatus.stop();
          deferred.reject(paymentState);
        } else {
          if (status.continuationResult == continuationResults.referral) {
            verifoneService.startVoiceReferralFlow(status, cardPayment).then(function () {
              return getTerminalStatus();
            });
          } else if (status.continuationResult == continuationResults.signatureRequired) {
            verifoneService.startSignatureFlow(status, cardPayment).then(function () {
              return getTerminalStatus();
            });
          } else {
            getTerminalStatus();
          }
        }
      }, function (error) {
        delete cardPayment.awaitingResponse;
        receipt.persist();
        deferred.reject(paymentState);
      });
    }
    getTerminalStatus();
    var cancellationEvent = $rootScope.$on("payment:card:abort", function () {
      sales.abortCardPayment(cardPayment.paymentDeviceId, cardPayment.message);
    });
    deferred.promise["finally"](function () {
      return cancellationEvent();
    });
    return deferred.promise;
  };
  service.cardPaymentRejected = function cardPaymentRejected(paymentState) {
    $log.debug("defaultCard: in cardPayment rejected");
    var rejection = paymentState.response;
    // sanity check the data before trying to recover
    if (!rejection || !rejection.data || !rejection.data.d) return $q.reject(paymentState);
    var cardPayment = rejection.data.d;
    if (cardPayment.cardReceipt) sales.printTerminalReceipt(cardPayment.cardReceipt);

    // Nets offline handling
    if (cardPayment.cardNumber === 601 && paymentState.cardStatus.responseCode === "Z3") {
      paymentState.cardOffline = true;
      return $q.reject(paymentState);
    }
    cardPayment.displayText = paymentState.cardStatus.errorText || cardPayment.displayText;
    paymentState.cardRejected = true;
    return $q.reject(paymentState);
  };
  service.validateAmount = function (amount) {
    if (!posConfig.getOptions().allowPaymentCardOvercharge) {
      var receiptTotal = receipt.getTotal();
      var paymentTotal = receipt.getPaymentsTotal();
      var remainder = receiptTotal.minus(paymentTotal);
      var amountDec = inputValidationService.convertToDecimal(amount);
      var canProceed = amountDec.lte(remainder.abs());
      return canProceed ? $q.resolve() : $q.reject("OVERCHARGE_NOT_ALLOWED");
    } else {
      return $q.resolve();
    }
  };
  service.validatePaymentChoice = function (choice, remainder, currency) {
    if (cardTransactionStatus.isInProgress()) {
      //Clear flag
      cardTransactionStatus.stop();
      throw "CARD_FAULT_STATE";
    }
    return true;
  };
  return service;
});
