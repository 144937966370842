"use strict";

pos.factory("webOrderWidgetService", function (webOrderRepository, posConfig, backend, receipt, webOrderStatusEnum, eventBus, findCustomerService, posStorage, fluctuatingPropertiesHelper) {
  var STORAGE = "weborders";
  var weborderModal = null;
  var service = {};
  service.getWebOrders = function () {
    return webOrderRepository.api.getWebOrders().then(function (response) {
      response.data.date = new Date(response.data.date);
      return response.data;
    });
  };
  service.getMoreWebOrders = function (searchParams, after, recordCount) {
    var firstName = !searchParams.firstName ? "" : searchParams.firstName.value;
    var lastName = !searchParams.lastName ? "" : searchParams.lastName.value;
    var email = !searchParams.email ? "" : searchParams.email.value;
    var phoneNo = !searchParams.phoneNo ? "" : searchParams.phoneNo.value;
    var orderNo = !searchParams.orderNo ? "" : searchParams.orderNo.value;
    var externalUniqueId = "";
    var cust = receipt.getCustomer();
    if (cust.customerNumber) {
      externalUniqueId = cust.customerNumber;
    }
    return webOrderRepository.api.getWebOrders(firstName, lastName, email, phoneNo, orderNo, externalUniqueId).then(function (response) {
      if (response) {
        response.data.date = new Date(response.data.date);
        return response.data;
      }
      return [];
    });
  };
  service.resetLines = function (webOrderLine) {
    var cachedWebOrders = posStorage.get(STORAGE) || [];
    var webOrderIdx = cachedWebOrders.findIndex(function (o) {
      return o.orderNo == webOrderLine.webOrderNumber;
    });
    if (webOrderIdx > -1) {
      var weborder = cachedWebOrders.splice(webOrderIdx, 1)[0];
      weborder.webOrderLines.forEach(function (line) {
        return line.status = webOrderStatusEnum.reserved;
      });
      webOrderRepository.api.update(weborder);
      posStorage.set(STORAGE, cachedWebOrders);
    }
  };
  service.skipWebOrder = function (webOrder) {
    if (!webOrder) return;
    return webOrderRepository.api.skip(webOrder);
  };
  service.printPickList = function (webOrder) {
    if (!webOrder) return;
    var deviceIndex = backend.defaultDevice;
    return webOrderRepository.api.printPickList(deviceIndex, webOrder, posConfig.printTranslations);
  };

  // All web order lines are picked when adding to receipt
  // TODO: unpick them if it gets removed from the receipt
  service.addToReceipt = function (webOrder) {
    if (!webOrder || this.isAddedToReceipt(webOrder)) return;
    webOrder.webOrderLines.forEach(function (line) {
      return line.status = webOrderStatusEnum.picked;
    });
    var promise = webOrderRepository.api.update(webOrder);
    var price = this.sumTotal(webOrder.webOrderLines);
    var vatAmount = this.sumVatAmount(webOrder.webOrderLines);
    var averageVatPercentage = price - vatAmount > 0 ? vatAmount * (100 / (price - vatAmount)) : 0;

    //load customer to receipt when the weborder contains a customer
    if (webOrder.externalUniqueId) {
      var cust = receipt.getCustomer();
      if (!cust.customerNumber) {
        findCustomerService.getCustomerByNumber(webOrder.externalUniqueId).then(function (customer) {
          receipt.attachCustomer(customer.externalUniqueId, customer.emailAddress);
          eventBus.notify(eventBus.events.customer.attach, customer);
        });
      }
    }
    cacheWebOrderObj(webOrder);
    receipt.addWebOrder(price, webOrder.itemGroup, webOrder.orderNo, webOrder.webShopId, 0, averageVatPercentage);
    return promise;
  };
  service.isAddedToReceipt = function (webOrder) {
    return receipt.getReceipt().items.findIndex(function (item) {
      return item.webOrderNumber === webOrder.orderNo;
    }) > -1;
  };
  service.sumTotal = function (webOrderLines) {
    if (!webOrderLines) return 0;
    return webOrderLines.filter(function (line) {
      return line.status != webOrderStatusEnum.skipped;
    }).reduce(function (total, orderLine) {
      return total + orderLine.effectiveSalesPrice;
    }, 0);
  };
  service.sumVatAmount = function (webOrderLines) {
    if (!webOrderLines) return 0;
    return webOrderLines.filter(function (line) {
      return line.status != webOrderStatusEnum.skipped;
    }).reduce(function (total, orderLine) {
      return total + orderLine.vatAmount;
    }, 0);
  };

  // Updates all weborders on the receipt to sold
  service.onSaleCompleted = function (finishedReceipt) {
    var orderNos = [];
    receipt.getReceipt().items.filter(function (item) {
      return item.webOrderNumber && item.source === "weborder";
    }).forEach(function (item) {
      return orderNos.push({
        orderNo: item.webOrderNumber,
        webShopId: item.webShopId
      });
    });
    posStorage.remove(STORAGE);
    if (orderNos.length) webOrderRepository.api.saleCompleted(orderNos);
  };
  service.onSaleCancelled = function (e, receipt) {
    var webOrderLines = receipt.items.filter(function (item) {
      return item.webOrderNumber && item.source == "weborder";
    });
    webOrderLines.forEach(function (line) {
      return service.resetLines(line);
    });
  };
  function cacheWebOrderObj(weborder) {
    var currentWeborders = posStorage.get(STORAGE) || [];
    currentWeborders.push(weborder);
    posStorage.set(STORAGE, currentWeborders);
  }
  service.getWeborderModal = function (type) {
    weborderModal = {};
    weborderModal.type = type;
    weborderModal.isVisible = false;
    weborderModal.location = posStorage.get("current_shop").description;
    weborderModal.imageUrl = posStorage.get("current_shop").imageUrl;
    weborderModal.webOrderDispatchNoteLines = posConfig.getWebOrderDispatchNoteLines();
    weborderModal.buttons = [];
    if (weborderModal.type === "picklist") {
      weborderModal.buttons.push({
        label: "widget/weborder/receiptprinter",
        type: "success",
        isDefault: false,
        closes: true,
        action: function action(webOrder) {
          service.printPickList(webOrder);
        }
      });
    }
    weborderModal.buttons.push({
      label: "weborder/windowsprinter",
      type: "success",
      isDefault: false,
      closes: true,
      action: function action(webOrder) {
        var newWindow = window.open();
        newWindow.document.write(document.getElementsByTagName("weborder-view")[0].innerHTML);
        setTimeout(function () {
          newWindow.print();
          newWindow.close();
        }, 300);
      }
    });
    weborderModal.buttons.push({
      label: "button/cancel",
      type: "warning",
      isDefault: true,
      closes: true,
      action: function action() {}
    });
    return weborderModal;
  };
  service.showDispatchNoteButton = function (receipt) {
    return receipt.items.some(function (i) {
      return i.webOrderNumber;
    });
  };
  service.showWebOrderModalFromReceipt = function (receipt) {
    var webOrderItem = receipt.items.find(function (i) {
      return i.webOrderNumber;
    });
    if (webOrderItem) {
      var webShopId = fluctuatingPropertiesHelper.getWebshopId(webOrderItem);
      webOrderRepository.api.getWebOrder(webOrderItem.webOrderNumber, webShopId).then(function (response) {
        showWebOrder(response.data);
      });
    }
  };
  service.showWebOrderModalFromWeborder = function (weborder) {
    showWebOrder(weborder);
  };
  function showWebOrder(webOrder) {
    weborderModal.isVisible = true;
    weborderModal.orgWeborder = webOrder;
    weborderModal.webOrder = angular.copy(webOrder);
    weborderModal.webOrder.orderedOn = webOrder.orderedOn ? moment(webOrder.orderedOn).format("YYYY-MM-DD") : "-";
    weborderModal.webOrder.pickedUpOn = webOrder.pickedUpOn ? moment(webOrder.pickedUpOn).format("YYYY-MM-DD") : "-";
    weborderModal.webOrder.dispatchedUpOn = moment().format("YYYY-MM-DD");
    weborderModal.total = service.sumTotal(webOrder.webOrderLines);
    weborderModal.vat = service.sumVatAmount(webOrder.webOrderLines);
  }
  eventBus.subscribe(eventBus.events.sale.completed, null, service.onSaleCompleted);
  eventBus.subscribe(eventBus.events.sale.cancelled, null, service.onSaleCancelled);
  return service;
});
