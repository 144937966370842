"use strict";

pos.directive("dddCaptureGlobalInput", function (inputService, $parse, $rootScope, keypadManager, $state, $document, $timeout) {
  "use strict";

  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      if (ele[0].nodeName !== "INPUT") return;
      var initializing = true;
      var attrValue = attrs.onEnter;
      var attrOnDenominationValue = attrs.onDenomination;
      var onDenominationFunc = $parse(attrOnDenominationValue);
      var onDenomination = !onDenominationFunc ? function () {} : function () {
        onDenominationFunc(scope);
      };
      var func = $parse(attrValue);
      var onEnter = !func ? function () {} : function () {
        func(scope);
      };

      // register the global input element on the controller's scope, so we may access it through the controller later
      scope.globalInput = ele;
      var handleOnEnter = function handleOnEnter(event) {
        if ($document[0].activeElement !== ele[0] || event.keyCode !== 13) return;
        $timeout(onEnter, 0);
      };
      var start = function start() {
        inputService.enableGlobalCapture(ele, onEnter, onDenomination);
      };
      var stop = function stop() {
        inputService.disableGlobalCapture(ele);
      };
      var switcher = $rootScope.$watch(function () {
        var attrValue = attrs.dddCaptureGlobalInput !== "ddd-capture-global-input" ? $parse(attrs.dddCaptureGlobalInput)(scope) : true;
        // enable/disable by looking at attribute value, if keypad is visible or not and whether the input is readonly or not
        return attrValue && !keypadManager.isVisible() && ele.attr("readonly");
      }, function (value, prev) {
        if (value && (!prev || initializing)) start();else if (!value) stop();
        initializing = false;
      });
      ele.on("$destroy", stop);
      ele.on("$destroy", switcher);
    }
  };
});
