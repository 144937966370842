"use strict";

pos.factory("intersolvePaymentType", function (receipt, sales, $q, $rootScope, signalr, backend, $injector, inputValidationService, defaultPaymentType, $log) {
  var service = {};
  function getPayment(uuid) {
    // look backwards for the payment
    var payments = receipt.getReceipt().payments;
    for (var i = payments.length - 1; i >= 0; i--) {
      var payment = payments[i];
      if (payment.uuid === uuid) {
        return payment;
      }
    }
  }

  /**
   * Initialise a payment as a default payment type. The default payment type sends it's request to a Connector
   * And expects more or less the same values back
   * @param  {string} paymentType         The type of payment, e.g. 'Cash', 'ManualCard', etc.
   * @param  {Decimal|number} amount      The amount passed in by the keyboard
   * @param  {object} currency            A curreny object, usually fetch from posConfig.getCurrencySetup(name) or posConfig.getLocalCurrency()
   * @param  {string} contextInfo         Any additional information this payment type may carry
   * @return {promise}                    $q promise which is rejected with a status or resolved with the makePayment response
   */
  service.makePayment = function (paymentType, amount, currency, contextInfo) {
    $log.debug("intersolve: make payment");
    try {
      amount = inputValidationService.convertToDecimal(amount);
    } catch (err) {
      return $q.reject(err);
    }
    var paymentStatus = receipt.getTotal().minus(receipt.getPaymentsTotal());
    var maxValueAllowed = 9999999.99;

    // we require a correct format for the amount and if the receipt is a return (negative total)
    // then we do not allow the clerk to try and pay back more than the total amount, because the sale
    // has to sum to 0 when all is said and done
    if (defaultPaymentType.isAmountInvalid(amount)) {
      return $q.reject("INVALID_AMOUNT");
    }

    // we allow 0 payments if there's items on the receipt (e.g. an exchanged item is a return + a sold at same price)
    // we also allow payments without any items, if the clerk want to withdraw some money
    // we only require that there either must a value in the payment OR items on the reciept
    if (amount.isZero()) {
      $rootScope.$emit("payment:intersolveError", "Loyalty transaction with amount 0 is not allowed");
      return $q.reject("INVALID_TYPE_AMOUNT");
    }
    if (receipt.getTotal().minus(receipt.getPaymentsTotal()).isNegative() && !amount.isNegative()) amount = amount.abs().neg();
    if (amount.abs().gt(paymentStatus.abs())) {
      $rootScope.$emit("payment:intersolveError", "Payment amount can not exceed remaining amount");
      return $q.reject("INTERSOLVE_AMOUNT_EXCEEDS_REMAINING_AMOUNT");
    }
    //loyaltyIntersolveService.getSumOfNettoItems(receipt)
    var loyaltyIntersolveService = $injector.get("loyaltyIntersolveService");
    if (amount.abs().gt(loyaltyIntersolveService.getSumOfNettoItems().abs())) {
      $rootScope.$emit("payment:intersolveError", "Web orders can not be payed for with loyalty credits");
      return $q.reject("INVALID_NETTO_AMOUNT");
    }
    return loyaltyIntersolveService.loadAttachedCustomer().then(function (result) {
      var loyaltyCustomer = result;
      var intersolveAttrib = createIntersolveAttributes();
      if (amount.gt(loyaltyCustomer.balance)) {
        $rootScope.$emit("payment:intersolveError", "Payment amount can not exceed loyalty customer balance");
        return $q.reject("INTERSOLVE_AMOUNT_EXCEEDS_BALANCE");
      }
      return sales.makePayment(paymentType, amount, currency, contextInfo).then(function (response) {
        var payment = response.data.d;
        payment.isIntersolve = true;
        payment.completed = false;
        payment.refPos = intersolveAttrib.refPos;
        payment.ticketNumber = intersolveAttrib.ticketNumber;
        payment.status = "Connecting";
        payment.customer = loyaltyCustomer;
        return response;
      });

      // return $q.resolve({
      //     data: {
      //         d: {
      //             voucherNumber: '',
      //             paymentType: 'LoyaltyDiscount',
      //             amount: amount,
      //             rounding: 0,
      //             exchangeRate: currency.exchangeRate,
      //             message: 'OK',
      //             completed: false,
      //             currencyCode: currency.code,
      //             currencyName: currency.name,
      //             customer: loyaltyCustomer,
      //             isIntersolve: true,
      //             refPos: intersolveAttrib.refPos,
      //             ticketNumber: intersolveAttrib.ticketNumber,
      //             status: "Connecting",
      //         }
      //     },
      //     config: {
      //         device: backend.getDeviceByIndex(backend.defaultDevice)
      //     },
      //     status: 200
      // });
    });
  };
  service.handleIntersolveTransaction = function handleIntersolveTransaction(paymentState) {
    $log.debug("intersolve: in handleIntersolveTransaction");
    var payment = paymentState.response.data.d;
    var customer = payment.customer;
    var receiptId = receipt.getReceipt().receiptId;
    payment.awaitingResponse = true;
    receipt.persist();

    //TODO get loyaltycustomer's card number
    var loyaltyIntersolveService = $injector.get("loyaltyIntersolveService");
    return loyaltyIntersolveService.subtractBalance(payment.refPos, customer.customerId, payment.ticketNumber, payment.amount.times(100)).then(function (response) {
      payment.completed = true;
      payment.awaitingResponse = false;
      payment.status = response.data.description;
      receipt.persist();

      // payments with loyalty cards are excluded in the discounts. Therefor
      // a recalc is required after adding such a payment.
      $rootScope.$emit("payment:intersolve", "Recalculate discounts");
      return paymentState;
    }, function (errorResponse) {
      if (errorResponse.data && errorResponse.data.description) {
        payment.status = errorResponse.data.description;
      }
      paymentState.intersolveResponse = errorResponse;
      payment.awaitingResponse = false;
      paymentState.noErrorMessage = true;
      receipt.persist();
      return $q.reject(paymentState);
    });
  };
  var createIntersolveAttributes = function createIntersolveAttributes() {
    var thisReceipt = receipt.getReceipt();
    var thisDate = new Date();
    var milliseconds = thisDate.getTime();
    var lastNumberOfYear = thisDate.getFullYear().toString().slice(-1);
    var paddedPosId = padWithZeros(thisReceipt.posId, 2);
    var paddedReceiptId = padWithZeros(thisReceipt.receiptId, 5);
    return {
      ticketNumber: Number(lastNumberOfYear + paddedPosId + paddedReceiptId),
      refPos: Number(thisReceipt.posId + milliseconds)
    };
  };
  var padWithZeros = function padWithZeros(number, length) {
    var paddedString = number.toString().slice(-length);
    while (paddedString.length < length) {
      paddedString = "0" + paddedString;
    }
    return paddedString;
  };
  return service;
});
