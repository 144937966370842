"use strict";

pos.directive("voucherModal", ["eventBus", function (eventBus) {
  var controller = function controller($scope) {
    $scope.action = function (btn) {
      if (btn.cancel) {
        $scope.content.isVisible = false;
      } else {
        btn.action();
      }
    };
  };
  return {
    templateUrl: "views/posModal/voucherModal-directive",
    controller: controller,
    scope: {
      content: "="
    }
  };
}]);
