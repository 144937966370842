"use strict";

pos.directive("dddPaymentType", function ($compile, $http, $templateCache, $timeout, backend, paymentService) {
  var getTemplate = function getTemplate(contentType) {
    var templateLoader,
      baseUrl = "views/partials/",
      templateMap = {
        Card: "payment-nonexpandable",
        Currency: "payment-currency",
        payPlazaCard: "payment-nonexpandable",
        Placeholder: "payment-placeholder",
        MobilePay: "payment-mobilepay"
      };
    var templateUrl = baseUrl + (templateMap[contentType] || "payment-expandable"); // default to expandable
    templateLoader = $http.get(templateUrl, {
      cache: $templateCache
    });
    return templateLoader;
  };
  var linker = function linker(scope, element, attrs) {
    getTemplate(scope.content.paymentType).then(function (response) {
      element.html($compile(response.data)(scope));
    });
    if (scope.islast) {
      scrollToElement(500);
    }
    function scrollToElement(timeoutTime) {
      $timeout(function () {
        if (scope.iscrollInstance) scope.iscrollInstance.scrollToElement(element[0], 0, true, true);else element[0].scrollIntoView(false);
      }, timeoutTime);
    }
    var showButton = true;
    scope.showButton = function () {
      return showButton;
    };
    scope.changeButton = function () {
      showButton = !showButton;
    };
    scope.getLocalAmount = function () {
      return scope.content.amount.times(scope.content.exchangeRate).div(100).toFixed(2);
    };
    scope.resumeCardPayment = function () {
      scope.$emit("payment:card:resume", scope.content);
    };
    scope.isPaymentProcessing = function () {
      return paymentService.getIsPaymentProcessing();
    };
    scope.removeline = function () {
      scope.$emit("payment:remove", scope.content);
    };
    scope.cancelPayPlazaTransaction = function () {
      scope.$emit("payment:cancelPayPlazaTransaction", scope.content);
    };
    scope.cancelIntersolvePayment = function () {
      scope.$emit("payment:cancelIntersolvePayment", scope.content);
    };
    scope.abortCardPayment = function () {
      scope.content.isAborting = true;
      scope.$emit("payment:card:abort", scope.content);
    };
    scope.showAbortButton = function () {
      var currentDevice = backend.getCurrentDevice();
      return currentDevice && [1, 2, 3, 4, 8].includes(currentDevice.terminalType) && scope.content.awaitingResponse && !scope.content.isAborting;
    };
    scope.abortMobilePayPayment = function () {
      scope.content.isAborting = true;
      scope.$emit("payment:mobilepay:abort", scope.content);
    };
    scope.resumeMobilePayPayment = function () {
      scope.$emit("payment:mobilepay:resume", scope.content);
    };
  };
  return {
    restrict: "E",
    scope: {
      content: "=",
      isselected: "=",
      islast: "=",
      paymentscroll: "="
    },
    link: linker
  };
});
