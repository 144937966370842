"use strict";

pos.directive("weborderView", ["eventBus", function (eventBus) {
  var controller = function controller($scope, notification, $translate) {
    var JsBarcode = require("jsbarcode");
    JsBarcode("#barcode", $scope.content.webOrder.orderNo, {
      format: "code128",
      width: 2,
      height: 15,
      displayValue: false
    });
  };
  return {
    templateUrl: "views/weborder/weborderView",
    controller: controller,
    scope: {
      content: "="
    }
  };
}]);
