"use strict";

pos.directive("customerorderWidget", function (customerOrderWidgetService) {
  return {
    templateUrl: "views/widgets/widget-customerorder",
    controller: function controller($scope, Widgets) {
      $scope.widgetsService = Widgets;
    }
  };
});
