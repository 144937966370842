"use strict";

pos.directive("userAgentDetection", function (userAgentService) {
  "use strict";

  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      var userAgent = userAgentService.getUserAgent();
      attrs.$set("ua-device", userAgent.deviceEscaped);
      attrs.$set("ua-browser", userAgent.browserEscaped);
      attrs.$set("ua-os", userAgent.osEscaped);
    }
  };
});
