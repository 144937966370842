"use strict";

pos.factory("notification", function ($injector, posStorage, uuid, $q, indexdb, ngToast, eventBus) {
  var notification = {};
  var INFO_MESSAGE_UPDATED_KEY = "infoMessageUpdated";
  var NOTIFICATIONS_KEY = "notifications";
  var messageArray = [];
  indexdb.notifications.getAll().then(function (ns) {
    return ns.forEach(function (n) {
      return messageArray.push(n);
    });
  });

  //TODO: remove and replace with trackPosMessage service
  notification.currentLogLevel = 0;
  notification.logLevel = {};
  notification.logLevel.INFO = 0;
  notification.logLevel.SUCCESS = 1;
  notification.logLevel.WARNING = 2;
  notification.logLevel.ERROR = 3;
  notification.logLevel.DISABLED = 99;
  notification.getMessages = function () {
    return messageArray;
  };
  notification.clearMessages = function () {
    // setting a new empty array will decouple the reference from header controller and break notification aside, so we do this instead.
    messageArray.length = 0;
    indexdb.notifications.clear();
  };

  //TODO: remove and replace with trackPosMessage service
  var logLevel = function logLevel(type) {
    if (isNaN(type)) {
      switch (type) {
        case "info":
          return notification.logLevel.INFO;
        case "success":
          return notification.logLevel.SUCCESS;
        case "warning":
          return notification.logLevel.WARNING;
        case "error":
          return notification.logLevel.ERROR;
        default:
          return notification.logLevel.INFO;
      }
    }
    return type;
  };

  //TODO: remove and replace with trackPosMessage service
  var generateLogModel = function generateLogModel(type, title, body, data) {
    var posConfig = $injector.get("posConfig");
    var version = $injector.get("versionService").getVersion();
    return {
      shopId: posConfig.shopId,
      posId: posConfig.posId,
      message: title + ": " + body,
      clientTime: new Date(),
      type: logLevel(type),
      posVersion: version,
      data: data ? JSON.stringify(data) : null
    };
  };
  notification.removeMessage = function (message) {
    var index = messageArray.indexOf(message);
    if (index >= 0) {
      messageArray.splice(index, 1);
      indexdb.notifications.remove(message.uuid);
    }
  };
  notification.setLogLevel = function (level) {
    notification.currentLogLevel = logLevel(level);
  };
  notification.pop = function (type, title, body, timeout, otherOptions) {
    if (type == "warn") type = "warning";else if (type == "error") {
      type = "danger";
    }
    var options = otherOptions || {};
    options.className = type;
    options.timeout = timeout || 5000;
    options.onDismiss = function () {
      eventBus.notify(eventBus.events.toaster.clicked, null);
    };
    if (body) {
      options.content = "<strong>".concat(title, "</strong><br /><p>").concat(body, "</p>");
    } else {
      options.content = "<p>".concat(title, "</p>");
    }
    ngToast.create(options);
  };
  notification.notify = function (type, title, body, timeout, bodyOutputType) {
    var obj = {};
    if (!angular.isObject(type)) {
      obj = {
        type: type,
        title: title,
        body: body,
        bodyOutputType: bodyOutputType,
        timestamp: new Date()
      };
    } else {
      obj = type;
    }
    var existingMessage;
    angular.forEach(messageArray, function (message) {
      if (message.type === obj.type && message.title === obj.title && message.body === obj.body) existingMessage = message;
    });
    if (!existingMessage) {
      obj.uuid = uuid();
      messageArray.unshift(obj);
      indexdb.notifications.add(obj);
      return obj;
    } else {
      return existingMessage;
    }
  };
  function handleMessages(messages) {
    var messageGroups = {};
    var newestMessageDate;
    angular.forEach(messages, function (message) {
      // skip message if it's expired
      if (moment(message.expireAt).isBefore(moment())) return;

      // find the newest message's createdAt date
      if (!newestMessageDate) newestMessageDate = moment(message.createdAt);else if (moment(message.createdAt).isAfter(newestMessageDate)) newestMessageDate = moment(message.createdAt);

      // find the message GUID object or create a new one
      var messageGroup = messageGroups[message.messageGuid] || {
        uuid: uuid()
      };
      messageGroup.type = message.type;
      messageGroup.timestamp = message.createdAt;
      messageGroup[message.language] = {
        title: message.title,
        body: message.message,
        bodyOutputType: "trustedHtml"
      };
      messageGroups[message.messageGuid] = messageGroup;
    });

    // store the newest message's createdAt date
    if (newestMessageDate) posStorage.set(INFO_MESSAGE_UPDATED_KEY, newestMessageDate.toISOString());
    angular.forEach(messageGroups, function (message) {
      messageArray.unshift(message);
    });
    indexdb.notifications.merge(messageArray);
    return messageGroups;
  }
  return notification;
}).factory("notificationHelper", function ($injector) {
  var helper = {};
  helper.getMessageBody = function getMessageBody(message) {
    return extractText(message, "body");
  };
  helper.getMessageTitle = function getMessageTitle(message) {
    return extractText(message, "title");
  };
  helper.getMessageTimestamp = function getMessageTimestamp(message) {
    return extractText(message, "timestamp");
  };
  function extractText(message, property) {
    // if it's not an API info message there wont be any language groups, just return the message
    if (message[property]) return message[property];

    // get the message for the current language or get the first message on the object if we can't find anything suitable
    var translatedMessage = message[getLanguage(message)] || message[Object.keys(message)[1]];
    return translatedMessage[property];
  }
  function getLanguage(message) {
    var posConfig = $injector.get("posConfig");
    var clerkLoginService = $injector.get("clerkLoginService");

    // set default language to to shop language
    var displayLanguage = "en-gb"; // use as fallback
    var shopLanguage = posConfig.getLocale();
    var clerkLanguage;
    if (clerkLoginService.isLoggedIn()) clerkLanguage = posConfig.mapToLanguage(clerkLoginService.currentUser().language);
    if (clerkLanguage && message[clerkLanguage]) displayLanguage = clerkLanguage;else if (message[shopLanguage]) displayLanguage = shopLanguage;
    return displayLanguage;
  }
  return helper;
});
