"use strict";

pos.factory("masterItemStockService", function (masterItemStockRepository) {
  var service = {};
  service.getByMasterItemStock = function (itemNo, storeId) {
    return masterItemStockRepository.api.getMasterItemStockInfo(itemNo, storeId).then(function (response) {
      return response.data;
    });
  };
  return service;
});
