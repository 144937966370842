"use strict";

pos.controller("DownPaymentShipmentAddressController", function ($scope, $state, downpayOrderService) {
  "use strict";

  var selectedIndex = -1;
  $scope.addresses = getShippingAddresses();
  $scope.selectShippingAddress = function (index, address) {
    if (selectedIndex === index) {
      selectedIndex = -1;
      return;
    }
    selectedIndex = index;
    downpayOrderService.setShippingAddress(address);
    downpayOrderService.saveShipping();
    $state.go("root.split.pos");
  };
  $scope.createAddress = function () {
    $state.go("root.split.downpayment-newaddress", {
      isBillingAddress: false
    });
  };
  function getShippingAddresses() {
    var addresses = downpayOrderService.getSelectedCustomer().addresses;
    var shippingAddresses = [];
    if (addresses) {
      shippingAddresses = addresses.filter(function (address) {
        return address.isPrimaryShipping === true;
      });
    }
    return shippingAddresses;
  }
});
