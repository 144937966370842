"use strict";

pos.factory("userAgentService", function () {
  "use strict";

  var scope = {};

  /**
   * Device character splits
   * Used for splitting the final "get device" filter-result
   *
   * @returns {}
   */
  scope.deviceCharacterSplits = [";", ")"];

  /**
   * Device aliases
   * If the user agent device matches any of the below,
   * the device alias will be set to the corresponding value
   *
   * @returns {}
   */
  scope.deviceAliases = [{
    original: "Windows NT",
    alias: "Computer"
  }, {
    original: "Macintosh",
    alias: "Mac"
  }];

  /**
   * Browser aliases
   * A list of known vendor-defined browser aliases
   *
   * @returns {Browser alias: New browser alias}
   */
  scope.browserAliases = {
    CriOS: "Chrome",
    Trident: "Internet Explorer"
  };

  /**
   * Operating system aliases
   * Create operating system aliases based on known devices
   *
   * @returns {Device: Operating system alias}
   */
  scope.osAliases = {
    Computer: "Windows",
    Mac: "Mac OS",
    iPhone: "iOS",
    iPad: "iOS",
    iPod: "iOS"
  };

  /**
   * Operating system version aliases
   * Known operating system version aliases
   *
   * @returns {Device: Operating system alias}
   */
  scope.osVersionAliases = {
    "Windows NT 6.1": "7",
    "Windows NT 6.2": "8",
    "Windows NT 10.0": "10"
  };

  /**
   * Device detection patterns.
   * Patterns are automatically applied. This is a way
   * to define known patterns for device alias detection.
   *
   * @returns {array}
   */
  scope.devicePatterns = [/Mozilla\/[0-9.,]* \((.*); |Mozilla\/[0-9.,]* \((.*)\)/];

  /**
   * OS detection patterns.
   * Patterns are automatically applied. This is a way
   * to define known patterns for OS detection.
   *
   * @returns {array}
   */
  scope.osPatterns = [/(Android) [0-9,.]*;/ // Android
  ];

  /**
   * OS version detection patterns.
   * Patterns are automatically applied. This is a way
   * to define known patterns for OS version detection.
   *
   * @returns {array}
   */
  scope.osVersionPatterns = [/(CPU(.*)OS )([0-9.,_]*) like/i,
  // iOS (Insert before Mac OS, as it contains Mac OS X in the agent)
  /(Mac [A-Z ]*)([0-9._,]*)/i,
  // Mac OS
  /Android(.*);/i // Android
  ];
  scope.osVersionFilters = [["OS X", ""], ["_", ".", /_/g]];

  /**
   * Browser detection patterns.
   * Patterns are automatically applied. This is a way
   * to define known patterns for browser alias detection.
   *
   * @returns {array}
   */
  scope.browserPatterns = [/(.*)(Edge)/i,
  // MS Edge
  /(.*)(Chrome|CriOS)/i,
  // Chrome
  /(.*)(Firefox)/i,
  // Firefox
  /(.*)(Safari)/i,
  // Safari
  /(.*)(Trident)/i // Internet Explorer
  ///Gecko\) (.*)\/[0-9.,]* /,          // Chrome
  ///Gecko\/[0-9]* (.*)\/[0-9.,]*/      // Firefox
  ];

  /**
   * Returns the user device as an object.
   *
   * @returns {object array} result
   */
  scope.detectDevice = function () {
    // Iterate user agent through known device patterns
    for (var a = 0; a < scope.devicePatterns.length; a++) {
      var devicePattern = scope.devicePatterns[a];
      var patternMatches = new RegExp(devicePattern).exec(scope.ua);
      if (patternMatches) {
        var result = patternMatches[1];

        // Remove potential unwanted string content
        for (var b = 0; b < scope.deviceCharacterSplits.length; b++) {
          var character = scope.deviceCharacterSplits[b];
          if (result.indexOf(character)) {
            result = result.split(character)[0];
          }
        }
        scope.originalDevice = result;

        // Iterate original device through known device aliases
        var abort = false;
        for (var index = 0; index < scope.deviceAliases.length; index++) {
          if (abort == true) break;
          var alias = scope.deviceAliases[index];
          if (new RegExp(alias.original).test(scope.originalDevice)) {
            result = alias.alias;
            abort = true;
          }
        }
        return result;
      }
    }
    return "Unknown";
  };

  /**
   * Returns the operating system name
   *
   * @returns {string} result
   */
  scope.detectOperatingSystem = function () {
    var device = scope.detectDevice();

    // Check if the device matches a known operating system alias
    if (scope.osAliases[device]) {
      return scope.osAliases[device];
    }

    // Iterate through known operating system patterns
    for (var a = 0; a < scope.osPatterns.length; a++) {
      var osPattern = scope.osPatterns[a];
      var patternMatches = new RegExp(osPattern).exec(scope.ua);
      if (patternMatches) {
        return patternMatches[1];
      }
    }
    return "Unknown";
  };

  /**
   * Checks if the operating system matches |operatingSystem|
   *
   * @param {string} operatingSystem
   * @returns {bool} result
   */
  scope.isOperatingSystem = function (operatingSystem) {
    return scope.detectOperatingSystem() == operatingSystem;
  };

  /**
   * Returns the OS version
   *
   * @returns {string} result
   */
  scope.detectOperatingSystemVersion = function () {
    var result = "Unknown";
    if (scope.osVersionAliases[scope.originalDevice]) {
      return scope.osVersionAliases[scope.originalDevice];
    } else {
      for (var a = 0; a < scope.osVersionPatterns.length; a++) {
        var versionPattern = scope.osVersionPatterns[a];
        var patternMatches = new RegExp(versionPattern).exec(scope.ua);
        if (patternMatches) {
          result = patternMatches[patternMatches.length - 1];
          break;
        }
      }
    }

    // Filter version string
    for (var b = 0; b < scope.osVersionFilters.length; b++) {
      var filter = scope.osVersionFilters[b];
      var filterKey = filter[0];
      var filterValue = filter[1];
      var filterRegex = filter[2];
      if (new RegExp(filterKey).test(result)) {
        result = result.replace(filterRegex, filterValue);
      }
    }
    return result;
  };

  /**
   * Returns the name of the user's browser
   *
   * @returns {string} result
   */
  scope.detectBrowser = function () {
    var result = "Unknown";

    // Iterate user agent through known browser patterns
    for (var i = 0; i < scope.browserPatterns.length; i++) {
      var browserPattern = scope.browserPatterns[i];
      var patternMatches = new RegExp(browserPattern).exec(scope.ua);
      if (patternMatches) {
        result = patternMatches[patternMatches.length - 1];
        break;
      }
    }

    // Rename the matched pattern if it matches any known aliases
    if (scope.browserAliases[result]) {
      result = scope.browserAliases[result];
    }

    // Check for iOS/Mac OS browser defaults
    if (result == "Version" && (scope.isOperatingSystem("iOS") || scope.isOperatingSystem("Mac OS"))) {
      result = "Safari";
    }
    return result;
  };

  /**
   * Returns an object with userAgent details
   *
   * @returns {object) childscope
   */
  scope.getUserAgent = function () {
    scope.ua = navigator.userAgent;
    var result = "Unknown";

    // Set UserAgent defaults
    var userAgentObject = {
      isMobile: Boolean,
      device: String,
      os: String,
      osVersion: Number,
      browser: String,
      string: String
    };

    // Set user device parameters
    userAgentObject.string = scope.ua;
    userAgentObject.device = scope.detectDevice();
    userAgentObject.originalDevice = scope.originalDevice;
    userAgentObject.os = scope.detectOperatingSystem();
    userAgentObject.osVersion = scope.detectOperatingSystemVersion();
    userAgentObject.browser = scope.detectBrowser();

    // Formatted device paramters
    userAgentObject.deviceEscaped = scope.escapeUaString(userAgentObject.device);
    userAgentObject.osEscaped = scope.escapeUaString(userAgentObject.os);
    userAgentObject.browserEscaped = scope.escapeUaString(userAgentObject.browser);

    // Set resultset
    result = userAgentObject;
    return result;
  };
  scope.escapeUaString = function (string) {
    return string.toLowerCase().replace(" ", "-");
  };
  return scope;
});
