"use strict";

pos.factory("picklistService", function (picklistRepository) {
  var service = {};
  service.printPicklist = function (type, orderBy, printFromLastPrint, creationDate, fromDate, toDate) {
    return picklistRepository.api.getPicklist(type, orderBy, printFromLastPrint, creationDate, fromDate, toDate);
  };
  service.getPicklistTimestamp = function () {
    return picklistRepository.api.getPicklistTimestamp();
  };
  return service;
});
