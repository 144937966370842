"use strict";

pos.factory("cardTransactionStatus", ["posStorage", function (posStorage) {
  var key = "card-transaction-in-progress";
  var service = {};
  service.start = function () {
    posStorage.set(key, true);
  };
  service.stop = function () {
    posStorage.remove(key);
  };
  service.isInProgress = function () {
    var val = posStorage.get(key);
    return !!val;
  };
  return service;
}]);
