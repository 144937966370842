"use strict";

pos.directive("slideAction", function slideAction($log) {
  return {
    restrict: "A",
    scope: {
      slideAction: "&",
      slideActionIscrollInstance: "="
    },
    link: function link(scope, ele, attr) {
      var $ = jQuery;
      var $slideToggle = ele.find(".slide-action__slider");
      var $slideLabel = ele.find(".slide-action__slider-label");
      var slideMaxed = false;
      if (scope.slideActionIscrollInstance) {
        ele.on("mouseenter", function () {
          scope.slideActionIscrollInstance.disable();
        });
        ele.on("mouseleave", function () {
          scope.slideActionIscrollInstance.enable();
        });
      }
      $slideToggle.draggable({
        axis: "x",
        containment: "parent",
        drag: function drag(event, ui) {
          if (ui.position.left + 50 >= $slideToggle.parent().width() - $slideToggle.parent().width() / 100 * 20 && slideMaxed == false) {
            slideMaxed = true;
            $slideLabel.addClass("slide-action__slider-label--visible");
          }
          if (ui.position.left + 50 < $slideToggle.parent().width() - $slideToggle.parent().width() / 100 * 20 && slideMaxed == true) {
            slideMaxed = false;
            $slideLabel.removeClass("slide-action__slider-label--visible");
          }
        },
        stop: function stop(event, ui) {
          if (ui.position.left + 50 >= $slideToggle.parent().width() - $slideToggle.parent().width() / 100 * 20) {
            slideMaxed = false;
            $slideLabel.removeClass("slide-action__slider-label--visible");
            scope.slideAction();
            $slideToggle.animate({
              left: 0
            }, 200);
          }
          if (ui.position.left + 50 < $slideToggle.parent().width() - $slideToggle.parent().width() / 100 * 20) {
            $log.debug("not maximum", attr.slideAction);
            slideMaxed = false;
            $slideLabel.removeClass("slide-action__slider-label--visible");
            $slideToggle.animate({
              left: 0
            }, 200);
          }
        }
      });
    }
  };
});
