"use strict";

pos.factory("storeNamespace", function (store, $cookies, $log) {
  var cookieShopId = $cookies.get("shopId");
  var shopId = cookieShopId && cookieShopId != "undefined" ? cookieShopId : undefined;
  var storeContextShopId = store.get("contextShopId");
  var storeShopId = store.get("shopId");
  var shop = shopId || storeContextShopId || storeShopId || "";

  /*
  if (storeContextShopId && shopId && storeContextShopId !== shopId) {
    //$cookies.remove("shopId");
    $cookies.put("shopId", storeContextShopId, {
      domain: ".k3imagine.com"
    });
  }
  */

  var tenant = $cookies.get("tenantId") || store.get("contextTenantId") || store.get("tenantId") || "";
  var namespace = "".concat(tenant, "-").concat(shop);
  if (namespace === "-" && IN_DEBUG_MODE) namespace = "2208-9961";
  $log.debug("getting posStorage as tennant/shopId: " + namespace);
  return namespace;
});
