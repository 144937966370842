"use strict";

pos.factory("voucherMessageService", function ($translate) {
  var service = {};
  var translationkeys = [{
    errorcode: -3,
    key: "voucher/errorcode/CommunicationError"
  }, {
    errorcode: -2,
    key: "voucher/errorcode/BrokerConfigurationError"
  }, {
    errorcode: -1,
    key: "voucher/errorcode/BrokerServiceError"
  }, {
    errorcode: 0,
    key: "voucher/errorcode/Succeeded"
  }, {
    errorcode: 1,
    key: "voucher/errorcode/InvalidOperation"
  }, {
    errorcode: 2,
    key: "voucher/errorcode/XmlSyntaxError"
  }, {
    errorcode: 3,
    key: "voucher/errorcode/NotLoggedIn"
  }, {
    errorcode: 4,
    key: "voucher/errorcode/LoginFailed"
  }, {
    errorcode: 5,
    key: "voucher/errorcode/InvalidParameters"
  }, {
    errorcode: 6,
    key: "voucher/errorcode/NotAllowedToImportThisCardHolder"
  }, {
    errorcode: 7,
    key: "voucher/errorcode/RetailerNotInGroup"
  }, {
    errorcode: 8,
    key: "voucher/errorcode/InvalidIdentifier"
  }, {
    errorcode: 9,
    key: "voucher/errorcode/InvalidUnknownRetailer"
  }, {
    errorcode: 10,
    key: "voucher/errorcode/InvalidUnknownTerminal"
  }, {
    errorcode: 11,
    key: "voucher/errorcode/NotAuthorized"
  }, {
    errorcode: 12,
    key: "voucher/errorcode/InvalidTerminalOriginatingAddress"
  }, {
    errorcode: 13,
    key: "voucher/errorcode/NoConfigurationAvailable"
  }, {
    errorcode: 14,
    key: "voucher/errorcode/OriginationAddressNotAuthorized"
  }, {
    errorcode: 15,
    key: "voucher/errorcode/NoTransactionAvailableToConfirmOrCancel"
  }, {
    errorcode: 16,
    key: "voucher/errorcode/RetaileridTerminalidCombinationDoesNotExist"
  }, {
    errorcode: 18,
    key: "voucher/errorcode/UnableToCancel"
  }, {
    errorcode: 19,
    key: "voucher/errorcode/TransactionCanNotBeCanceledAtThisMoment"
  }, {
    errorcode: 20,
    key: "voucher/errorcode/AddCardFailed"
  }, {
    errorcode: 21,
    key: "voucher/errorcode/CardAlreadyExists"
  }, {
    errorcode: 22,
    key: "voucher/errorcode/UnknownBrandType"
  }, {
    errorcode: 23,
    key: "voucher/errorcode/DeviceBlockedDueToLicenseAgreementViolation"
  }, {
    errorcode: 24,
    key: "voucher/errorcode/DeviceUsageExceedsLicenseAgreement"
  }, {
    errorcode: 100,
    key: "voucher/errorcode/PostIssueFailed"
  }, {
    errorcode: 101,
    key: "voucher/errorcode/CardOrCustomerDoesNotExist"
  }, {
    errorcode: 102,
    key: "voucher/errorcode/CardIsExpired"
  }, {
    errorcode: 103,
    key: "voucher/errorcode/CardIsBlocked"
  }, {
    errorcode: 104,
    key: "voucher/errorcode/InsufficientBalanceToPerformTransaction"
  }, {
    errorcode: 105,
    key: "voucher/errorcode/UsernamePasswordDoNotMatchWithCardholder"
  }, {
    errorcode: 106,
    key: "voucher/errorcode/CardholderNotRegistered"
  }, {
    errorcode: 107,
    key: "voucher/errorcode/InvalidSequenceNumber"
  }, {
    errorcode: 108,
    key: "voucher/errorcode/CardNotRelatedToAGroup"
  }, {
    errorcode: 109,
    key: "voucher/errorcode/InvalidCardid"
  }, {
    errorcode: 110,
    key: "voucher/errorcode/InvalidEmailAddress"
  }, {
    errorcode: 111,
    key: "voucher/errorcode/InvalidCountry"
  }, {
    errorcode: 112,
    key: "voucher/errorcode/InvalidObjectidOrObjecttype"
  }, {
    errorcode: 113,
    key: "voucher/errorcode/InvalidCardcodeInCustomerid"
  }, {
    errorcode: 114,
    key: "voucher/errorcode/CardIsAlreadyActive"
  }, {
    errorcode: 115,
    key: "voucher/errorcode/InvalidGroupid"
  }, {
    errorcode: 116,
    key: "voucher/errorcode/InvalidCardFormat"
  }, {
    errorcode: 117,
    key: "voucher/errorcode/InvalidScheme"
  }, {
    errorcode: 118,
    key: "voucher/errorcode/CardIsTransferred"
  }, {
    errorcode: 119,
    key: "voucher/errorcode/CardIsNotActive"
  }, {
    errorcode: 120,
    key: "voucher/errorcode/IncorrectActivationOrPurchaseValue"
  }, {
    errorcode: 121,
    key: "voucher/errorcode/CustomerAlreadyExists"
  }, {
    errorcode: 122,
    key: "voucher/errorcode/CardAlreadyExists1"
  }, {
    errorcode: 123,
    key: "voucher/errorcode/CustomerDoesNotExist"
  }, {
    errorcode: 124,
    key: "voucher/errorcode/MaximumNumberOfTransactionsPerDayReached"
  }, {
    errorcode: 125,
    key: "voucher/errorcode/MaximumNumberOfTransactionsPerDayPerRetailerReached"
  }, {
    errorcode: 126,
    key: "voucher/errorcode/MaximumIssuePointsPerDayIsReached"
  }, {
    errorcode: 127,
    key: "voucher/errorcode/CardNotAvailable"
  }, {
    errorcode: 128,
    key: "voucher/errorcode/NotAllowedToRedeemOnDateOfFirstTransaction"
  }, {
    errorcode: 129,
    key: "voucher/errorcode/NumberOfPointsForIssueOrRedeemTransactionIsTooHigh"
  }, {
    errorcode: 130,
    key: "voucher/errorcode/TheServiceIsNotAvailableOnTheCard"
  }, {
    errorcode: 131,
    key: "voucher/errorcode/TheServiceCannotBeVerified"
  }, {
    errorcode: 132,
    key: "voucher/errorcode/ReceiptNotFound"
  }, {
    errorcode: 133,
    key: "voucher/errorcode/ReceiptAlreadyClaimed"
  }, {
    errorcode: 134,
    key: "voucher/errorcode/InvalidBlockDate"
  }, {
    errorcode: 135,
    key: "voucher/errorcode/SchemeidAlreadyExists"
  }, {
    errorcode: 136,
    key: "voucher/errorcode/BalanceNotAvailable"
  }, {
    errorcode: 140,
    key: "voucher/errorcode/InvalidBrand"
  }, {
    errorcode: 141,
    key: "voucher/errorcode/InvalidBrandtype"
  }, {
    errorcode: 161,
    key: "voucher/errorcode/MaximumCardBalanceIsReached"
  }, {
    errorcode: 162,
    key: "voucher/errorcode/CardIsNotReloadable"
  }, {
    errorcode: 163,
    key: "voucher/errorcode/CardIsAlreadyAssignedToACustomer"
  }, {
    errorcode: 164,
    key: "voucher/errorcode/WrongCurrency"
  }, {
    errorcode: 165,
    key: "voucher/errorcode/TransactionWouldExceedConfiguredLimits"
  }, {
    errorcode: 201,
    key: "voucher/errorcode/LoginFailedUnknownUser"
  }, {
    errorcode: 202,
    key: "voucher/errorcode/MaximumSimultaneousLoginsReached"
  }, {
    errorcode: 203,
    key: "voucher/errorcode/UserIsAlreadyLoggedIn"
  }, {
    errorcode: 204,
    key: "voucher/errorcode/LoginAccountIsSetInactive"
  }, {
    errorcode: 205,
    key: "voucher/errorcode/DatabaseIsLocked"
  }, {
    errorcode: 206,
    key: "voucher/errorcode/DatabaseIsExpiredAndNowSetToLocked"
  }, {
    errorcode: 207,
    key: "voucher/errorcode/LoginFailedInvalidPassword"
  }, {
    errorcode: 208,
    key: "voucher/errorcode/InvalidUsername"
  }, {
    errorcode: 209,
    key: "voucher/errorcode/InvalidSecretanswer"
  }, {
    errorcode: 210,
    key: "voucher/errorcode/InvalidSecretquestion"
  }, {
    errorcode: 211,
    key: "voucher/errorcode/InvalidPin"
  }, {
    errorcode: 212,
    key: "voucher/errorcode/InvalidEanCode"
  }, {
    errorcode: 250,
    key: "voucher/errorcode/InvalidEmailAddress1"
  }, {
    errorcode: 251,
    key: "voucher/errorcode/EmailOrCustomerInformationIsMissing"
  }, {
    errorcode: 252,
    key: "voucher/errorcode/EmailAddressAlreadyInUse"
  }, {
    errorcode: 300,
    key: "voucher/errorcode/InvalidConfigurationid"
  }, {
    errorcode: 301,
    key: "voucher/errorcode/InvalidVoucherid"
  }, {
    errorcode: 302,
    key: "voucher/errorcode/InvalidIssuedvoucherid"
  }, {
    errorcode: 303,
    key: "voucher/errorcode/VoucherNotFound"
  }, {
    errorcode: 304,
    key: "voucher/errorcode/VoucherNotAvailable"
  }, {
    errorcode: 305,
    key: "voucher/errorcode/InvalidVoucherownerCombination"
  }, {
    errorcode: 306,
    key: "voucher/errorcode/InvalidMediaidmediatype"
  }, {
    errorcode: 307,
    key: "voucher/errorcode/VoucherHasBeenRedeemedAlready"
  }, {
    errorcode: 308,
    key: "voucher/errorcode/DeactivationNotSupportedForThisVoucher"
  }, {
    errorcode: 309,
    key: "voucher/errorcode/VoucherRedeemCurrentlyDeniedForThisVoucher"
  }, {
    errorcode: 310,
    key: "voucher/errorcode/InvalidRedeemCurrentlyDeniedForThisVoucher"
  }, {
    errorcode: 311,
    key: "voucher/errorcode/MaximumNumberOfVoucherIssuesExceededForThisVoucher"
  }, {
    errorcode: 312,
    key: "voucher/errorcode/VoucherHasBeenPurchasedAlready"
  }, {
    errorcode: 313,
    key: "voucher/errorcode/VoucherReservationExpired"
  }, {
    errorcode: 314,
    key: "voucher/errorcode/InvalidDeliveryMethod"
  }, {
    errorcode: 401,
    key: "voucher/errorcode/CardidinitialaccesscodeMismatch"
  }, {
    errorcode: 402,
    key: "voucher/errorcode/SchemeNotFound"
  }, {
    errorcode: 403,
    key: "voucher/errorcode/InvalidRegistercode"
  }, {
    errorcode: 404,
    key: "voucher/errorcode/CardDoesNotBelongToTheCustomer"
  }, {
    errorcode: 405,
    key: "voucher/errorcode/TheSourceAndDestinationCardMustBeSpecified"
  }, {
    errorcode: 406,
    key: "voucher/errorcode/MayNotTransferToTheSameCard"
  }, {
    errorcode: 407,
    key: "voucher/errorcode/TransferCustomerFailed"
  }, {
    errorcode: 408,
    key: "voucher/errorcode/CustomerNotFound"
  }, {
    errorcode: 409,
    key: "voucher/errorcode/InvalidCustomeridFormat"
  }, {
    errorcode: 500,
    key: "voucher/errorcode/NoActiveDraw"
  }, {
    errorcode: 501,
    key: "voucher/errorcode/DrawIsClosedNewSalesStartsTomorrow"
  }, {
    errorcode: 502,
    key: "voucher/errorcode/NoLotteryTicketsAvailable"
  }, {
    errorcode: 503,
    key: "voucher/errorcode/NoActiveDrawForThisProduct"
  }, {
    errorcode: 504,
    key: "voucher/errorcode/SoldOutSelectADifferentDay"
  }, {
    errorcode: 550,
    key: "voucher/errorcode/CustomerDoesNotExist1"
  }, {
    errorcode: 551,
    key: "voucher/errorcode/ValidationField1NotValid"
  }, {
    errorcode: 552,
    key: "voucher/errorcode/ValidationField2NotValid"
  }, {
    errorcode: 553,
    key: "voucher/errorcode/ValidationField3NotValid"
  }, {
    errorcode: 554,
    key: "voucher/errorcode/DeliveryTypeIsNotSupported"
  }, {
    errorcode: 555,
    key: "voucher/errorcode/CardAlreadyHasAPin"
  }, {
    errorcode: 556,
    key: "voucher/errorcode/CardDoesHaveAPin"
  }, {
    errorcode: 557,
    key: "voucher/errorcode/InvalidPin1"
  }, {
    errorcode: 558,
    key: "voucher/errorcode/CouldNotEncryptPin"
  }, {
    errorcode: 700,
    key: "voucher/errorcode/PaymentOk"
  }, {
    errorcode: 701,
    key: "voucher/errorcode/PaymentFailed"
  }, {
    errorcode: 702,
    key: "voucher/errorcode/PaymentDeclined"
  }, {
    errorcode: 703,
    key: "voucher/errorcode/PaymentPending"
  }, {
    errorcode: 704,
    key: "voucher/errorcode/PaymentRevoked"
  }, {
    errorcode: 705,
    key: "voucher/errorcode/TransactionNotFound"
  }, {
    errorcode: 706,
    key: "voucher/errorcode/PaymentNeedsToBeCanceled"
  }, {
    errorcode: 914,
    key: "voucher/errorcode/SpecialFixedValueActivationAlreadyDone"
  }, {
    errorcode: 919,
    key: "voucher/errorcode/SpecialFixedValueActivation"
  }];
  service.getErrorMessage = function (errorCode) {
    if (errorCode == 0) return;
    var message = errorCode + " - ";
    var translation = translationkeys.find(function (t) {
      return t.errorcode == errorCode;
    });
    if (translation) message += $translate.instant(translation.key);else message += $translate.instant("error_not_found");
    return message;
  };
  service.getStatusMessage = function (statusCode) {
    switch (statusCode) {
      case 1:
        return $translate.instant("voucher/status/inactive");
      case 2:
        return $translate.instant("voucher/status/active");
      case 3:
        return $translate.instant("voucher/status/blocked");
      case 4:
        return $translate.instant("voucher/status/redeemed");
      case 5:
        return $translate.instant("voucher/status/expired");
      case 6:
        return $translate.instant("voucher/status/subsituted");
      case 7:
        return $translate.instant("voucher/status/disabled");
      default:
        return $translate.instant("voucher/status/undefined");
    }
  };
  service.getPaymentMessage = function (responseCode, balance, statusCode) {
    if (responseCode != 0) {
      return this.getErrorMessage(responseCode);
    }
    if (statusCode != 2) {
      return $translate.instant("voucher/payment/wrong_status") + this.getStatusMessage(statusCode);
    }
    if (balance <= 0) {
      return $translate.instant("voucher/payment/insufficient_balance");
    }
  };
  return service;
});
