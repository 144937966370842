"use strict";

pos.controller("GiftCertificateCtrl", function ($scope, giftCertificate, $state, notification, $translate, receipt, keypadManager, $timeout) {
  "use strict";

  var i18nProperAmount;
  $translate(["input/enter_amount", "toast/max 4 digit", "toast/proper amount"]).then(function (translations) {
    $scope.placeHolder = $translate.instant("input/enter_amount");
    i18nProperAmount = translations["toast/proper amount"];
  });
  $scope.$watch("price", function (price) {
    if (!price) {
      $scope.giftNo = giftCertificate.generateGiftCertificate(true);
      return;
    }
    $scope.update("" + price);
  });
  $scope.update = function (price) {
    price = new Decimal(price);
    var dotIndex = price.toString();
    dotIndex = dotIndex.indexOf(".");
    if (dotIndex != -1) {
      //Dot found
      var fracUnit = price.toString().substr(dotIndex + 1);
      if (fracUnit.length > 2) {
        $scope.price = Number(price.toString().substring(0, price.toString().length - 1));
        return;
      }
    }
    $scope.displayValue = price.toNumber();
    $scope.giftNo = giftCertificate.generateGiftCertificate(true);
  };
  $scope.addToReceipt = function (price) {
    price = new Decimal(price);
    if (price.isNaN() || price.isZero()) {
      notification.pop("info", i18nProperAmount);
      return;
    }
    receipt.addGiftCertificate(price, giftCertificate.generateGiftCertificate());
    $state.go("root.split.pos");
  };
  $scope.reset = function () {
    $scope.price = "";
  };
});
