"use strict";

pos.factory("coreLoyaltyService", function ($rootScope, receipt, posConfig, eventBus, notification, $translate, $q) {
  var service = {};
  var canPurchaseGiftCertificateWithLoyalty;
  var minimumBeforeSpending = 0;
  var convertionRatio = 0;
  var loyaltyCustomer;
  var loyaltyWatcher;
  var loyaltyPayment = {
    type: "LoyaltyBonus",
    translation: "button/loyaltybonus",
    icon: "icons8-discount",
    style: "",
    // TODO: add look-at-me-styling
    paymentStrategy: "loyalty"
  };
  var i18nDeductLoyalty;
  var loyaltyEnabledPredicate = function loyaltyEnabledPredicate() {
    if (canPurchaseGiftCertificateWithLoyalty) {
      return loyaltyCustomer && loyaltyCustomer.loyaltyPointsAvailable >= minimumBeforeSpending && receipt.getTotal().isPos() && getMoneyToUse(loyaltyCustomer).gt(0);
    } else {
      return getNonGiftCertificatesAmount(receipt.getReceipt().items) > 0 && !canPurchaseGiftCertificateWithLoyalty && loyaltyCustomer && loyaltyCustomer.loyaltyPointsAvailable >= minimumBeforeSpending && receipt.getTotal().isPos() && getMoneyToUse(loyaltyCustomer).gt(0);
    }
  };
  $translate("toast/return-with-loyalty").then(function (translation) {
    return i18nDeductLoyalty = translation;
  });

  /**
   * Replace the default loyalty paymenttype.
   * It should have the same properties defined as the default one
   */
  service.setLoyaltyPaymentType = function (paymentType) {
    loyaltyPayment = paymentType;
  };

  /**
   * Attach customer to receipt and setup watcher for loyalty payment
   * customer = {
   *      customerNumber: <customerNumber(Number)>
   *      moneyToUse: <spendings available(Number)>
   * }
   */
  service.attachCustomer = function (customer) {
    // disable watcher if one is running, to avoid leaking
    this.detachCustomer();

    // add the customerNmber to the receipt
    loyaltyCustomer = customer;
    receipt.addLoyaltyCustomer(customer.customerNumber, customer.customerEmail);
    if (receipt.getTotal().isNeg()) notification.pop("warning", i18nDeductLoyalty);

    // setup a watcher to decide if the loyalty payment should be available or not
    loyaltyWatcher = $rootScope.$watch(loyaltyEnabledPredicate, addOrRemoveLoyaltyPayment);
  };

  /**
   * Remove customer from receipt and cancel watcher
   */
  service.detachCustomer = function () {
    if (loyaltyWatcher) loyaltyWatcher();
    loyaltyCustomer = null;
    removeLoyaltyPayment();
    receipt.addLoyaltyCustomer(null, null);
  };
  service.validatePaymentAmount = function (amount) {
    var amountDecimal = new Decimal(amount);
    var isUsingMoreThanCurrentBalance = getMoneyToUse(loyaltyCustomer).lt(new Decimal(amountDecimal));
    var isUsingMoreThanRemainder = receipt.getTotal().minus(receipt.getPaymentsTotal()).lt(new Decimal(amountDecimal));
    if (isUsingMoreThanCurrentBalance) return $q.reject("LOYALTY_OVERCHARGE_NOT_ALLOWED");
    if (isUsingMoreThanRemainder) return $q.reject("LOYALTY_OVERPAY_NOT_ALLOWED");
    if (!canPurchaseGiftCertificateWithLoyalty) {
      var isPayingWithLoyaltyForGiftCertificates = receipt.getTotal().minus(getGiftCertificatesAmount(receipt.getReceipt().items)).lt(new Decimal(amountDecimal));
      if (isPayingWithLoyaltyForGiftCertificates) return $q.reject("LOYALTY_GIFTCERTIFICATE_PURCHASE_NOT_ALLOWED");
    }
    return $q.resolve();
  };
  function getGiftCertificatesAmount(items) {
    return items.filter(function (element) {
      return element.templateType === "giftCertificate";
    }).reduce(function (acc, element) {
      return acc.plus(element.effectiveSalesPrice);
    }, new Decimal(0));
  }
  function getNonGiftCertificatesAmount(items) {
    return items.filter(function (element) {
      return element.templateType !== "giftCertificate";
    }).reduce(function (acc, element) {
      return acc.plus(element.effectiveSalesPrice);
    }, new Decimal(0));
  }
  function addOrRemoveLoyaltyPayment(enableLoyaltyPayment) {
    if (enableLoyaltyPayment) addLoyaltyPayment();else removeLoyaltyPayment();
  }
  function removeLoyaltyPayment() {
    var paymentTypes = posConfig.getPaymentTypeConfigs();
    for (var i = 0; i < paymentTypes.length; i++) {
      if (paymentTypes[i].type !== loyaltyPayment.type) continue;
      paymentTypes.splice(i, 1);
      break;
    }
    posConfig.setPaymentTypeConfigs(paymentTypes);
    delete loyaltyPayment.amountSuggestion;
  }
  function addLoyaltyPayment() {
    var paymentTypes = posConfig.getPaymentTypeConfigs();
    // the amount suggestion strategy is currently not changeable
    loyaltyPayment.amountSuggestion = function () {
      var toUse = getMoneyToUse(loyaltyCustomer);
      var remaining = receipt.getTotal().minus(receipt.getPaymentsTotal()).abs();
      if (!canPurchaseGiftCertificateWithLoyalty) {
        var giftCertificatesAmount = getGiftCertificatesAmount(receipt.getReceipt().items);
        remaining = remaining.minus(giftCertificatesAmount);
      }
      return toUse.gt(remaining) ? remaining : toUse;
    };
    paymentTypes.unshift(loyaltyPayment);
    posConfig.setPaymentTypeConfigs(paymentTypes);
  }
  function getMoneyToUse(customer) {
    var payments = receipt.getReceipt().payments;
    var loyaltySpendings = payments.filter(function (o) {
      return o.paymentType === loyaltyPayment.type;
    }).reduce(function (pre, cur) {
      return pre.plus(cur.amount);
    }, new Decimal(0));
    return new Decimal(customer.loyaltyPointsAvailable || 0).times(convertionRatio).minus(loyaltySpendings);
  }
  function initConfig() {
    var localCurrency = posConfig.getLocalCurrency();
    var cfg = posConfig.getLoyaltyConfiguration();
    var loyaltyRatio = cfg.ratios.find(function (ratio) {
      return ratio.currencyCode == localCurrency.name;
    });
    convertionRatio = loyaltyRatio ? loyaltyRatio.ratioPointToCurrency : 0;
    minimumBeforeSpending = cfg.settings.minAvailablePointsBeforeSpending;
    canPurchaseGiftCertificateWithLoyalty = cfg.settings.canPurchaseGiftVouchersWithPoints;
    // If there is a loyalty customer attached and the payment types array does not contain the loyalty payment type - readd it
    if (!!loyaltyCustomer && !posConfig.getPaymentTypeConfigs().find(function (pt) {
      return pt.type == loyaltyPayment.type;
    })) {
      addOrRemoveLoyaltyPayment(loyaltyEnabledPredicate());
    }
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, function () {
    return initConfig();
  });
  initConfig();
  return service;
});
