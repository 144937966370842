"use strict";

pos.directive("inputPopup", function (keypadManager, $timeout, $parse) {
  return {
    restrict: "A",
    link: function link(scope, ele, attr) {
      ele.on("blur", function (event) {
        scope.trigger = false;
      });
      scope.$watch(function () {
        return scope[attr.inputPopup];
      }, function (trigger) {
        if (trigger) $timeout(function () {
          var attrValue = attr.onEnter;
          var func = $parse(attrValue);
          var onEnter = !func ? function () {} : function () {
            func(scope);
            keypadManager.hide();
          };
          keypadManager.show(ele, onEnter);
        }, 10);
      });
    }
  };
});
