"use strict";

// eager load the intersolve service if the loyaltyintersolve widget is in the widget list
// this ensures that all the services watchers and POS manipulations get's triggered
// immediately instead of waiting for the widget to load the service (due to the depdenency).
// Basically we're creating a "fake" dependency with the $injector service to eager load the intersolve service
pos.run(function (posConfig, $injector, $log, eventBus) {
  function loadConfig() {
    // we need to await that posConfig is ready, then we know the latest config is loaded
    posConfig.whenReady().then(function () {
      var widgets = posConfig.getWidgets();
      // search for loyaltyintersolve in the list of widgets
      for (var i = 0; i < widgets.length; i++) {
        var widget = widgets[i];
        if (widget.name === "loyaltyintersolve") {
          // eager load the service by getting it with the $injector
          $log.debug("eager load intersolve");
          return $injector.get("loyaltyIntersolveService");
        }
      }
    });
  }
  loadConfig();
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
});
pos.factory("loyaltyIntersolveService", function (intersolveRepository, notification, coreLoyaltyService, $q, $timeout, receipt, $rootScope, posConfig, $translate, paymentService, intersolvePaymentType, defaultPaymentType, posStorage, $log) {
  $log.debug("Creating Intersolve Service");
  var service = {};
  var loyaltyCustomer;
  var cancelPaymentUnloader;
  var UNLOAD_CANCELPAYMENT_FREQUENCY = 60000;
  var INTERSOLVE_CANCELLATIONS = "intersolveFailedCancels";
  var loyaltyPaymentType = {
    type: "LoyaltyDiscount",
    translation: "button/loyaltydiscount",
    icon: "fa-star",
    style: "",
    // TODO: add look-at-me-styling
    paymentStrategy: "intersolve"
  };
  var intersolvePaymentStrategy = {
    makePayment: intersolvePaymentType.makePayment,
    responseSteps: [{
      success: defaultPaymentType.addPaymentToReceipt
    }, {
      success: intersolvePaymentType.handleIntersolveTransaction
    }, {
      success: defaultPaymentType.isPaymentEnough
    }, {
      success: defaultPaymentType.doChange
    }, {
      success: defaultPaymentType.guaranteedFinishReceipt
    }]
  };
  var i18ncancelling, i18ncancellationRequestFailed, i18ntoastcancellationRequestFailed, i18nCardWrongCurrency;
  $translate(["label/cancellingPayment", "label/cancellationRequestFailed", "widget/intersolve/cardWrongCurrency", "toast/cancellationRequestFailed"]).then(function (translations) {
    i18ncancelling = translations["label/cancellingPayment"];
    i18ncancellationRequestFailed = translations["label/cancellationRequestFailed"];
    i18nCardWrongCurrency = translations["widget/intersolve/cardWrongCurrency"];
    i18ntoastcancellationRequestFailed = translations["toast/cancellationRequestFailed"];
  });
  posConfig.addBlockNavigationPaymentType(loyaltyPaymentType.type);
  paymentService.setPaymentStrategy(loyaltyPaymentType.paymentStrategy, intersolvePaymentStrategy);
  coreLoyaltyService.setLoyaltyPaymentType(loyaltyPaymentType);

  /**
   * Intersolve payments are not allowed to be used in (total) discount calculations.
   * This function is injected to exclude them.
   */
  receipt.discountExcludes.push(function (receipt) {
    var totalExcludes = new Decimal(0);
    _.forEach(receipt.payments, function (payment) {
      if (payment.paymentType === "LoyaltyDiscount") {
        totalExcludes = totalExcludes.plus(payment.amount);
      }
    });
    return totalExcludes;
  });

  /**
   * Clear the cached loyaltyCustomer if the customerNumber is removed from the receipt
   */
  $rootScope.$watch(function () {
    return receipt.getLoyaltyCustomerNumber();
  }, function (cardId) {
    if (!cardId) {
      loyaltyCustomer = null;
      coreLoyaltyService.detachCustomer();
    }
  });
  $rootScope.$on("payment:intersolveError", function (event, error) {
    notification.pop("warning", error);
  });
  $rootScope.$on("payment:cancelIntersolvePayment", function (event, payment) {
    service.cancelIntersolvePayment(payment);
  });

  // Intersolve payments are exluded from discounts, so they require a recalculation.
  $rootScope.$on("payment:intersolve", function (event, message) {
    receipt.reCalculateDiscounts();
  });
  service.getLoyaltyCustomer = function (cardId) {
    return intersolveRepository.api.getLoyaltyCustomer(cardId).then(function (response) {
      var customer = response.data;
      var customerBalance = customer.balance;
      loyaltyCustomer = customer;
      if (!customer.currency) {
        customer.currency = posConfig.getLocalCurrency().name;
      }
      if (posConfig.getLocalCurrency().name !== customer.currency) {
        notification.pop("warning", i18nCardWrongCurrency);
        customerBalance = 0;
      }
      coreLoyaltyService.attachCustomer({
        customerNumber: customer.customerId,
        customerEmail: customer.email,
        moneyToUse: customerBalance
      });
      customer.balance = new Decimal(customer.balance);
      return customer;
    });
  };
  service.searchForLoyaltyCustomer = function (firstname, lastname, streetname, zipcode, town, housenumber, email, mobile) {
    return intersolveRepository.api.searchForLoyaltyCustomer(firstname, lastname, streetname, zipcode, town, housenumber, email, mobile).then(function (response) {
      var customers = response.data;
      return customers;
    });
  };
  service.loadAttachedCustomer = function () {
    if (loyaltyCustomer) return $q.resolve(loyaltyCustomer);
    var cardId = receipt.getLoyaltyCustomerNumber();
    if (cardId) return service.getLoyaltyCustomer(cardId);
    return $q.reject();
  };
  service.clearCustomer = function () {
    loyaltyCustomer = null;
    coreLoyaltyService.detachCustomer();
  };
  service.subtractBalance = function (refPos, customerId, ticketNumber, amount) {
    $log.debug("LoyaltyIntersolveService: SubtractBalance");
    if (checkCancellationRetryList(customerId)) {
      var response = {
        data: {
          description: "Failed, previous cancellation in progress"
        }
      };
      notification.pop("warning", "Failed, previous cancellation in progress");
      return $q.reject(response);
    }
    return intersolveRepository.api.subtractBalance(refPos, customerId, ticketNumber, amount).then(function (response) {
      var successful = processResponse(response.data, customerId, refPos);
      if (!successful) {
        return $q.reject(response);
      }
      return response;
    }, function (response) {
      // An error represents an unsuccessful API call
      var cancellation = {
        customerId: customerId,
        refPos: refPos
      };
      pushCancelPayment(cancellation);
      return response;
    });
  };
  service.cancelIntersolvePayment = function (payment) {
    $log.debug("LoyaltyIntersolveService: CancelIntersolvePayment");
    // don't try and subtract if the payment didn't even complete in the first go
    if (!payment.completed) return receipt.removePayment(payment.uuid);
    payment.awaitingResponse = true;
    payment.status = i18ncancelling;
    var customer = payment.customer;
    var retries = 0;
    intersolveRepository.api.CancelSubtractBalance(customer.customerId, payment.refPos).then(function (response) {
      var successful = processResponse(response.data, customer.customerId, payment.refPos);
      if (successful) {
        receipt.removePayment(payment.uuid);
      } else {
        receipt.removePayment(payment.uuid);
        notification.pop("warning", response.data.description);
      }
      payment.awaitingResponse = false;
    }, function (errorResponse) {
      // An error represents an unsuccessful API call
      var cancellation = {
        customerId: customer.customerId,
        refPos: payment.refPos
      };
      pushCancelPayment(cancellation);
      payment.awaitingResponse = false;
      payment.status = i18ncancellationRequestFailed;
      notification.pop("warning", i18ntoastcancellationRequestFailed);
    });
  };
  service.getSumOfNettoItems = function () {
    var _receipt = receipt.getReceipt();
    var _total = receipt.getTotal();
    var isNettoSum = new Decimal(0);
    var payedWithLoyalty = new Decimal(0);

    // Getting total sum of "isNetto" items
    for (var i = 0; i < _receipt.items.length; i++) {
      $log.debug(_receipt.items[i]);
      if (_receipt.items[i].isNetto) {
        isNettoSum = isNettoSum.plus(new Decimal(_receipt.items[i].effectiveSalesPrice));
      }
    }

    // checking previous loyalty payments on receipt
    for (var j = 0; j < _receipt.payments.length; j++) {
      $log.debug(_receipt.payments[j]);
      if (_receipt.payments[j].paymentType === "LoyaltyDiscount" && _receipt.payments[j].completed) {
        payedWithLoyalty = payedWithLoyalty.plus(new Decimal(_receipt.payments[j].amount));
      }
    }
    return _total.minus(payedWithLoyalty.plus(isNettoSum));
  };
  function processResponse(response, customerId, refPos) {
    // id 0 is a success while -99 indicates a failure where the API successfully
    // sent a cancel request to Intersove service.
    if (response.id == 0) {
      return true;
    } else if (response.id != -99) {
      var cancellation = {
        customerId: customerId,
        refPos: refPos
      };
      pushCancelPayment(cancellation);
    }
    return false;
  }
  function pushCancelPayment(cancellation) {
    var queue = posStorage.get(INTERSOLVE_CANCELLATIONS) || [];
    queue.push(cancellation);
    posStorage.set(INTERSOLVE_CANCELLATIONS, queue);
  }
  service.startCancelPaymentUnloader = function () {
    cancelPaymentUnloader = $timeout(tryUnloadCancelPaymentQueue, UNLOAD_CANCELPAYMENT_FREQUENCY);
  };
  function tryUnloadCancelPaymentQueue() {
    var queue = posStorage.get(INTERSOLVE_CANCELLATIONS) || [];
    if (queue.length === 0) return service.startCancelPaymentUnloader();
    var notDelivered = [];
    // start unloading from the first receipt in the saleQueue - it will run recursively through the list

    unload(0);
    function unload(index) {
      var cancellation = queue[index];
      intersolveRepository.api.CancelSubtractBalance(cancellation.customerId, cancellation.refPos).then(function (response) {
        if (response.data.id != 0) notDelivered.push(cancellation);
      }, function (errorResponse) {
        notDelivered.push(cancellation);
      })["finally"](function () {
        if (index !== queue.length - 1) return unload(++index);
        posStorage.set(INTERSOLVE_CANCELLATIONS, notDelivered);
        service.startCancelPaymentUnloader();
      });
    }
  }
  function checkCancellationRetryList(customerId) {
    var cancelList = posStorage.get(INTERSOLVE_CANCELLATIONS) || [];
    for (var i = 0; i < cancelList.length; i++) {
      if (cancelList[i].customerId === customerId) {
        return true;
      }
    }
    return false;
  }
  service.stopCancelPaymentUnloader = function () {
    if (cancelPaymentUnloader) $timeout.cancel(cancelPaymentUnloader);
  };
  service.loadAttachedCustomer();
  service.startCancelPaymentUnloader();
  return service;
});
