"use strict";

pos.factory("voucherRepository", function (backendHelper, posStorage) {
  var voucher = {};
  var backend = backendHelper.backend;
  voucher.api = {
    verifyGiftCertificate: function verifyGiftCertificate(voucherNo) {
      return backend.api.get("api/vouchers/verify/" + voucherNo, {
        ignoreError: true
      });
    },
    getVoucher: function getVoucher(voucherNo, clerkId, pin) {
      var request = {};
      request.voucherNo = voucherNo;
      request.clerkId = clerkId;
      request.clientRequestDate = moment();
      if (pin) query.pin = pin;
      return backend.api.post("api/v1.0/voucher/get", request);
    },
    deactivateVoucher: function deactivateVoucher(voucherNo, clerkId, transactionReference) {
      return backend.api.post("api/v1.0/voucher/deactivate", {
        voucherNo: voucherNo,
        clerkId: clerkId,
        transactionReference: transactionReference,
        clientRequestDate: moment()
      });
    },
    printVoucherAction: function printVoucherAction(voucherAction) {
      var printTranslations = posStorage.get("printTranslations");
      return backend.devices[backend.defaultDevice].post("/POSServiceHandler.aspx/PrintVoucherAction", {
        voucherAction: voucherAction,
        printTranslations: printTranslations
      });
    },
    activateVouchers: function activateVouchers(items, transactionReference, currencyCode) {
      var activationRequests = [];
      angular.forEach(items, function (item) {
        var request = {
          voucherNo: item.voucherNumber,
          clerkId: item.clerkId,
          value: item.salesPrice,
          currencyCode: currencyCode,
          transactionReference: transactionReference,
          clientRequestDate: moment()
        };
        activationRequests.push(request);
      });
      return backend.api.post("api/v1.0/voucher/activate", activationRequests);
    },
    purchaseWithVoucher: function purchaseWithVoucher(voucherNo, clerkId, amount, currencyCode, transactionReference, pin) {
      var puchaseRequest = {
        voucherNo: voucherNo,
        clerkId: clerkId,
        value: amount,
        currencyCode: currencyCode,
        transactionReference: transactionReference,
        clientRequestDate: moment()
      };
      if (pin) puchaseRequest.pin = pin;
      return backend.api.post("api/v1.0/voucher/purchase", puchaseRequest);
    },
    cancelAction: function cancelAction(voucherNo, clerkId, uniqueActionId, transactionReference) {
      var cancelRequest = {
        voucherNo: voucherNo,
        clerkId: clerkId,
        uniqueActionId: uniqueActionId,
        transactionReference: transactionReference,
        clientRequestDate: moment()
      };
      return backend.api.post("api/v1.0/voucher/cancel", cancelRequest);
    }
  };
  return voucher;
});
