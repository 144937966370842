"use strict";

pos.factory("storeService", function (storeRepository) {
  var service = {};
  service.getStores = function () {
    return storeRepository.api.getStores().then(function (response) {
      return response.data;
    });
  };
  return service;
});
