"use strict";

pos.controller("DownPaymentSelectBillingAddressController", function ($scope, $state, downpayOrderService) {
  "use strict";

  var selectedIndex = -1;
  $scope.addresses = getBillingAddresses();
  $scope.selectBillingAddress = function (index, address) {
    if (selectedIndex === index) {
      selectedIndex = -1;
      return;
    }
    selectedIndex = index;
    downpayOrderService.setBillingAddress(address);
    if (downpayOrderService.isBooking) {
      $state.go("root.split.downpayment-shipment");
    } else {
      $state.go("root.split.pos");
    }
  };
  $scope.createAddress = function () {
    $state.go("root.split.downpayment-newaddress", {
      isBillingAddress: true
    });
  };
  function getBillingAddresses() {
    var addresses = downpayOrderService.getSelectedCustomer().addresses;
    var billingAddresses = [];
    if (addresses) {
      billingAddresses = addresses.filter(function (address) {
        return address.isPrimaryBilling === true;
      });
    }
    return billingAddresses;
  }
});
