"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
pos.factory("pricePromotionService", function (pricePromotionRepository, $rootScope, receipt, posConfig, $timeout, $q, eventBus, notification, $translate) {
  var service = {
    availableDiscounts: [],
    isRequesting: false
  };
  var getDiscountsPending = false;
  var getDiscountsTimeout = false;
  var REQUEST_BUFFER = 2000;
  var currentScannedPromotionBarcodes = [];
  var i18nPriceAndPromotionScanned;
  receipt.nonPersistantDiscountTypes.push("PPE");
  $translate(["toast/ppe-barcode"]).then(function (translation) {
    return i18nPriceAndPromotionScanned = translation["toast/ppe-barcode"];
  });
  $rootScope.$watch(function () {
    // we need to fetch discounts everytime the quantity changes
    // either by increasing quantity on an existing line or adding a new line
    var totalQty = 0;
    angular.forEach(receipt.getReceipt().items, function (item) {
      if (!item.isNetto && item.transactionType !== "Return") totalQty += item.quantity;
    });
    return totalQty;
  }, function (qty, previousQty) {
    if (!qty) return;
    getDiscountsBuffer();
  });
  $rootScope.$watch(function () {
    return receipt.getReceipt().customerNumber;
  }, function () {
    getDiscountsBuffer();
  });
  service.promotionBarcodeScanned = function (ev, barcode) {
    currentScannedPromotionBarcodes.push(barcode);
    notification.pop("success", i18nPriceAndPromotionScanned);
    getDiscountsBuffer();
  };
  service.resetScannedBarcodes = function () {
    currentScannedPromotionBarcodes = [];
  };
  eventBus.subscribe(eventBus.events.sale.cancelled, null, service.resetScannedBarcodes);
  eventBus.subscribe(eventBus.events.sale.completed, null, service.resetScannedBarcodes);

  // Avoiding spamming the API by creating a minimum REQUEST_BUFFER long interval between calls
  function getDiscountsBuffer() {
    if (!getDiscountsTimeout) {
      getDiscountsTimeout = true;
      service.isRequesting = true;
      service.getDiscounts(receipt.items).then(function (data) {
        if (!data) return;
        service.availablePromotions = data;
        service.isRequesting = false;
        applyDiscounts(data);
      });
      $timeout(function () {
        getDiscountsTimeout = false;
        if (getDiscountsPending) {
          getDiscountsPending = false;
          getDiscountsBuffer();
        }
      }, REQUEST_BUFFER);
    } else {
      getDiscountsPending = true;
    }
  }
  service.getDiscounts = function (items) {
    // Creating the basket model for the PPE API
    var currentReceipt = receipt.getReceipt();
    var basket = {
      basketHeader: {
        shopId: posConfig.shopId,
        posNo: posConfig.posId,
        groupId: posConfig.groupId,
        basketDate: currentReceipt.date,
        clerkNo: currentReceipt.clerk,
        customerNo: currentReceipt.customerNumber,
        currencyCode: posConfig.getLocalCurrency().name,
        promotionBarcodes: currentScannedPromotionBarcodes
      },
      basketLines: []
    };
    for (var i = 0; i < currentReceipt.items.length; i++) {
      var item = currentReceipt.items[i];
      if (item.ean && item.quantity > 0) {
        basket.basketLines.push({
          sequenceNo: i,
          barcode: item.ean,
          quantity: item.quantity,
          itemId: item.variantId,
          salesPrice: item.salesPrice
        });
      }
    }
    if (basket.basketLines.length == 0) return $q.resolve();
    return pricePromotionRepository.getDiscounts(basket).then(function (response) {
      // just pass through for now
      return response.data;
    });
  };
  function applyDiscounts(promotions) {
    var items = receipt.getReceipt().items;

    // Remove all ppe discounts from the receipt before applying new/updated ones
    var discountableItems = items.filter(function (item) {
      return !item.isNetto && item.transactionType !== "Return";
    });
    discountableItems.forEach(function (item) {
      return item.discountDetails = item.discountDetails ? item.discountDetails.filter(function (discount) {
        return discount.type !== "PPE";
      }) : [];
    });
    receipt.reCalculateDiscounts();
    promotions.basketReturnLines.filter(function (discountLine) {
      return discountLine.discountPercent > 0;
    }).forEach(function (discountLine) {
      // Find the receipt item that corrosponds with the basketline
      var itemIdx = items.findIndex(function (itm, idx) {
        return idx == discountLine.sequenceNo && itm.ean === discountLine.barcode;
      });
      if (itemIdx < 0) return;

      // Setting up discount
      var discountDetails = {
        type: "PPE"
      };
      var discountGroups = groupBy(discountLine.discounts, "discountCode");
      Object.keys(discountGroups).forEach(function (discountType) {
        discountGroups[discountType].filter(function (discount) {
          return discount.percent > 0;
        }).forEach(function (discount) {
          var discountReason = {
            reason: discount.description || "",
            id: 600,
            promotionType: discount.discountCode
          };
          if (items[itemIdx].quantity > discount.quantity) {
            items[itemIdx].quantity -= discount.quantity;
            receipt.addToReceipt(_objectSpread(_objectSpread({}, items[itemIdx]), {}, {
              quantity: discount.quantity
            }), true);
            receipt.setLineDiscount(discount.percent, discountReason, receipt.getReceipt().items.length - 1, discountDetails, true);
          } else {
            receipt.setLineDiscount(discount.percent, discountReason, itemIdx, discountDetails, true);
          }
        });
      });
    });
  }
  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  return service;
});
