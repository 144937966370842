"use strict";

pos.directive("stockWidget", function ($rootScope, stockService) {
  return {
    templateUrl: "views/widgets/widget-stock",
    controller: function controller($scope) {},
    link: function link(scope, ele, attr) {
      scope.selectedItem = false;
      scope.stockInfo = [];
      scope.$watch(function () {
        return $rootScope.selectedItem;
      }, function (selectedItem) {
        scope.selectedItem = selectedItem;
        if (selectedItem !== false) {
          stockService.getItemStockLevels(selectedItem.variantId, false).then(function (response) {
            scope.stockInfo = response;
          });
        } else {
          scope.stockInfo = [];
        }
      });
    }
  };
});
