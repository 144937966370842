"use strict";

pos.factory("loyaltyRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    lookupCustomer: function lookupCustomer(customerNumber) {
      return backend.api.get("api/loyalty/members/customerNumber/" + customerNumber);
    }
  };
  return repo;
}).factory("pricePromotionRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {
    getDiscounts: function getDiscounts(basket) {
      return backend.api.post("api/ppe/basket", basket);
    }
  };
  return repo;
}).factory("kegaRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getWebOrders: function getWebOrders() {
      return backend.api.get("api/kega/orders");
    }
  };
  return repo;
}).factory("trimitRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    addCustomer: function addCustomer(customer) {
      return backend.api.post("api/trimit/customer", customer);
    },
    getCustomer: function getCustomer(searchString) {
      return backend.api.get("api/trimit/search/customer/withdetails?searchString=".concat(searchString));
    },
    getCustomerFields: function getCustomerFields() {
      return backend.api.get("api/trimit/fieldSetup");
    },
    toggleMember: function toggleMember(customerNumber, isMember) {
      return backend.api.patch("api/trimit/customer/customerId/".concat(customerNumber, "/ismember/").concat(isMember));
    },
    getDiscounts: function getDiscounts(basket) {
      return backend.api.post("api/trimit/basketprice", basket);
    },
    getWebOrders: function getWebOrders(firstName, lastName, email, phoneNo, orderNo) {
      var query = {};
      if (firstName) query.firstName = firstName;
      if (lastName) query.lastName = lastName;
      if (email) query.email = email;
      if (phoneNo) query.phoneNo = phoneNo;
      if (orderNo) query.orderNo = orderNo;
      return backend.api.get("api/trimit/orders", {
        params: query
      });
    },
    skip: function skip(weborder) {
      return backend.api["delete"]("api/trimit/orders/" + weborder.orderNo);
    }
  };
  return repo;
}).factory("webOrderRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getWebOrder: function getWebOrder(orderNo, webshopId) {
      return backend.api.get("api/weborders?orderNo=" + orderNo + "&webShopId=" + webshopId);
    },
    getWebOrders: function getWebOrders(firstName, lastName, email, phoneNo, orderNo, externalUniqueId) {
      var query = "";
      if (firstName) query += (query === "" ? "?" : "&") + "firstName=" + firstName;
      if (lastName) query += (query === "" ? "?" : "&") + "lastName=" + lastName;
      if (email) query += (query === "" ? "?" : "&") + "email=" + email;
      if (phoneNo) query += (query === "" ? "?" : "&") + "phoneNo=" + phoneNo;
      if (orderNo) query += (query === "" ? "?" : "&") + "orderNo=" + orderNo;
      if (externalUniqueId) query += (query === "" ? "?" : "&") + "externalUniqueId=" + externalUniqueId;
      return backend.api.get("api/weborders/query" + query);
    },
    printPickList: function printPickList(deviceIndex, webOrder, printTranslations) {
      var device = backend.getDeviceByIndex(deviceIndex);
      return device.post("/POSServiceHandler.aspx/PrintWebOrderPickList", {
        webOrder: webOrder,
        printTranslations: printTranslations
      });
    },
    update: function update(weborder) {
      return backend.api.put("api/weborders/linestatus", weborder);
    },
    skip: function skip(weborder) {
      return backend.api.patch("api/weborders/" + encodeURIComponent(weborder.orderNo) + "/webshops/" + weborder.webShopId + "/skip");
    },
    saleCompleted: function saleCompleted(orderNos) {
      return backend.api.put("api/weborders/completesale", orderNos);
    },
    createOrderInStore: function createOrderInStore(weborder, webshopId) {
      return backend.api.post("api/orderinstore/webshops/" + webshopId, weborder);
    }
  };
  return repo;
}).factory("customerOrderRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getCustomerOrders: function getCustomerOrders(searchParams) {
      return backend.api.post("api/customerorder/query", searchParams);
    },
    saveCustomerOrder: function saveCustomerOrder(customerOrder) {
      return backend.api.put("api/customerorder", customerOrder);
    },
    changeStatusCustomerOrder: function changeStatusCustomerOrder(id, status) {
      return backend.api.put("api/customerorder/".concat(id, "/status?status=").concat(status));
    },
    printPickList: function printPickList(deviceIndex, customerOrder, printTranslations) {
      var device = backend.getDeviceByIndex(deviceIndex);
      var customerOrderPickList = {
        orderNo: customerOrder.orderNo,
        status: customerOrder.statusText,
        pickListLines: []
      };
      angular.forEach(customerOrder.customerOrderLines, function (line) {
        var pickListLine = {
          barcode: line.article.ean,
          itemNo: line.itemNo,
          inStock: line.currentOnHandQuantity,
          toPick: line.quantity,
          picked: line.quantityPicked
        };
        customerOrderPickList.pickListLines.push(pickListLine);
      });
      return device.post("/POSServiceHandler.aspx/PrintCustomerOrderPickList", {
        customerOrderPickList: customerOrderPickList,
        printTranslations: printTranslations
      });
    }
  };
  return repo;
}).factory("customerRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  function parseBirthDate(date) {
    if (date) {
      return date.split("T")[0];
    }
    return null;
  }
  repo.api = {
    searchForCustomer: function searchForCustomer(firstName, lastName, email, customerNo, phone, zipCode, membership, after, recordCount) {
      var filters = [];
      if (firstName) filters.push({
        name: "firstName",
        value: firstName,
        or: false
      });
      if (lastName) filters.push({
        name: "lastName",
        value: lastName,
        or: false
      });
      if (email) filters.push({
        name: "email",
        value: email,
        or: false
      });
      if (customerNo) filters.push({
        name: "externalUniqueId",
        value: customerNo,
        or: false
      });
      if (phone) filters.push({
        name: "phone",
        value: phone,
        or: false
      });
      if (zipCode) filters.push({
        name: "zipCode",
        value: zipCode,
        or: false
      });
      if (membership) filters.push({
        name: "uniqueIdentifier",
        value: membership,
        or: false
      });
      return backend.api.post("api/Customer/Search?after=" + after + "&recordCount=" + recordCount, filters).then(function (resp) {
        resp.data.forEach(function (cust) {
          return cust.birthDate = parseBirthDate(cust.birthDate);
        });
        return resp;
      });
    },
    getCustomer: function getCustomer(customerNumber) {
      return backend.api.get("api/Customer/" + customerNumber).then(function (resp) {
        resp.data.birthDate = parseBirthDate(resp.data.birthDate);
        return resp;
      });
    },
    addCustomer: function addCustomer(customer) {
      return backend.api.post("api/Customer", customer);
    },
    addDownPaymentCustomer: function addDownPaymentCustomer(customer) {
      return backend.api.post("api/customer/createDownPaymentCustomer", customer);
    },
    updateDownPaymentCustomer: function updateDownPaymentCustomer(customer) {
      return backend.api.put("api/customer/updateDownPaymentCustomer/" + customer.id, customer).then(function (resp) {
        resp.data.birthDate = parseBirthDate(resp.data.birthDate);
        return resp;
      });
    },
    updateCustomer: function updateCustomer(customer) {
      return backend.api.put("api/Customer/" + customer.id, customer).then(function (resp) {
        resp.data.birthDate = parseBirthDate(resp.data.birthDate);
        return resp;
      });
    },
    getCompany: function getCompany(companyId) {
      return backend.api.get("api/company/" + companyId);
    },
    getCalculatedLoyaltyPoints: function getCalculatedLoyaltyPoints(receipt) {
      return backend.api.post("api/customer/loyaltyPointsCalculationBeforeSale", receipt);
    }
  };
  return repo;
}).factory("addressRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    add: function add(address) {
      return backend.api.post("api/Address", address);
    },
    addDownPaymentAddress: function addDownPaymentAddress(address) {
      return backend.api.post("api/address/addDownPaymentCustomerAddress", address);
    }
  };
  return repo;
}).factory("countryRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getByLanguageCode: function getByLanguageCode(languageCode) {
      return backend.api.get("api/country/getcountries/" + languageCode);
    }
  };
  return repo;
}).factory("intersolveRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getLoyaltyCustomer: function getLoyaltyCustomer(cardId) {
      return backend.api.get("api/intersolve/details/" + cardId, {
        ignoreError: true
      });
    },
    searchForLoyaltyCustomer: function searchForLoyaltyCustomer(firstname, lastname, streetname, zipcode, town, housenumber, email, mobile) {
      var data = {
        firstname: firstname,
        lastname: lastname,
        streetname: streetname,
        zipcode: zipcode,
        town: town,
        housenumber: housenumber,
        email: email,
        mobile: mobile
      };
      return backend.api.post("api/intersolve/customer/search/", data);
    },
    subtractBalance: function subtractBalance(refPos, customerId, ticketNumber, subtractAmount) {
      var data = {
        cardId: customerId,
        subtractAmount: subtractAmount,
        refPos: refPos,
        ticketNumber: ticketNumber
      };
      return backend.api.post("api/intersolve/giftcard/subtractbalance/", data);
    },
    CancelSubtractBalance: function CancelSubtractBalance(cardId, refPos) {
      var data = {
        cardId: cardId,
        refPos: refPos
      };
      return backend.api.post("api/intersolve/giftcard/cancel/", data);
    }
  };
  return repo;
}).factory("stockRepository", function (backendHelper, $q, $http, posStorage) {
  var repo = {};
  var backend = backendHelper.backend;
  repo.api = {
    getItemStockLevels: function getItemStockLevels(itemId, includeInactiveShops) {
      return backend.api.get("api/Items/" + itemId + "/stocklevels?includeInactiveShops=" + includeInactiveShops);
    },
    getStockFromClient: function getStockFromClient(stockLookupRequest) {
      return backend.api.post("api/StockLookup/getStockFromClient", stockLookupRequest);
    },
    getItemAvailability: function getItemAvailability(request) {
      return backend.api.post("api/StockLookup/itemAvailability", request);
    }
  };
  return repo;
}).factory("masterItemStockRepository", function (backendHelper, $q, $http, posStorage) {
  var repo = {};
  var backend = backendHelper.backend;
  repo.api = {
    getMasterItemStockInfo: function getMasterItemStockInfo(itemNo, storeId) {
      return backend.api.get("api/StockLookup/masteritem?masterItemNo=" + itemNo + "&storeId=" + storeId);
    }
  };
  return repo;
}).factory("storeRepository", function (backendHelper, $q, $http, posStorage) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getStores: function getStores() {
      return backend.api.get("api/StockLookup/locations");
    },
    getAllStores: function getAllStores() {
      return backend.api.get("api/shops/shops");
    }
  };
  return repo;
}).factory("insuranceRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getInsurance: function getInsurance(createInsuranceInformation) {
      return backend.api.post("api/insurance/create", createInsuranceInformation);
    },
    cancelInsurance: function cancelInsurance(cancelInsuranceInformation) {
      return backend.api.post("api/insurance/cancel", cancelInsuranceInformation);
    },
    getCustomer: function getCustomer(ssn, retailerId, retailerPassword) {
      var payload = {
        ssn: ssn,
        retailerId: retailerId,
        retailerPassword: retailerPassword
      };
      return backend.api.post("api/insurance/getcustomer/", payload);
    }
  };
  return repo;
}).factory("payPlazaRepository", function (backendHelper, $q, $http, posStorage, $log) {
  var repo = {};
  var backend = backendHelper.backend;
  repo.api = {
    handleTransaction: function handleTransaction(paymentId, receiptId, currencyName, ecrId, amount) {
      var data = {
        paymentId: paymentId,
        transId: receiptId,
        currency: currencyName,
        ecrId: ecrId,
        amount: amount
      };
      return backend.api.post("api/payment/payplaza", data, {
        timeout: 0 /* long poll: should never time out */
      });
    },
    cancelTransaction: function cancelTransaction(ecrId, paymentId) {
      backend.api.get("api/payment/payplaza/cancel/" + ecrId);
    },
    getLastReceipt: function getLastReceipt(ecrId) {
      return backend.api.get("api/payment/payplaza/receipt/" + ecrId);
    },
    acknowledgePayment: function acknowledgePayment(ecrId) {
      backend.api.get("api/payment/payplaza/acknowledge/" + ecrId);
    },
    getTerminalStatus: function getTerminalStatus(ecrId) {
      return backend.api.get("api/payment/payplaza/status/" + ecrId);
    }
  };
  repo.device = function (index) {
    if (index === undefined) index = backend.defaultDevice;
    return {
      deviceId: index,
      printTerminalReceipt: function printTerminalReceipt(receipt) {
        var data = {
          receipt: receipt
        };
        $log.debug("Print receipt: ");
        $log.debug(receipt);
        var that = this;
        return backendHelper.normalize(backend.devices[that.deviceId].post("/POSServiceHandler.aspx/PrintCardReceipt", data));
      }
    };
  };

  //var payPlazaAssociationBaseUrl = 'http://barcodesample.payplaza.software:8080/mpos-ecr-rest-api-3.0/api/'; // production
  //var api_token = 'ifvmjadlgi0ahs3k57a6p74v8ci6q09n'; // simulator
  //var api_token = 'j92jcsjgsuegcgcjej6gqcrubi29v5kk' // mbposbackground - development
  //var api_token = 'ifvmjadlgi0ahs3k57a6p74v8ci6q09n'; // simulator

  var payPlazaAssociationBaseUrl = "https://mpos-ecr-p01.payplaza.software/mpos-ecr-rest-api-3.0/api/"; // production
  var api_token = "pk7is1t5f72vf40o93hedik7u7qicdm1"; // production

  var headers = {
    Authorization: "Bearer " + api_token
  };
  repo.setScannerServiceBaseUrl = function (url) {
    posStorage.set("payPlazaScannerBaseUrl", url);
  };
  repo.getScannerServiceBaseUrl = function () {
    return posStorage.get("payPlazaScannerBaseUrl");
  };
  repo.initializeAssociation = function () {
    return $http.post(payPlazaAssociationBaseUrl + "initializeAssociation", {}, {
      headers: headers
    });
  };
  repo.getAssociationId = function (nonce) {
    return $http.post(payPlazaAssociationBaseUrl + "getAssociationId", {
      associationNonce: nonce
    }, {
      headers: headers
    });
  };
  repo.finalizeAssociation = function (associationId) {
    return $http.post(payPlazaAssociationBaseUrl + "finalizeAssociation", {
      associationId: associationId
    }, {
      headers: headers
    });
  };
  repo.activateAssociation = function (associationId) {
    $log.debug(moment().format("HH:mm:ss.SS") + ": activateAss with Id: " + associationId);
    return $http.post(repo.getScannerServiceBaseUrl() + "activateAssociation", {
      associationId: associationId
    }, {
      headers: headers
    });
  };
  repo.getConnectionState = function (associationId) {
    return $http.post(repo.getScannerServiceBaseUrl() + "getConnectionState", {
      associationId: associationId
    }, {
      headers: headers
    });
  };
  repo.getBarcode = function (associationId) {
    var canceler = $q.defer();
    $log.debug(moment().format("HH:mm:ss.SS") + ": getBarcode with Id: " + associationId);
    var promise = $http.post(repo.getScannerServiceBaseUrl() + "getBarcode", {
      associationId: associationId
    }, {
      headers: headers,
      ignoreLoadingBar: true,
      dontTrack: true,
      timeout: canceler.promise
    });
    promise.isResolved = false;
    promise["finally"](function () {
      promise.isResolved = true;
    });
    promise.abort = function () {
      $log.debug("cancelling long poll");
      canceler.resolve();
    };
    return promise;
  };
  return repo;
}).factory("tenantFeatureRepository", function (backendHelper) {
  var backend = backendHelper.backend;
  var repo = {};
  repo.api = {
    getTenantFetures: function getTenantFetures() {
      return backend.api.get("api/tenantFeature");
    },
    getTenantFetureByName: function getTenantFetureByName(featureName) {
      return backend.api.get("api/tenantFeature/byname/" + featureName);
    }
  };
  return repo;
});
