"use strict";

pos.directive("flipToggle", function flipToggle() {
  return {
    restrict: "A",
    link: function link(scope, ele, attr) {
      ele.bind("click touchstart", function () {
        ele.addClass("flip-toggle__pre--active");
        scope.resetTimeout = setTimeout(function () {
          ele.removeClass("flip-toggle__pre--active");
        }, 5000);
      });
    }
  };
});
