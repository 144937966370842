"use strict";

pos.directive("insuranceWidget", function (receipt, $rootScope, $state, notification, $translate, insuranceService, canInsureReturnEnum) {
  return {
    templateUrl: "views/widgets/widget-insurancewidget",
    controller: function controller($scope, Widgets) {
      var i18nErrorAlreadyInsured, i18nErrorCannotInsure;
      $translate(["widget/insurance/error/itemalreadyinsured", "widget/insurance/error/itemcannotbeinsured", "widget/insurance/error/cannotinsureinsurance"]).then(function (translations) {
        i18nErrorAlreadyInsured = translations["widget/insurance/error/itemalreadyinsured"];
        i18nErrorCannotInsure = translations["widget/insurance/error/itemcannotbeinsured"];
      });
      $scope.widgetsService = Widgets;
      $scope.hideWidgets = function () {
        $scope.widgetsService.showWidgets = false;
        $("#center-content.center-content--downscale").removeClass("center-content--downscale");
      };
      $scope.enableButton = function () {
        return receipt.selectedItemLineIndex < 0;
      };
      $scope.open = function () {
        var re = receipt.getReceipt().items;
        var selectedItem = re.length >= receipt.selectedItemLineIndex && receipt.selectedItemLineIndex >= 0 ? re[receipt.selectedItemLineIndex] : null;
        switch (insuranceService.canBeInsured(selectedItem)) {
          case canInsureReturnEnum.yes:
            $state.go("root.split.widgetpane", {
              widgetName: "mpeditinsurance"
            });
            break;
          case canInsureReturnEnum.no:
            notification.pop("error", i18nErrorCannotInsure);
            break;
          case canInsureReturnEnum.alreadyInsured:
            notification.pop("error", i18nErrorAlreadyInsured);
            break;
        }
      };
    }
  };
});
