"use strict";

pos.directive("mpweborderWidget", function (webOrderWidgetService, $translate, keypadManager, hideDivSuppressor, notification, webOrderTypeEnum, $state, receipt) {
  return {
    templateUrl: "views/widgets/widget-mpweborder",
    controller: function controller($scope, $log) {
      buildWebOrderSearchParameters();
      $scope.paginationSize = 20;
      $scope.after = 0;
      $scope.searchWebOrder = searchWebOrder;
      $scope.getMoreWebOrders = getMoreWebOrders;
      $scope.buildWebOrderSearchParameters = buildWebOrderSearchParameters;
      $scope.addToReceipt = addToReceipt;
      $scope.isSelected = isSelected;
      $scope.isNotSelected = isNotSelected;
      $scope.setSelected = setSelected;
      $scope.isClickAndCollect = isClickAndCollect;
      $scope.isOrderInStore = isOrderInStore;
      $scope.skipWebOrder = skipWebOrder;
      $scope.showWebOrder = showWebOrder;
      $scope.weborderModal = webOrderWidgetService.getWeborderModal("picklist");
      var selectedIndex = -1;
      var i18nCheckLog;
      var i18nMultipleWebordersInReceipt;
      $translate(["toast/check log", "weborder/error/multipleordersinreceipt"]).then(function () {
        i18nCheckLog = $translate.instant("toast/check log");
        i18nMultipleWebordersInReceipt = $translate.instant("weborder/error/multipleordersinreceipt");
      });
      searchWebOrder();
      function buildWebOrderSearchParameters() {
        $scope.searchParams = {
          firstName: {
            name: "FirstName",
            value: ""
          },
          lastName: {
            name: "LastName",
            value: ""
          },
          email: {
            name: "Email",
            value: ""
          },
          phoneNo: {
            name: "PhoneNo",
            value: ""
          },
          orderNo: {
            name: "OrderNo",
            value: ""
          }
        };
        $translate(["widget/webOrderFilter/searchFirstName", "widget/webOrderFilter/searchLastName", "widget/webOrderFilter/searchEmail", "widget/webOrderFilter/searchPhoneNo", "widget/webOrderFilter/searchOrderNo"]).then(function (translations) {
          $scope.searchParams.firstName.name = translations["widget/webOrderFilter/searchFirstName"];
          $scope.searchParams.lastName.name = translations["widget/webOrderFilter/searchLastName"];
          $scope.searchParams.email.name = translations["widget/webOrderFilter/searchEmail"];
          $scope.searchParams.phoneNo.name = translations["widget/webOrderFilter/searchPhoneNo"];
          $scope.searchParams.orderNo.name = translations["widget/webOrderFilter/searchOrderNo"];
        });
      }
      function searchWebOrder() {
        keypadManager.hide();
        if (hideDivSuppressor.target) hideDivSuppressor.target.hide();
        $scope.searchResults = [];
        $scope.after = 0;
        $scope.fetchFailed = false;
        getMoreWebOrders();
      }
      function getMoreWebOrders() {
        webOrderWidgetService.getMoreWebOrders($scope.searchParams, $scope.after, $scope.paginationSize).then(successCallback, errorCallback);
      }
      function addToReceipt(webOrder) {
        if (receipt.getReceipt().items.some(function (i) {
          return i.webOrderNumber;
        })) {
          notification.pop("error", i18nMultipleWebordersInReceipt);
          return;
        }
        webOrderWidgetService.addToReceipt(webOrder);
        $state.go("root.split.pos");
      }
      function isSelected(index) {
        return index === selectedIndex;
      }
      function isNotSelected(index) {
        return !isSelected(index);
      }
      function setSelected(index) {
        selectedIndex = index;
      }
      function isOrderInStore(webOrder) {
        return webOrder.orderType === webOrderTypeEnum.orderInStore;
      }
      function isClickAndCollect(webOrder) {
        return webOrder.orderType === webOrderTypeEnum.clickAndCollect;
      }
      function skipWebOrder(webOrder) {
        webOrderWidgetService.skipWebOrder(webOrder).then(function (res) {
          var index = $scope.searchResults.findIndex(function (o) {
            return o.orderNo === webOrder.orderNo;
          });
          if (index > -1) {
            if (res.data && res.data.orderNo === webOrder.orderNo) {
              $scope.searchResults[index] = res.data;
            } else {
              $scope.searchResults.splice(index, 1);
            }
          }
        });
      }
      function showWebOrder(webOrder) {
        webOrderWidgetService.showWebOrderModalFromWeborder(webOrder);
      }
      function successCallback(response) {
        for (var i = 0; i < response.length; i++) {
          $scope.searchResults.push(response[i]);
        }
        if (response.length === 0) {
          $scope.fetchFailed = true;
        }
        $scope.after += $scope.paginationSize;
      }
      function errorCallback(errorResponse) {
        $log.debug("searchByParameter error");
        $log.debug(errorResponse);
        notification.pop("error", i18nCheckLog);
      }
    },
    link: function link(scope, el, attr, controller) {
      scope.reset = reset;
      function reset() {
        scope.buildWebOrderSearchParameters();
        scope.searchWebOrder();
      }
    }
  };
});
