"use strict";

pos.controller("DownPaymentSelectCustomerController", function ($state, downpayOrderService, $scope) {
  "use strict";

  $scope.customers = downpayOrderService.getCustomerSearch();
  $scope.selectCustomerLine = function (customer) {
    downpayOrderService.setSelectedCustomer(customer);
    if (downpayOrderService.isBooking) {
      $state.go("root.split.downpayment-selectbillingaddress");
    } else {
      $state.go("root.split.pos");
    }
  };
});
