"use strict";

pos.factory("verifoneService", ["eventBus", "sales", "$q", function (eventBus, sales, $q) {
  var responseHandler;
  var flowDeferred;
  var service = {};
  var splitDisplayTextParameters = function splitDisplayTextParameters(displayText) {
    return displayText.split(";").map(function (o) {
      return o.split("=");
    }).reduce(function (pre, curr) {
      pre[curr[0].toLowerCase()] = curr[1];
      return pre;
    }, {});
  };
  service.startVoiceReferralFlow = function (status, cardPayment) {
    flowDeferred = $q.defer();
    eventBus.notify(eventBus.events.payment.verifone.voiceReferralApproval, splitDisplayTextParameters(status.displayText));
    responseHandler = eventBus.subscribe(eventBus.events.payment.verifone.voiceReferralApprovalResponse, null, function (ev, textInput) {
      return voiceReferralApprovalResponse(textInput, cardPayment);
    });
    return flowDeferred.promise;
  };
  service.startSignatureFlow = function (status, cardPayment) {
    flowDeferred = $q.defer();
    cardPayment.cardReceipt = cardPayment.cardReceipt.substring(0, cardPayment.cardReceipt.length - status.bonText.length);
    if (cardPayment.cardReceipt === "") {
      cardPayment.cardReceipt = null;
    }
    sales.printTerminalReceipt(status.bonText);
    eventBus.notify(eventBus.events.payment.verifone.signatureApproval);
    responseHandler = eventBus.subscribe(eventBus.events.payment.verifone.signatureApprovalResponse, null, function (ev, approved) {
      return signatureApprovalResponse(approved, cardPayment);
    });
    return flowDeferred.promise;
  };
  var voiceReferralApprovalResponse = function voiceReferralApprovalResponse(textInput, cardPayment) {
    if (textInput) {
      sales.verifoneVoiceApproval(cardPayment.paymentDeviceId, cardPayment.message, cardPayment.amount, true, textInput.toUpperCase()).then(function () {
        return flowDeferred.resolve();
      });
    } else {
      sales.verifoneVoiceApproval(cardPayment.paymentDeviceId, cardPayment.message, cardPayment.amount, false, "").then(function () {
        return flowDeferred.resolve();
      });
    }
    responseHandler();
    responseHandler = null;
  };
  var signatureApprovalResponse = function signatureApprovalResponse(approved, cardPayment) {
    sales.verifoneSignatureApproval(cardPayment.paymentDeviceId, cardPayment.message, cardPayment.amount, approved).then(function () {
      return flowDeferred.resolve();
    });
    responseHandler();
    responseHandler = null;
  };
  return service;
}]);
