"use strict";

pos.factory("accountPaymentType", function (receipt, $q, findCustomerService, defaultPaymentType, inputValidationService, sales, $filter, notification, posConfig, $translate, $injector) {
  var service = {};
  var i18nCreditLimitExceededTitle, i18nCreditLimitExceeded, i18nCreditLimitExceededCompany;
  $translate(["toast/credit-limit-exceeded", "toast/credit-limit-exceeded-company", "toast/credit-limit-exceeded-title"]).then(function (translations) {
    i18nCreditLimitExceeded = translations["toast/credit-limit-exceeded"];
    i18nCreditLimitExceededTitle = translations["toast/credit-limit-exceeded-title"];
    i18nCreditLimitExceededCompany = translations["toast/credit-limit-exceeded-company"];
  });

  /**
   * Initialise an account payment type.
   * @param  {string} paymentType         The type of payment, e.g. 'Cash', 'ManualCard', etc.
   * @param  {Decimal|number} amount      The amount passed in by the keyboard
   * @param  {object} currency            A curreny object, usually fetch from posConfig.getCurrencySetup(name) or posConfig.getLocalCurrency()
   * @param  {string} contextInfo         Any additional information this payment type may carry
   * @return {promise}                    $q promise which is rejected with a status or resolved with the makePayment response
   */
  service.makePayment = function (paymentType, amount, currency, contextInfo) {
    var currentCustomer = receipt.getCustomer();
    if (!currentCustomer || !currentCustomer.customerNumber) {
      return $q.reject("NO_CUSTOMER_SELECTED");
    }
    try {
      amount = inputValidationService.convertToDecimal(amount);
    } catch (err) {
      return $q.reject(err);
    }

    // we require a correct format for the amount and if the receipt is a return (negative total)
    // then we do not allow the clerk to try and pay back more than the total amount, because the sale
    // has to sum to 0 when all is said and done
    if (defaultPaymentType.isAmountInvalid(amount)) {
      return $q.reject("INVALID_AMOUNT");
    }

    // we allow 0 payments if there's items on the receipt (e.g. an exchanged item is a return + a sold at same price)
    // we also allow payments without any items, if the clerk want to withdraw some money
    // we only require that there either must a value in the payment OR items on the reciept
    if (amount.isZero() && receipt.getReceipt().items.length === 0) {
      return $q.reject("INVALID_TYPE_AMOUNT");
    }
    try {
      if (!validateBalance(amount, findCustomerService.getSelectedCustomer())) return $q.reject();
    } catch (err) {
      return $q.reject(err);
    }
    if (receipt.getTotal().minus(receipt.getPaymentsTotal()).isNegative() && !amount.isNegative()) amount = amount.abs().neg(); // make sure that it's positive before we negate it

    var paymentStatus = receipt.getTotal().minus(receipt.getPaymentsTotal());
    if (amount.abs().gt(paymentStatus.abs())) {
      return $q.reject("PAYMENT_AMOUNT_EXCEEDS_REMAINING_AMOUNT");
    }
    return sales.makePayment(paymentType, amount, currency, contextInfo);
  };
  service.clearCustomer = function (paymentState) {
    findCustomerService.removeSelectedCustomer();
    return $q.resolve(paymentState);
  };
  service.validatePaymentChoice = function (choice, remainder, currency) {
    //If we're using trimit skip the validate payment choice
    //As there are no imagine customers to link on
    if (isUsingTrimit() === true) {
      return true;
    }
    var customer = findCustomerService.getSelectedCustomer();
    var company = findCustomerService.company;
    if (!customer) {
      throw "NO_CUSTOMER_SELECTED";
    }
    var validationResults = [];
    validationResults.push(validateCurrency(customer));
    validationResults.push(validateBalance(remainder, customer, company, findCustomerService.getChargeToCompany()));
    return validationResults.reduce(function (acc, cur) {
      return acc && cur;
    }, true);
  };
  function isUsingTrimit() {
    var trimitWidget = posConfig.getWidgets().find(function (widget) {
      return widget.name === "trimit";
    });
    if (trimitWidget) {
      return true;
    } else {
      return false;
    }
  }
  function validateBalance(remainder, customer, company, chargeToCompany) {
    //If we're using trimit skip the validate balance choice
    //As there are no imagine customers to link on to get a currency
    if (isUsingTrimit() === true) {
      return true;
    }
    if (!remainder.isNeg()) {
      var paymentService = $injector.get("paymentService");
      var currencies = posConfig.getCurrencySetup();
      var currency = Object.keys(currencies).map(function (cur) {
        return currencies[cur];
      }).find(function (o) {
        return o.name == customer.currencyCode;
      });
      if (!currency) throw "CUSTOMER_ACCT_CURRENCY_NOT_SETUP";
      var creditLimit = chargeToCompany ? company.creditLimit : customer.creditLimit;
      var balance = chargeToCompany ? company.currentBalance : customer.currentBalance;
      var remainderInAccountCurrency = paymentService.convertToCurrency(currency, remainder);
      var creditLimitDecimal = new Decimal(creditLimit);
      var newBalance = new Decimal(balance).minus(remainderInAccountCurrency);
      if (newBalance.lt(creditLimitDecimal)) {
        if (!customer.unboundCreditLimit) {
          var exceeds = $filter("number")(newBalance.minus(creditLimitDecimal).abs().toNumber(), 2);
          var name = customer.externalUniqueId;
          if (customer.firstName || customer.lastName) name = "".concat(customer.firstName ? customer.firstName : "").concat(customer.lastName ? " " + customer.lastName : "");
          var text = chargeToCompany ? i18nCreditLimitExceededCompany.replace("{exceeds}", exceeds).replace("{currency}", currency.name) : i18nCreditLimitExceeded.replace("{name}", name).replace("{exceeds}", exceeds).replace("{currency}", currency.name);
          notification.pop("error", i18nCreditLimitExceededTitle, text, 8000);
          return false;
        }
      }
    }
    return true;
  }
  function validateCurrency(customer) {
    if (!customer.currencyCode) {
      throw "CUSTOMER_NO_ACCOUNT_CURRENCY";
    }
    var currencies = posConfig.getCurrencySetup();
    var currencySetupForShop = Object.keys(currencies).map(function (cur) {
      return currencies[cur];
    }).map(function (cur) {
      return cur.name;
    }).includes(customer.currencyCode);
    if (!currencySetupForShop) {
      throw "CUSTOMER_ACCT_CURRENCY_NOT_SETUP";
    }
    return true;
  }
  return service;
});
