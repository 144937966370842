"use strict";

/**
 * Service for hooking onto the connector part
 * of the backend service.
 *
 * @returns {object} scope
 */
pos.service("ConnectorService", function (backend) {
  var scope = {};
  scope.defaultConnector = "";

  /**
   * Defines the connectors based on backend data
   *
   * @returns {object} connectors
   */
  scope.getConnectors = function () {
    var connectors = [];

    // Iterate through connectors
    for (var i = 0; i < backend.devices.length; i++) {
      var connector = backend.devices[i];

      // Append the connector to the connectors array
      connectors.push({
        text: connector.deviceName,
        click: "setDefaultArm(" + i + ")",
        reboot: "rebootArm(" + i + ")",
        device: connector
      });
    }
    return connectors;
  };

  /**
   * Checks whether any devices are online.
   *
   * @returns {bool} childscope.result
   */
  scope.anyConnectorOnline = function () {
    for (var i = 0; i < backend.devices.length; i++) {
      var currentConnector = backend.devices[i];

      // If an online connector is encounted, break the loop
      // and set the online property to true
      if (!currentConnector.isStatic && currentConnector.isOnline) {
        return true;
      }
    }
    return false;
  };

  /**
   * Method for filtering connectors, only showing static
   * connectors if all other connectors are offline.
   *
   * @param {object} connector
   * @returns {bool} chilscope.result
   */
  scope.filterConnectors = function (connector) {
    // Enable the static connector (Default) if no devices are online
    if (connector.device.isStatic === true) {
      return !scope.anyConnectorOnline();
    }
    return true;
  };

  /**
   * Returns a boolean depending on whether the
   * device is selected or not.
   *
   * @param {number} currentConnector - The current connector index
   * @param {object} selectedConnector - The currently selected connector
   * @returns {bool} chilscope.result
   */
  scope.connectorIsSelected = function (currentConnector, selectedConnector) {
    return currentConnector.index == selectedConnector;
  };

  // Serve the service
  return scope;
});
