"use strict";

pos.directive("payplazaWidget", function (payplazaService, keypadManager, posConfig, $translate, notification) {
  return {
    templateUrl: "views/widgets/widget-payplaza",
    link: function link(scope, ele, attr) {
      var passwordProtectedMethod;
      scope.reprint = function () {
        payplazaService.reprintReceipt();
      };
      scope.setEftId = function (eftId) {
        payplazaService.setEftId(eftId);
      };
      scope.requestPassword = function (method) {
        scope.trigger = true;
        passwordProtectedMethod = method;
      };
      scope.validateAndRun = function validateAndRun() {
        scope.trigger = false; // just making really sure that the input is removed
        var d = new Date();
        var password = d.getFullYear() - Number(d.getMonth() + 1) + "" + d.getDate();
        var inputPwd = scope.password;
        scope.password = "";
        if (inputPwd === password) return payplazaService[passwordProtectedMethod]();
        notification.pop("warning", i18nIncorrectPwd);
      };
      scope.eftIdExists = false;
      scope.enterEftId = "EFT id";
      scope.i18nEnterPassword = "Enter password";
      var i18nIncorrectPwd;
      $translate(["payplazaWiget/enterEFTid", "payplazaWiget/uncoupleEFT", "input/enterpassword", "toast/incorrectpassword"]).then(function (translations) {
        scope.enterEftId = translations["payplazaWiget/enterEFTid"];
        scope.i18nUncoupleEft = translations["payplazaWiget/uncoupleEFT"];
        scope.i18nEnterPassword = translations["input/enterpassword"];
        i18nIncorrectPwd = translations["toast/incorrectpassword"];
      });
      scope.payplazaService = payplazaService;
      scope.$watch(function () {
        return payplazaService.getEftId();
      }, function (eftId) {
        scope.eftIdExists = !!eftId; // convert to boolean
        scope.eftId = "";
      });
      scope.connectScanner = function () {
        payplazaService.connectScanner();
      };
      scope.isScanning = payplazaService.isScanning;
    }
  };
});
