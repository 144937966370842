"use strict";

pos.directive("dummyWidget", function (keypadManager, posConfig) {
  return {
    templateUrl: "views/widgets/widget-dummy",
    link: function link(scope, ele, attr) {
      //dummy comment
    }
  };
});
