"use strict";

function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
pos.directive("trimitweborderWidget", function (trimitService, $translate, keypadManager, hideDivSuppressor, notification, $state) {
  return {
    templateUrl: "views/widgets/widget-trimitweborder",
    controller: function controller($scope, $log) {
      $scope.paginationSize = 20;
      $scope.after = 0;
      var selectedIndex = -1;
      var i18nCheckLog;
      $scope.buildWebOrderSearchParameters = function () {
        $scope.searchParams = {
          firstName: {
            name: "FirstName",
            value: ""
          },
          lastName: {
            name: "LastName",
            value: ""
          },
          email: {
            name: "Email",
            value: ""
          },
          phoneNo: {
            name: "PhoneNo",
            value: ""
          },
          orderNo: {
            name: "OrderNo",
            value: ""
          }
        };
        $translate(["widget/webOrderFilter/searchFirstName", "widget/webOrderFilter/searchLastName", "widget/webOrderFilter/searchEmail", "widget/webOrderFilter/searchPhoneNo", "widget/webOrderFilter/searchOrderNo", "toast/check log"]).then(function (translations) {
          $scope.searchParams.firstName.name = translations["widget/webOrderFilter/searchFirstName"];
          $scope.searchParams.lastName.name = translations["widget/webOrderFilter/searchLastName"];
          $scope.searchParams.email.name = translations["widget/webOrderFilter/searchEmail"];
          $scope.searchParams.phoneNo.name = translations["widget/webOrderFilter/searchPhoneNo"];
          $scope.searchParams.orderNo.name = translations["widget/webOrderFilter/searchOrderNo"];
          i18nCheckLog = translations["toast/check log"];
        });
      };
      $scope.searchWebOrder = function () {
        keypadManager.hide();
        if (hideDivSuppressor.target) hideDivSuppressor.target.hide();
        $scope.searchResults = [];
        $scope.after = 0;
        $scope.fetchFailed = false;
        $scope.getMoreWebOrders();
      };
      $scope.getMoreWebOrders = function () {
        trimitService.getMoreWebOrders($scope.searchParams, $scope.after, $scope.paginationSize).then(handleWebOrders, notifyError);
      };
      $scope.addToReceipt = function (webOrder) {
        trimitService.addToReceipt(webOrder);
        $state.go("root.split.pos");
      };
      $scope.isSelected = function (index) {
        return index === selectedIndex;
      };
      $scope.isNotSelected = function (index) {
        return !$scope.isSelected(index);
      };
      $scope.setSelected = function (index) {
        selectedIndex = index;
      };
      $scope.skipWebOrder = function (webOrder) {
        trimitService.skipWebOrder(webOrder).then(function (res) {
          var index = $scope.searchResults.findIndex(function (o) {
            return o.orderNo === webOrder.orderNo;
          });
          if (index > -1) {
            if (res.data && res.data.orderNo === webOrder.orderNo) {
              $scope.searchResults[index] = res.data;
            } else {
              $scope.searchResults.splice(index, 1);
            }
          }
        });
      };
      function handleWebOrders(response) {
        $scope.searchResults = [].concat(_toConsumableArray($scope.searchResults), _toConsumableArray(response));
        if (response.length === 0) {
          $scope.fetchFailed = true;
        }
        $scope.after += $scope.paginationSize;
      }
      function notifyError(errorResponse) {
        $log.debug("searchByParameter error");
        $log.debug(errorResponse);
        notification.pop("error", i18nCheckLog);
      }
      $scope.buildWebOrderSearchParameters();
      $scope.searchWebOrder();
    },
    link: function link(scope, el, attr, controller) {
      scope.reset = reset;
      function reset() {
        scope.buildWebOrderSearchParameters();
        scope.searchWebOrder();
      }
    }
  };
});
