"use strict";

pos.controller("ParkedReceiptsCtrl", function ($scope, receipt, notification, $translate, posConfig, receiptHelper, posStorage, clerkLoginService, eventBus) {
  init();
  var selectedIndex = -1;
  var i18nParkedReceipt;
  var clerks = {};
  loadConfig();
  $scope.receiptHelper = receiptHelper;
  $scope.printTranslations = posStorage.get("printTranslations");
  $translate(["label/noItemsReceipt", "label/oneItemReceipt", "toast/parked note"]).then(function (translations) {
    $scope.i18nNoItems = translations["label/noItemsReceipt"];
    $scope.i18nOne = translations["label/oneItemReceipt"];
    i18nParkedReceipt = translations["toast/parked note"];
  });
  $translate("label/otherItemsReceipt", {
    u: "{}"
  }).then(function (translation) {
    $scope.i18nOther = translation;
  });
  $scope.selectLine = function (index) {
    if (selectedIndex === index) {
      selectedIndex = -1;
      return;
    }
    selectedIndex = index;
  };
  $scope.selectedLineIndex = function () {
    return selectedIndex;
  };
  $scope.isSelected = function (index) {
    return selectedIndex === index;
  };
  $scope.getClerkName = function (id) {
    return _.isObject(clerks[id]) ? clerks[id].firstName : clerks[id];
  };
  $scope.getStringSafe = function (variable) {
    return typeof variable === "string" ? variable : "";
  };
  $scope.totalItems = function (receipt) {
    return receipt.items.reduce(function (sum, item) {
      return sum + item.quantity;
    }, 0);
  };
  $scope.showReceiptDiscount = function (receipt) {
    var totalDiscount = receiptHelper.getReceiptTotalDiscount(receipt);
    return !totalDiscount.isZero() && !totalDiscount.isNeg();
  };
  function init() {
    receipt.getParkedReceipts().then(function (response) {
      if (!response.data) return;
      angular.forEach(response.data, function (receiptBox) {
        receiptBox.receipt = receipt.decimalifyReceipt(receiptBox.receipt);
        putToClerkDictionary(receiptBox.receipt.clerk);
      });
      $scope.receiptsBoxes = response.data;
    });
  }
  function putToClerkDictionary(clerkId) {
    clerks[clerkId] = clerkId;
    clerkLoginService.getClerk(clerkId).then(function (clerk) {
      clerks[clerkId] = clerk;
    });
  }
  $scope.loadReceipt = function (receiptBox) {
    if (!receipt.isEmpty()) return notification.pop("warning", i18nParkedReceipt);
    receiptBox.receipt.date = new Date();
    receipt.loadReceipt(receiptBox.receipt);
    receipt.deleteParkedReceipt(receiptBox.id);
    $scope.$state.go("root.split.pos");
  };
  $scope.deleteParkedNote = function (receiptBox) {
    receipt.deleteParkedReceipt(receiptBox.id).then(init);
  };
  function loadConfig() {
    $scope.noteConfiguration = posConfig.noteConfiguration;
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, $scope, loadConfig);
});
