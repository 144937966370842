"use strict";

pos.controller("PayWidgetCtrl", function ($scope, backend, ConnectorService, receipt) {
  $scope.selectedArmDevice = null;
  $scope.$watch(function () {
    return backend.defaultDevice;
  }, function (index) {
    $scope.selectedArmDevice = index;
  });
  $scope.$watch(function () {
    var payments = receipt.getReceipt().payments;
    return angular.isArray(payments) ? payments.length : 0;
  }, function (length) {
    $scope.disableSelection = length > 0;
  });

  // Get connector list
  $scope.connectors = ConnectorService.getConnectors();

  // Filter connectors
  $scope.filterConnectors = ConnectorService.filterConnectors;

  // Selected connector comparator
  $scope.connectorIsSelected = function (connectorIndex, connector) {
    return ConnectorService.connectorIsSelected({
      index: connectorIndex,
      connector: connector
    }, $scope.selectedArmDevice);
  };
  $scope.setDefaultArm = function (armIndex) {
    if ($scope.disableSelection || !backend.devices[armIndex].isOnline) return;
    backend.setDefaultDevice(armIndex);
  };
});

//defaultArm.id === connector.device.id && connector.device.isOnline
