"use strict";

pos.factory("setService", function (posStorage, receipt, $translate, notification) {
  var service = {};
  var setPrice = new Decimal(0);
  var set = [];
  function persistSet() {
    posStorage.set("setList", set);
  }
  function init() {
    set = posStorage.get("setList") || [];
    angular.forEach(set, function (item) {
      item.effectiveSalesPrice = new Decimal(item.effectiveSalesPrice);
      item.salesPrice = new Decimal(item.salesPrice);
    });
    if (service.getSetTotal() !== service.getSetPrice()) setPrice = service.getSetPrice();
  }
  service.addSetItem = function addSetItem(item) {
    if (item.disableDiscount) {
      $translate(["receiptItem/toast/itemHasDiscountBlocked", "receiptItem/toast/cantAddItemToSet"]).then(function (translations) {
        notification.pop("info", translations["receiptItem/toast/cantAddItemToSet"], translations["receiptItem/toast/itemHasDiscountBlocked"]);
      });
      return;
    }
    item.quantity = 1;
    item.salesPrice = new Decimal(item.salesPrice);
    item.effectiveSalesPrice = new Decimal(item.salesPrice);
    item.calculatedDiscount = new Decimal(0);
    item.setNo = (posStorage.get("setNo") || 0) + 1;
    item.uuid = receipt.generateUUID();
    item.discountDetails = [];

    // mark the new item as the new setEnd or setStart
    item.templateType = set.length === 0 ? "setStart" : "setEnd";
    // change the current setEnd to setItem
    if (set.length >= 2) {
      set[set.length - 1].templateType = "setItem";
    }
    // add the new item to the set
    set.push(item);

    // changing price also persists
    if (!setPrice.isZero()) service.setSetPrice(setPrice);else persistSet();
  };
  service.removeSetItem = function removeSetItem(index) {
    set.splice(index, 1);
    persistSet();
  };
  service.clearSet = function clearSet() {
    set = [];
    setPrice = new Decimal(0);
    persistSet();
  };
  service.getSetTotal = function getSetTotal() {
    var total = new Decimal(0);
    angular.forEach(set, function (item) {
      total = total.plus(item.salesPrice);
    });
    return total;
  };
  service.getSetPrice = function getSetTotal() {
    var total = new Decimal(0);
    angular.forEach(set, function (item) {
      total = total.plus(item.effectiveSalesPrice);
    });
    return total;
  };
  service.setSetPrice = function setSetPrice(price) {
    setPrice = new Decimal(price);
    // calculate the discount percentage for the total
    var discount = receipt.calculateDiscountPercentage(setPrice, service.getSetTotal());
    angular.forEach(set, function (item) {
      // apply the discount percentage to each line

      receipt.setItemDiscountPercentage(item, discount, undefined, "Line", true);
    });
    correctRoundingDifference();
    persistSet();
  };
  function correctRoundingDifference() {
    var setTotal = service.getSetTotal();
    var totalDiscount = new Decimal(0);
    var topItem;
    angular.forEach(set, function (item) {
      totalDiscount = totalDiscount.plus(item.calculatedDiscount);
      // store the highest value item
      if (!topItem || topItem.salesPrice.lt(item.salesPrice)) topItem = item;
    });

    // it would be weird if there wasn't a sigle item in the set
    if (!topItem) return;
    var diff = totalDiscount.plus(setPrice).minus(setTotal);
    topItem.effectiveSalesPrice = topItem.effectiveSalesPrice.plus(diff);
  }
  service.getSet = function getSet() {
    return set;
  };
  service.addSetToReceipt = function addSetToReceipt() {
    if (set.length < 2) return false;
    posStorage.set("setNo", posStorage.get("setNo") + 1);
    receipt.addSet(set);
    service.clearSet();
    return true;
  };
  init();
  return service;
});
