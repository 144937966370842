"use strict";

pos.factory("giftCertificate", function (posStorage, posConfig, voucherRepository, fluctuatingPropertiesHelper) {
  "use strict";

  var giftCertificate = {};
  var seqKey = "sequenceNo";
  var seqDateKey = "sequenceNoDate";
  var tenantIdKey = "tenantId";
  function shopId() {
    var padLength = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
    return pad(posConfig.shopId, padLength);
  }
  function getPosId() {
    return pad(posConfig.posId, 3);
  }
  function incSeqNo() {
    posStorage.set(seqKey, getSeqNo() + 1);
    posStorage.set(seqDateKey, new Date());
  }
  function resetSeqNo() {
    posStorage.set(seqKey, 1);
    posStorage.set(seqDateKey, new Date());
  }
  function getSeqNo() {
    var today = new Date();
    var usedDate = new Date(posStorage.get(seqDateKey)) || today;
    if (usedDate.getDate() !== today.getDate()) resetSeqNo();
    return Number(posStorage.get(seqKey));
  }
  function getTenantId() {
    return pad(posStorage.get(tenantIdKey), 5);
  }
  function getSeqNoString() {
    return pad(getSeqNo(), 3);
  }
  function getDate() {
    var today = new Date();
    var dd = pad(today.getDate(), 2);
    var mm = pad(today.getMonth() + 1, 2);
    var yy = today.getFullYear() % 100;
    return dd + mm + yy;
  }
  function pad(value, width, padding) {
    padding = padding || "0";
    value = value + "";
    return value.length >= width ? value : new Array(width - value.length + 1).join(padding) + value;
  }

  // the giftno should have a even number of characters otherwise it cannot be printed ITF - Interleave 2 or 5
  function generateGiftNo(type, addPadding) {
    var giftNo;
    var posId = getPosId();
    if (addPadding) {
      posId = pad(posId, posId.length + 1);
    }
    var extVouchersFeature = posConfig.getShopFeatures().externalVouchers;
    if (extVouchersFeature) {
      if (extVouchersFeature.values.enabled && extVouchersFeature.values.provider.toLowerCase() == "shopify") giftNo = [type, shopId(5), getDate(), posId, getSeqNoString()].join("");
    }
    if (!giftNo) giftNo = [type, getTenantId(), shopId(), getDate(), posId, getSeqNoString()].join("");
    if (giftNo.length % 2 == 1) giftNo = generateGiftNo(type, true);
    return giftNo;
  }
  giftCertificate.generateGiftCertificate = function (preview) {
    var giftCert = generateGiftNo(1);
    if (!preview) incSeqNo();
    return giftCert;
  };
  giftCertificate.generateCreditVoucher = function (preview) {
    var giftCert = generateGiftNo(2);
    if (!preview) incSeqNo();
    return giftCert;
  };
  giftCertificate.lastVoucherNoReceived = function (lastVoucher) {
    var seqNoFromLastVoucher = +lastVoucher.substr(lastVoucher.length - 3);
    while (seqNoFromLastVoucher >= getSeqNo()) {
      incSeqNo();
    }
  };
  giftCertificate.verifyGiftCertificate = function (giftCertificateNumber) {
    return voucherRepository.api.verifyGiftCertificate(giftCertificateNumber);
  };
  giftCertificate.setDateSpecificExpiry = function (item, receiptDate) {
    var isGiftCertificate = giftCertificate.isGiftCertificate(item.voucherNumber);
    var expiryInDays;
    if (isGiftCertificate && posConfig.getGiftCertificateExpiryInDays()) {
      expiryInDays = posConfig.getGiftCertificateExpiryInDays();
    } else if (!isGiftCertificate && posConfig.getCreditnoteExpiryInDays()) {
      expiryInDays = posConfig.getCreditnoteExpiryInDays();
    }
    if (expiryInDays) {
      var actualDate = moment.utc(receiptDate, "YYYY-MM-DD").startOf("day").add(expiryInDays + 1, "days").toJSON();
      fluctuatingPropertiesHelper.addKeyValue("expirationDate", actualDate, item);
    }
  };
  giftCertificate.isExpired = function (giftcertificate) {
    if (giftcertificate.expirationDate && new Date(giftcertificate.expirationDate).toISOString() < new Date(Date.now()).toISOString()) {
      return true;
    }
    return false;
  };
  giftCertificate.isGiftCertificate = function (vouchernumber) {
    return vouchernumber.startsWith("1");
  };
  return giftCertificate;
});
