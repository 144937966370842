"use strict";

pos.directive("trimitnewcustomerWidget", function ($location, $translate, trimitService, notification) {
  return {
    templateUrl: "views/widgets/widget-trimitnewcustomer",
    controller: function controller($scope) {
      $scope.formSubmitted = false;
      $scope.customerFields = trimitService.customerFields;
      $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      $scope.newCustomer = {};
    },
    link: function link(scope) {
      var i18nFailedToAddCustomer;
      $translate(["widget/trimit/failedtoaddcustomer"]).then(function (translations) {
        i18nFailedToAddCustomer = translations["widget/trimit/failedtoaddcustomer"];
      });
      scope.displayInEditPanel = function (fieldName) {
        var customerAttrib = scope.customerFields.find(function (attrib) {
          return attrib.fieldName === fieldName;
        });
        return customerAttrib && customerAttrib.visibleEditPanel;
      };
      scope.isFieldRequired = function (fieldName) {
        var isRequiredField = scope.customerFields.find(function (attrib) {
          return attrib.fieldName === fieldName;
        });
        return isRequiredField && isRequiredField.required;
      };
      scope.isDisabled = function (fieldName) {
        var isDisabledField = scope.customerFields.find(function (attrib) {
          return attrib.fieldName === fieldName;
        });
        return isDisabledField && isDisabledField.readOnly;
      };
      scope.saveCustomer = function (formState) {
        scope.formSubmitted = true;
        if (!formState) return;
        trimitService.addCustomer(scope.newCustomer).then(function (response) {
          scope.saveSuccess(response);
        })["catch"](function (error) {
          scope.saveFailed(error);
        });
      };
      scope.saveSuccess = function (response) {
        if (response) {
          trimitService.setCustomer(response);
          $location.path("pos");
        }
      };
      scope.saveFailed = function (errorResponse) {
        notification.pop("error", i18nFailedToAddCustomer, errorResponse);
      };
    }
  };
});
