"use strict";

pos.factory("fluctuatingPropertiesHelper", function () {
  var webOrderTypeKey = "webOrderType";
  var fluctuatingPropertiesHelper = {};
  fluctuatingPropertiesHelper.setWebOrderType = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[webOrderTypeKey] = value;
  };
  var customerOrderNo = "customerOrderNo";
  fluctuatingPropertiesHelper.setCustomerOrderNo = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[customerOrderNo] = value;
  };
  fluctuatingPropertiesHelper.getWebOrderType = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties[webOrderTypeKey]) return;
    return item.fluctuatingProperties[webOrderTypeKey];
  };
  fluctuatingPropertiesHelper.updateProperties = function (item) {
    initProps(item);

    // Exchange related properties
    if (item.isGift) {
      item.fluctuatingProperties["isGift"] = item.isGift;
      item.fluctuatingProperties["exchangeExpiryDate"] = moment.utc(item.exchangeExpiryDate, "DD/MM/YYYY").toISOString();
    }
  };
  fluctuatingPropertiesHelper.setExchangeLabelBarcodeScanned = function (item, barcode) {
    initProps(item);
    item.fluctuatingProperties["usedExchangeLabelBarcode"] = barcode;
    item.isGift = item.fluctuatingProperties["isGift"];
    item.exchangeExpiryDate = item.fluctuatingProperties["exchangeExpiryDate"];
  };
  fluctuatingPropertiesHelper.setIsExcludedFromLoyalty = function (item, isExcluded) {
    initProps(item);
    if (isExcluded) {
      item.fluctuatingProperties["isExcludedFromLoyalty"] = isExcluded;
    } else {
      delete item.fluctuatingProperties["isExcludedFromLoyalty"];
    }
    item.isExcludedFromLoyalty = isExcluded;
  };
  fluctuatingPropertiesHelper.setVNumber = function (item, vNumber) {
    initProps(item);
    if (vNumber) {
      item.fluctuatingProperties["vNumber"] = vNumber;
    } else {
      delete item.fluctuatingProperties["vNumber"];
    }
  };
  fluctuatingPropertiesHelper.getVNumber = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["vNumber"]) return;
    return item.fluctuatingProperties["vNumber"];
  };
  fluctuatingPropertiesHelper.setIsBooking = function (item, isBooking) {
    initProps(item);
    if (isBooking) {
      item.fluctuatingProperties["isBooking"] = isBooking;
    } else {
      delete item.fluctuatingProperties["isBooking"];
    }
  };
  fluctuatingPropertiesHelper.getIsBooking = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["isBooking"]) return;
    return item.fluctuatingProperties["isBooking"];
  };
  fluctuatingPropertiesHelper.getDownPaymentNo = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["downPaymentNo"]) return;
    return item.fluctuatingProperties["downPaymentNo"];
  };
  fluctuatingPropertiesHelper.setDownPaymentNo = function (item, downPaymentNo) {
    initProps(item);
    if (downPaymentNo) {
      item.fluctuatingProperties["downPaymentNo"] = downPaymentNo;
    } else {
      delete item.fluctuatingProperties["downPaymentNo"];
    }
  };
  fluctuatingPropertiesHelper.getIsBooking = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["isBooking"]) return;
    return item.fluctuatingProperties["isBooking"];
  };
  fluctuatingPropertiesHelper.getMaxPaymentValue = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["maxPaymentValue"]) return;
    return item.fluctuatingProperties["maxPaymentValue"];
  };
  fluctuatingPropertiesHelper.setMaxPaymentValue = function (item, value) {
    initProps(item);
    if (value) {
      item.fluctuatingProperties["maxPaymentValue"] = value;
    } else {
      delete item.fluctuatingProperties["maxPaymentValue"];
    }
  };
  fluctuatingPropertiesHelper.getDownPaymentValue = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["downPaymentValue"]) return;
    return item.fluctuatingProperties["downPaymentValue"];
  };
  fluctuatingPropertiesHelper.setDownPaymentValue = function (item, downPaymentValue) {
    initProps(item);
    if (downPaymentValue) {
      item.fluctuatingProperties["downPaymentValue"] = downPaymentValue;
    } else {
      delete item.fluctuatingProperties["downPaymentValue"];
    }
  };
  fluctuatingPropertiesHelper.setBookingPayment = function (item, bookingPayment) {
    initProps(item);
    if (bookingPayment) {
      item.fluctuatingProperties["bookingPayment"] = bookingPayment;
    } else {
      delete item.fluctuatingProperties["bookingPayment"];
    }
  };
  fluctuatingPropertiesHelper.getBookingAmountToBePaid = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["amountToBePaid"]) return;
    return item.fluctuatingProperties["amountToBePaid"];
  };
  fluctuatingPropertiesHelper.getBookingPayments = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["bookingPayments"]) return;
    return item.fluctuatingProperties["bookingPayments"];
  };
  fluctuatingPropertiesHelper.addKeyValue = function (key, value, item) {
    initProps(item);
    item.fluctuatingProperties[key] = value;
  };
  fluctuatingPropertiesHelper.setExchangeBarcode = function (value, item) {
    initProps(item);
    item.fluctuatingProperties["exchangeBarcode"] = value;
  };
  fluctuatingPropertiesHelper.getExchangeBarcode = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["exchangeBarcode"]) return;
    return item.fluctuatingProperties["exchangeBarcode"];
  };
  fluctuatingPropertiesHelper.getExchangeExpiryDate = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["exchangeExpiryDate"]) return;
    return item.fluctuatingProperties["exchangeExpiryDate"];
  };
  var isGiftAidableProperty = "GiftAid_isGiftAidable";
  var giftAidTitlePropertyName = "GiftAid_title";
  var giftAidFirstNamePropertyName = "GiftAid_firstName";
  var giftAidLastNamePropertyName = "GiftAid_lastName";
  var giftAidHouseNumberPropertyName = "GiftAid_houseNumber";
  var giftAidPostalCodePropertyName = "GiftAid_postalCode";
  fluctuatingPropertiesHelper.setItemAsGiftAidable = function (item) {
    initProps(item);
    item.fluctuatingProperties[isGiftAidableProperty] = true;
  };
  var serialNumberPropertyName = "SerialNo";
  fluctuatingPropertiesHelper.setItemAsSerialized = function (item) {
    initProps(item);
    item.serialButtonIsVisible = true;
    item.fluctuatingProperties[serialNumberPropertyName] = item.serialNo;
  };
  fluctuatingPropertiesHelper.getSerialNo = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties["SerialNo"]) return;
    return item.fluctuatingProperties["SerialNo"];
  };
  fluctuatingPropertiesHelper.isItemGiftAidable = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties[isGiftAidableProperty]) return;
    return item.fluctuatingProperties[isGiftAidableProperty];
  };
  fluctuatingPropertiesHelper.setTransactionAsGiftAidable = function (receipt, isGiftAidable) {
    initProps(receipt);
    receipt.fluctuatingProperties[isGiftAidableProperty] = isGiftAidable;
  };
  fluctuatingPropertiesHelper.isTransactionGiftAidable = function (receipt) {
    if (!receipt.fluctuatingProperties) return;
    return receipt.fluctuatingProperties[isGiftAidableProperty];
  };
  fluctuatingPropertiesHelper.setTransactionGiftAidTitle = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[giftAidTitlePropertyName] = value;
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidTitle = function (receipt) {
    return fluctuatingPropertiesHelper.getTransactionGiftAidValue(receipt, giftAidTitlePropertyName);
  };
  fluctuatingPropertiesHelper.setTransactionGiftAidFirstName = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[giftAidFirstNamePropertyName] = value;
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidFirstName = function (receipt) {
    return fluctuatingPropertiesHelper.getTransactionGiftAidValue(receipt, giftAidFirstNamePropertyName);
  };
  fluctuatingPropertiesHelper.setTransactionGiftAidLastName = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[giftAidLastNamePropertyName] = value;
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidLastName = function (receipt) {
    return fluctuatingPropertiesHelper.getTransactionGiftAidValue(receipt, giftAidLastNamePropertyName);
  };
  fluctuatingPropertiesHelper.setTransactionGiftAidHouseNumber = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[giftAidHouseNumberPropertyName] = value;
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidHouseNumber = function (receipt) {
    return fluctuatingPropertiesHelper.getTransactionGiftAidValue(receipt, giftAidHouseNumberPropertyName);
  };
  fluctuatingPropertiesHelper.setTransactionGiftAidPostalCode = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[giftAidPostalCodePropertyName] = value;
  };
  var infrasecCTUIDPropertyName = "Infrasec_CTUID";
  fluctuatingPropertiesHelper.setInfrasecCTUID = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecCTUIDPropertyName] = value;
  };
  var infrasecCodePropertyName = "Infrasec_Code";
  fluctuatingPropertiesHelper.setInfrasecCode = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecCodePropertyName] = value;
  };
  var infrasecRegisterIdPropertyName = "Infrasec_RegisterId";
  fluctuatingPropertiesHelper.setInfrasecRegisterId = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecRegisterIdPropertyName] = value;
  };
  var infrasecRequestIdPropertyName = "Infrasec_RequestId";
  fluctuatingPropertiesHelper.setInfrasecRequestId = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecRequestIdPropertyName] = value;
  };
  var infrasecOrganisationNumberPropertyName = "Infrasec_OrganisationNumber";
  fluctuatingPropertiesHelper.setInfrasecOrganisationNumber = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecOrganisationNumberPropertyName] = value;
  };
  var infrasecCopyCTUIDPropertyName = "Infrasec_Copy_CTUID";
  fluctuatingPropertiesHelper.setInfrasecCopyCTUID = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecCopyCTUIDPropertyName] = value;
  };
  var infrasecCopy_CodePropertyName = "Infrasec_Copy_Code";
  fluctuatingPropertiesHelper.setInfrasecCopyCode = function (receipt, value) {
    initProps(receipt);
    receipt.fluctuatingProperties[infrasecCopy_CodePropertyName] = value;
  };
  fluctuatingPropertiesHelper.getInfrasecCopyCode = function (receipt) {
    if (!receipt.fluctuatingProperties || !receipt.fluctuatingProperties[infrasecCopy_CodePropertyName]) return;
    return receipt.fluctuatingProperties[infrasecCopy_CodePropertyName];
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidPostalCode = function (receipt) {
    return fluctuatingPropertiesHelper.getTransactionGiftAidValue(receipt, giftAidPostalCodePropertyName);
  };
  fluctuatingPropertiesHelper.getTransactionGiftAidValue = function (receipt, key) {
    if (!receipt.fluctuatingProperties) return;
    return receipt.fluctuatingProperties[key];
  };
  fluctuatingPropertiesHelper.removeTransactionGiftAidProperties = function (receipt) {
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, isGiftAidableProperty);
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, giftAidTitlePropertyName);
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, giftAidFirstNamePropertyName);
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, giftAidLastNamePropertyName);
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, giftAidHouseNumberPropertyName);
    fluctuatingPropertiesHelper.removeTransactionGiftAidProperty(receipt, giftAidPostalCodePropertyName);
  };
  fluctuatingPropertiesHelper.removeTransactionGiftAidProperty = function (receipt, key) {
    if (!receipt.fluctuatingProperties) return;
    if (receipt.fluctuatingProperties[key]) receipt.fluctuatingProperties[key] = undefined;
  };
  var webshopIdPropertyName = "webShopId";
  fluctuatingPropertiesHelper.setWebshopId = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[webshopIdPropertyName] = value;
  };
  fluctuatingPropertiesHelper.getWebshopId = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties[webshopIdPropertyName]) return;
    return item.fluctuatingProperties[webshopIdPropertyName];
  };
  var uniqueTransactionId = "uniqueTransactionId";
  fluctuatingPropertiesHelper.setUniqueTransactionId = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[uniqueTransactionId] = value;
  };
  fluctuatingPropertiesHelper.getUniqueTransactionId = function (item) {
    if (!item.fluctuatingProperties || !item.fluctuatingProperties[uniqueTransactionId]) return;
    return item.fluctuatingProperties[uniqueTransactionId];
  };
  var voucherResponseCode = "voucherResponseCode";
  fluctuatingPropertiesHelper.setVoucherResponseCode = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[voucherResponseCode] = value;
  };
  fluctuatingPropertiesHelper.getVoucherResponseCode = function (item) {
    if (!item.fluctuatingProperties || item.fluctuatingProperties[voucherResponseCode] == undefined) return;
    return item.fluctuatingProperties[voucherResponseCode];
  };
  var voucherBalance = "voucherBalance";
  fluctuatingPropertiesHelper.setVoucherBalance = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[voucherBalance] = value;
  };
  var voucherExpiryDate = "voucherExpiryDate";
  fluctuatingPropertiesHelper.setVoucherExpiryDate = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[voucherExpiryDate] = value;
  };
  var voucherDescription = "voucherDescription";
  fluctuatingPropertiesHelper.setVoucherDescription = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[voucherDescription] = value;
  };
  var returnTransactionLineIdPropertyName = "Return_TransactionLineId";
  fluctuatingPropertiesHelper.setReturnTransactionLineId = function (item, value) {
    initProps(item);
    item.fluctuatingProperties[returnTransactionLineIdPropertyName] = value;
  };
  function initProps(item) {
    item.fluctuatingProperties = item.fluctuatingProperties || {};
  }
  return fluctuatingPropertiesHelper;
});
