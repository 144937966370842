"use strict";

pos.directive("weborderWidget", function (webOrderWidgetService, $filter, $translate) {
  return {
    templateUrl: "views/widgets/widget-weborder",
    controller: function controller($scope, Widgets) {
      $scope.widgetsService = Widgets;
      $scope.hideWidgets = function () {
        $scope.widgetsService.showWidgets = false;
        $("#center-content.center-content--downscale").removeClass("center-content--downscale");
      };
    },
    link: function link(scope, ele, attr) {
      scope.ordersVisible = false;
      scope.loadingOrders = false;
      scope.ordersInitiallyFetched = false;
      scope.error = false;
      scope.toggleOrder = function (order) {
        scope.activeOrder = scope.activeOrder == order ? null : order;
      };
      scope.getWebOrders = function () {
        // Indicate loading
        scope.loadingOrders = true;

        // Fetch products
        webOrderWidgetService.getWebOrders().then(function (response) {
          var ordersQuery = $filter("orderBy")(response, "-orderNo");
          angular.forEach(ordersQuery, function (item) {
            item.price = new Decimal(item.price);
          });

          // Check if the new orders are equal to the old
          if (scope.availableOrders != ordersQuery) {
            scope.availableOrders = ordersQuery;
            scope.ordersInitiallyFetched = true;
          }
          scope.loadingOrders = false;
          scope.ordersVisible = true;
          scope.error = false;
        }, function (error) {
          scope.loadingOrders = false;
          scope.error = error;
        });
      };
    }
  };
});
