"use strict";

pos.factory("selectDimensionComboService", function (posStorage) {
  var callback;
  var service = {};
  service.setMasterItemData = function (mi) {
    return posStorage.set("dimension-select-masteritem", mi);
  };
  service.getMasterItemData = function () {
    return posStorage.get("dimension-select-masteritem");
  };
  service.clearMasterItemData = function () {
    return posStorage.remove("dimension-select-masteritem");
  };
  service.setCallBack = function (cb) {
    return callback = cb;
  };
  service.callCallBack = function (item) {
    return callback(item);
  };
  return service;
});
