"use strict";

pos.controller("ExpenseCtrl", function ($scope, $log, $state, itemgroup, receipt, notification, $translate, keypadManager, $filter, sales, $timeout, inputValidationService) {
  "use strict";

  $scope.priceField = 0;
  $scope.numberField = 1;
  $scope.targetValues = ["", ""];
  var i18nProperAmount;
  var selectedItemLineIndex = 0;
  $translate(["button/itemgroup no/name", "input/enter_amount", "toast/proper amount"]).then(function (translations) {
    $scope.placeholder1 = translations["button/itemgroup no/name"];
    $scope.placeholder2 = translations["input/enter_amount"];
    i18nProperAmount = translations["toast/proper amount"];
  });
  itemgroup.getExpenseGroups().then(function (response) {
    $scope.itemgroup = response.data;
    $scope.itemgroup = $filter("orderBy")($scope.itemgroup, "name");
  });
  $scope.selectItemLine = function (index) {
    selectedItemLineIndex = index;
  };
  $scope.selectedItemLineIndex = function () {
    return selectedItemLineIndex;
  };
  $scope.reset = function () {
    $scope.itemNo = "";
    $scope.amount = "";
  };
  $scope.search = function (itemGroup) {
    if (!$scope.itemNo || itemGroup.name.toLowerCase().indexOf($scope.itemNo.toLowerCase()) != -1 || itemGroup.no.toString().toLowerCase().indexOf($scope.itemNo.toLowerCase()) != -1) {
      return true;
    }
    return false;
  };
  $scope.addToReceipt = function (price) {
    try {
      price = inputValidationService.convertToDecimal(price);
      if (price.isNaN() || price.isZero() || !inputValidationService.validateAmount(price)) throw "INVALID_AMOUNT";
    } catch (err) {
      notification.pop("info", i18nProperAmount);
      return;
    }
    var filteredExpenses = $filter("filter")($scope.itemgroup, $scope.itemNo);
    receipt.addExpense(price * -1, filteredExpenses[selectedItemLineIndex].name, filteredExpenses[selectedItemLineIndex].no);
    $state.go("root.split.pos");
  };
  $scope.hideKeypad = function () {
    keypadManager.hide(); //Hide Keypad
  };
});
