"use strict";

pos.controller("PickWeborderController", function ($scope, $translate, webOrderRepository, notification, $location, webOrderStatusEnum) {
  $scope.webOrderStatusEnum = webOrderStatusEnum;
  if ($scope.$stateParams.orderNo) {
    $scope.orderNo = $scope.$stateParams.orderNo;
  } else {
    $location.path("/"); // Cancel sale if no order no is present
  }

  // Get order status translations
  var orderStatusTranslations = {};
  $translate(["pickweborder/orderstatus/reserved", "pickweborder/orderstatus/picked", "pickweborder/orderstatus/delivered", "pickweborder/orderstatus/returned", "pickweborder/orderstatus/returnedpayment", "pickweborder/orderstatus/skipped"]).then(function (translations) {
    orderStatusTranslations[webOrderStatusEnum.reserved] = translations["pickweborder/orderstatus/reserved"];
    orderStatusTranslations[webOrderStatusEnum.picked] = translations["pickweborder/orderstatus/picked"];
    orderStatusTranslations[webOrderStatusEnum.delivered] = translations["pickweborder/orderstatus/delivered"];
    orderStatusTranslations[webOrderStatusEnum.returned] = translations["pickweborder/orderstatus/returned"];
    orderStatusTranslations[webOrderStatusEnum.returnedPayment] = translations["pickweborder/orderstatus/returnedpayment"];
    orderStatusTranslations[webOrderStatusEnum.skipped] = translations["pickweborder/orderstatus/skipped"];
  });

  // Private methods
  function getWebOrder(orderNo, webShopId) {
    webOrderRepository.api.getWebOrder(orderNo, webShopId).then(function (response) {
      if (response && response.data) {
        $scope.weborder = response.data;
      }
    });
  }

  // Public methods
  $scope.translateStatus = function (statusId) {
    var statusTranslation = orderStatusTranslations[statusId];
    if (statusTranslation === undefined) return "";
    return statusTranslation;
  };
  $scope.pick = function () {
    webOrderRepository.api.updateAsPicked($scope.weborder).then(function (response) {
      if (!response || !response.data) {
        notification.pop("warning", "Picking order failed");
      }

      // Refresh view to show changes
      getWebOrder($scope.orderNo, $scope.webShopId);
    });
  };
  $scope.toggleItem = function (item) {
    // Certain statuses cannot change
    if (item.status === webOrderStatusEnum.delivered || item.status === webOrderStatusEnum.returned || item.status === webOrderStatusEnum.returnedPayment) return;

    // Item cannot change status from 'picked' if that status came from the server
    if (item.status === webOrderStatusEnum.picked && item.oldStatus) {
      item.status = item.oldStatus;
    } else {
      item.oldStatus = item.status;
      item.status = webOrderStatusEnum.picked;
    }
  };

  // Init
  getWebOrder($scope.orderNo, $scope.webShopId);
});
