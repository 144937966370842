"use strict";

pos.factory("returnReasons", function (posConfig, $q, eventBus) {
  "use strict";

  var service = {};
  var itemDeffered, receiptDeffered;
  loadConfig();
  service.overlay = false;
  service.receiptReturnOverlay = false;
  service.returnReasonsSelector = function () {
    itemDeffered = $q.defer();
    service.overlay = true;
    return itemDeffered.promise;
  };
  service.returnReceiptReasonsSelector = function () {
    receiptDeffered = $q.defer();
    service.receiptReturnOverlay = true;
    return receiptDeffered.promise;
  };
  service.applyReturnReason = function (returnReason) {
    hide();
    itemDeffered.resolve(returnReason);
    itemDeffered = null;
  };
  service.applyReceiptReturnReason = function (returnReason) {
    hide();
    receiptDeffered.resolve(returnReason);
    receiptDeffered = null;
  };
  service.blur = function () {
    if (itemDeffered) {
      itemDeffered.reject("dismissed");
      itemDeffered = null;
    }
    if (receiptDeffered) {
      receiptDeffered.reject("dismissed");
      receiptDeffered = null;
    }
  };
  service.haveReturnReasons = function () {
    return service.items && service.items.length > 0;
  };
  service.haveReceiptReturnReasons = function () {
    return service.receiptReasonItems && service.receiptReasonItems.length > 0;
  };
  function hide() {
    service.overlay = false;
    service.receiptReturnOverlay = false;
  }
  function loadConfig() {
    service.items = posConfig.getReturnReasons();
    service.receiptReasonItems = posConfig.getReceiptReturnReasons();
  }
  service.getReturnReasons = function () {
    return service.items;
  };
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
  return service;
});
