"use strict";

pos.directive("downpayOrderWidget", function (downpayOrderService, addressHelper, $translate, $state, $location) {
  return {
    templateUrl: "views/widgets/widget-downpay-order",
    link: function link(scope) {
      scope.downpayOrder = {
        customer: downpayOrderService.loadSelectedCustomer(),
        billingAddress: null,
        shipment: null
      };
      scope.isBooking = downpayOrderService.isBooking;
      var i18nYes;
      var i18nNo;
      $translate(["label/yes", "label/no"]).then(function (translations) {
        i18nYes = translations["label/yes"];
        i18nNo = translations["label/no"];
      });
      scope.$watch(function () {
        return downpayOrderService.getSelectedCustomer();
      }, function (customer) {
        if (customer) {
          scope.downpayOrder.customer = customer;
        } else {
          scope.downpayOrder.customer = null;
        }
      });
      scope.$watch(function () {
        return downpayOrderService.getShipment();
      }, function (shipment) {
        if (shipment) {
          scope.downpayOrder.shipment = shipment;
        } else {
          scope.downpayOrder.shipment = null;
        }
      });
      scope.$watch(function () {
        return downpayOrderService.getSelectedBillingAddress();
      }, function (address) {
        if (address) {
          scope.downpayOrder.billingAddress = address;
        } else {
          scope.downpayOrder.billingAddress = null;
        }
      });
      scope.clearCustomer = function () {
        scope.downpayOrder.customer = null;
        scope.downpayOrder.billingAddress = null;
        scope.downpayOrder.shipping = null;
        downpayOrderService.clearCustomer();
      };
      scope.clearBillingAddress = function () {
        downpayOrderService.clearBillingAddress();
        scope.downpayOrder.billingAddress = null;
      };
      scope.clearShippingAddress = function () {
        downpayOrderService.clearShippingAddress();
        scope.downpayOrder.shipment.address = null;
        scope.downpayOrder.shipment.store = null;
      };
      scope.getAddressString = function (address) {
        return addressHelper.getAddressString(address);
      };
      scope.setTransactionBookingType = function () {
        downpayOrderService.setTransactionBookingType();
        scope.isBooking = downpayOrderService.isBooking;
        if (scope.downpayOrder.customer) {
          if (scope.downpayOrder.billingAddress) {
            $state.go("root.split.downpayment-shipment");
          } else {
            $state.go("root.split.downpayment-selectbillingaddress");
          }
        } else {
          $location.path("widget/mpcustomer");
        }
      };
      scope.clearTransactionBookingType = function () {
        downpayOrderService.clearTransactionBookingType();
        scope.isBooking = downpayOrderService.isBooking;
        $state.go("root.split.pos");
      };
      scope.getBoolText = function (value) {
        if (value) {
          return i18nYes;
        }
        return i18nNo;
      };
    }
  };
});
