"use strict";

pos.directive("loyaltyWidget", function (loyaltyService, loyaltyStaticDiscountService, keypadManager, $translate) {
  return {
    templateUrl: "views/widgets/widget-loyalty",
    link: function link(scope) {
      var i18nNoLevel;
      scope.i18nCustomerNumberPlaceholder = "";
      $translate(["widget/loyalty/customerNumberPlaceholder", "widget/loyalty/noLevel"]).then(function (translations) {
        scope.i18nCustomerNumberPlaceholder = translations["widget/loyalty/customerNumberPlaceholder"];
        i18nNoLevel = translations["widget/loyalty/noLevel"];
      });
      loyaltyService.getLoyaltyCustomer().then(function (member) {
        scope.loyaltyCustomer = member;
        if (member) scope.customerInput = member.customerNumber;
      });
      scope.lookupCustomer = function () {
        var attachMember = true;
        if (!scope.customerInput || isNaN(scope.customerInput)) return;
        loyaltyService.lookupCustomer(scope.customerInput, attachMember).then(function (member) {
          attachCustomer(member);
        }, function () {
          detachCustomerAndHideKeyPad();
        });
      };
      function attachCustomer(customer) {
        keypadManager.hide();
        scope.loyaltyCustomer = customer;
        scope.customerInput = customer.customerNumber;
      }
      function detachCustomerAndHideKeyPad() {
        scope.detachCustomer();
        return keypadManager.hide();
      }
      scope.detachCustomer = function () {
        loyaltyService.detachCustomer();
        scope.loyaltyCustomer = null;
        scope.customerInput = "";
      };
      scope.getLevelUpPercentage = function (member) {
        var nextLevel = getNextLevel(member);
        if (!member || !nextLevel) return 0;
        var percentageOfNextLevel = new Decimal(member.totalPoints).div(nextLevel.startPoints).times(100).toFixed(2);
        return percentageOfNextLevel;
      };
      scope.getCurrentLevel = function (member) {
        if (!member || !member.currentLevel) return {
          levelName: i18nNoLevel,
          value: 0
        };
        return member.currentLevel;
      };
      function getNextLevel(member) {
        if (!member || !member.nextLevel) return null;
        return member.nextLevel;
      }
      scope.showLevelEngineMemberInfo = function (member) {
        return member && (member.engineTypeIdentifier == 1 || member.engineTypeIdentifier == 3); // DiscountLevelEngine or BonusLevelEngine
      };
      scope.showStaticDiscountEngineMemberInfo = function (member) {
        return loyaltyStaticDiscountService.isStaticDiscountEngineMember(member);
      };
    }
  };
});
