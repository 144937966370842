"use strict";

var _require = require("decimal.js"),
  Decimal = _require["default"];
var _require2 = require("lodash"),
  findLast = _require2.findLast;
pos.factory("uuid", function ($window) {
  function generateUUID() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (d + $window.Math.random() * 16) % 16 | 0;
      d = $window.Math.floor(d / 16);
      return (c == "x" ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  }
  return generateUUID;
});
pos.factory("receiptHelper", function (receipt) {
  var onehundred = new Decimal(100);
  function getReceiptSalesPriceTotal(receipt) {
    var total = new Decimal(0);
    angular.forEach(receipt.items, function (item) {
      total = total.plus(new Decimal(item.quantity).abs().times(item.salesPrice));
    });
    return total;
  }
  function getReceiptEffectiveSalesPriceTotal(receipt) {
    var total = new Decimal(0);
    angular.forEach(receipt.items, function (item) {
      total = total.plus(new Decimal(item.quantity).abs().times(getLineEffectiveSalesPrice(item)));
    });
    return total.minus(getReceiptRoundingDifference(receipt));
  }
  function getReceiptTotal(receipt) {
    var total = new Decimal(0);
    angular.forEach(receipt.items, function (item) {
      total = total.plus(new Decimal(item.quantity).times(getLineEffectiveSalesPrice(item)));
    });
    return total;
  }
  function getReceiptRoundingDifference(receipt) {
    var roundingDifference = new Decimal(0);
    if (receipt.payments) receipt.payments.forEach(function (payment) {
      if (payment.paymentType === "Rounding") roundingDifference = roundingDifference.plus(payment.amount);
    });
    return roundingDifference;
  }
  function getReceiptTotalDiscount(receipt) {
    if (receipt.payments) return onehundred.minus(getReceiptEffectiveSalesPriceTotal(receipt).times(onehundred).dividedBy(getReceiptSalesPriceTotal(receipt)));
    return new Decimal(0);
  }
  function getReceiptTotalDiscountCurrency(receipt) {
    var discount = getReceiptEffectiveSalesPriceTotal(receipt);
    var total = getReceiptSalesPriceTotal(receipt);
    return total.minus(discount);
  }
  function getItemCount(receipt) {
    var total = Decimal(0);
    angular.forEach(receipt.items, function (item) {
      if (item.itemgroup === 9918) return;
      total = total.plus(new Decimal(item.quantity).abs());
    });
    return total.toNumber();
  }
  function getPaymentCount(receipt) {
    var total = Decimal(0);
    angular.forEach(receipt.payments, function (item) {
      total = total.plus(new Decimal(item.quantity).abs());
    });
    return total.toNumber();
  }
  function getLineDiscountPctWithRounding(line) {
    if (line.roundingDiff === undefined) {
      line.totalDiscountPercent = onehundred.minus(line.effectiveSalesPrice.times(onehundred).dividedBy(line.salesPrice));
    } else {
      line.totalDiscountPercent = onehundred.minus(getLineEffectiveSalesPrice(line).times(onehundred).dividedBy(line.salesPrice));
    }
    line.totalDiscountPercent = line.totalDiscountPercent.toDecimalPlacesUnrounded(2);
    return line.totalDiscountPercent;
  }
  function getReceiptDiscountPctWithRounding(line, receipt) {
    line.totalDiscountPercent = onehundred.minus(getLineEffectiveSalesPriceTotal(line).times(onehundred).dividedBy(line.salesPrice));
    line.totalDiscountPercent = line.totalDiscountPercent.toDecimalPlacesUnrounded(2);
    return line.totalDiscountPercent;
  }
  function getLineDiscountPct(line, receipt) {
    if (receipt === undefined) {
      return getLineDiscountPctWithRounding(line);
    }
    return getReceiptDiscountPctWithRounding(line, receipt);
  }
  function getLineEffectiveSalesPrice(line) {
    if (line.roundingDiff) {
      return line.effectiveSalesPrice.plus(line.roundingDiff / line.quantity);
    }
    return line.effectiveSalesPrice;
  }
  function getLineEffectiveSalesPriceTotal(line) {
    return receipt.getSelectedLineTotal(line);
  }
  function getLineSalesPriceTotal(line) {
    return line.salesPrice.times(line.quantity);
  }
  function isLineDiscounted(line) {
    return line.effectiveSalesPrice.lessThan(line.salesPrice);
  }
  function includesLineDiscount(line) {
    return _.some(line.discountDetails, ["type", "Line"]);
  }
  function getLineInfo(line) {
    // Add cases to return relevant info for different types of payment
    switch (line.paymentType) {
      case "Card":
        return line.cardName;
      default:
        return "";
    }
  }
  function getReceiptVatTotal(receipt) {
    var finalData = [];
    var groupedData = _.groupBy(receipt.items, "vat");
    angular.forEach(groupedData, function (vatItem) {
      angular.forEach(vatItem, function (item) {
        var quantity = new Decimal(item.quantity).times(getLineEffectiveSalesPrice(item));
        var itemVat = quantity - new Decimal(quantity / (100 + item.vat) * 100);
        var data = {
          vatPercent: item.vat,
          vatAmount: itemVat
        };
        finalData.push(data);
      });
    });
    var helper = {};
    var result = finalData.reduce(function (r, o) {
      var key = o.vatPercent;
      if (!helper[key]) {
        helper[key] = Object.assign({}, o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].vatAmount += o.vatAmount;
      }
      return r;
    }, []);
    return result;
  }
  return {
    getReceiptSalesPriceTotal: getReceiptSalesPriceTotal,
    getReceiptEffectiveSalesPriceTotal: getReceiptEffectiveSalesPriceTotal,
    getReceiptTotal: getReceiptTotal,
    getReceiptVatTotal: getReceiptVatTotal,
    getReceiptTotalDiscount: getReceiptTotalDiscount,
    getReceiptTotalDiscountCurrency: getReceiptTotalDiscountCurrency,
    getItemCount: getItemCount,
    getPaymentCount: getPaymentCount,
    getLineDiscountPct: getLineDiscountPct,
    getLineEffectiveSalesPriceTotal: getLineEffectiveSalesPriceTotal,
    getLineSalesPriceTotal: getLineSalesPriceTotal,
    isLineDiscounted: isLineDiscounted,
    includesLineDiscount: includesLineDiscount,
    getLineInfo: getLineInfo
  };
});
pos.factory("addressHelper", function () {
  function getAddressString(a) {
    var arr = [];
    var whitespace = " ";
    var seperator = ", ";
    var g = [];
    if (a.address1) g.push(a.address1);
    if (a.address2) g.push(a.address2);
    if (a.address3) g.push(a.address3);
    var g1 = g.join(whitespace);
    g = [];
    if (a.city) g.push(a.city);
    if (a.county) g.push(a.county);
    var g2 = g.join(whitespace);
    g = [];
    if (a.state) g.push(a.state);
    if (a.zipCode) g.push(a.zipCode);
    var g3 = g.join(whitespace);
    g = [];
    if (a.country) g.push(a.country);
    var g4 = g.join(whitespace);
    arr.push(g1, g2, g3, g4);
    var filtered = arr.filter(function (x) {
      return x !== "" && x;
    });
    return filtered.join(seperator);
  }
  return {
    getAddressString: getAddressString
  };
});
