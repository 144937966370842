"use strict";

pos.controller("ItemgroupCtrl", function ($scope, $log, $state, itemgroup, receipt, notification, $translate, keypadManager, $timeout, $filter, inputValidationService) {
  "use strict";

  itemgroup.getItemGroups().then(function (response) {
    $scope.itemgroup = response.data;
    $scope.itemgroup = $filter("orderBy")($scope.itemgroup, "name");
  });
  $scope.search = function (itemGroup) {
    if (!$scope.itemNo || itemGroup.name.toLowerCase().indexOf($scope.itemNo.toLowerCase()) != -1 || itemGroup.no.toString().toLowerCase().indexOf($scope.itemNo.toLowerCase()) != -1) {
      return true;
    }
    return false;
  };
  var selectedItemLineIndex = 0;
  var i18nProperAmount;
  $translate(["button/itemgroup no/name", "input/enter_amount", "toast/proper amount", "button/sort by name", "button/sort by no"]).then(function (translations) {
    $scope.placeholder1 = translations["button/itemgroup no/name"];
    $scope.placeholder2 = translations["input/enter_amount"];
    i18nProperAmount = translations["toast/proper amount"];
    $scope.sortByName = translations["button/sort by name"];
    $scope.sortByNo = translations["button/sort by no"];
  });
  $scope.sortSelect = "name";
  $scope.backToReceipt = function () {
    $state.go("root.split.pos");
  };
  $scope.addToReceipt = function (price) {
    try {
      price = inputValidationService.convertToDecimal(price);
      if (price.isNaN() || price.isZero() || !inputValidationService.validateAmount(price)) throw "INVALID_AMOUNT";
    } catch (err) {
      notification.pop("info", i18nProperAmount);
      return;
    }

    // apply the same filtering as seen on the view to make sure the selected index matches what's shown in the view
    var filteredItemGroups = $filter("filter")($scope.itemgroup, $scope.itemNo);
    receipt.addItemGroup(price, filteredItemGroups[selectedItemLineIndex].name, filteredItemGroups[selectedItemLineIndex].no, filteredItemGroups[selectedItemLineIndex].vat, filteredItemGroups[selectedItemLineIndex].taxCodeId, filteredItemGroups[selectedItemLineIndex].imageUrl);
    $state.go("root.split.pos");
  };
  $scope.selectItemLine = function (index) {
    selectedItemLineIndex = index;
  };
  $scope.selectedItemLineIndex = function () {
    return selectedItemLineIndex;
  };
  $scope.sortItems = function (type) {
    $scope.itemgroup = $filter("orderBy")($scope.itemgroup, type);
  };
  $scope.reset = function () {
    $scope.itemNo = "";
    $scope.amount = "";
  };
  $scope.hideKeypad = function () {
    keypadManager.hide(); //Hide Keypad
  };
});
