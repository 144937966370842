"use strict";

angular.module("ddd-auth", ["ng", "ngCookies", "angular-storage"]).provider("tokenService", function () {
  "use strict";

  this.tokenStore = "webposApiToken";
  this.refreshTokenStore = "webposRefreshToken";
  var unauthorizedCallbacks = [];
  this.registerUnauthorizedTokenRequestCallback = function (callback) {
    unauthorizedCallbacks.push(callback);
  };
  this.$get = function (tokenRepository, $q, posStorage, indexdb) {
    var tokenService = {};
    var provider = this;
    tokenService.tokenRequestCallbacks = [];
    tokenService.requestRefreshToken = function (username, password, clientid) {
      var deferred = $q.defer();
      setRequesting(deferred);
      tokenRepository.api.authorize(username, password, clientid).then(function (response, status) {
        tokenService.setRefreshToken(response.data.refresh_token);
        requestCallback(response, status, deferred);
      }, function (error) {
        requestError(error, deferred);
      });
      return deferred.promise;
    };
    tokenService.requestAuthToken = function (refreshToken, clientid) {
      var deferred = $q.defer();
      setRequesting(deferred);
      tokenRepository.api.refreshAuthToken(refreshToken, clientid).then(function (response, status) {
        requestCallback(response, status, deferred);
      }, function (error) {
        requestError(error, deferred);
      });
      return deferred.promise;
    };
    function setRequesting(deferred) {
      tokenService.requestingToken = true;
      deferred.promise["finally"](function () {
        tokenService.requestingToken = false;
      });
    }
    function requestCallback(response, status, deferred) {
      var token = response.data.token || response.data.access_token;
      var refreshToken = response.data.refresh_token;
      console.log("Code:1000 requestCallback token:" + token);
      console.log("Code:1001 requestCallback refreshToken:" + refreshToken);
      if (token) {
        tokenService.setToken(token);
        tokenService.setRefreshToken(refreshToken);
        deferred.resolve(response);
        while (tokenService.tokenRequestCallbacks.length > 0) {
          tokenService.tokenRequestCallbacks.shift()();
        }
      } else deferred.reject("No token available in data");
    }
    function requestError(error, deferred) {
      deferred.reject(error);
      // we dont want to be do the unauth rutine if the API /token is just unavailable
      if (error.status === 401 || error.status === 403 || error.status === 400) doUnauthorizedCallbacks(error);
    }
    function doUnauthorizedCallbacks(error) {
      tokenService.tokenRequestCallbacks = [];
      for (var i = 0; i < unauthorizedCallbacks.length; i++) {
        unauthorizedCallbacks[i](error);
      }
    }
    tokenService.setupToken = function (credentials) {
      var client_id = credentials.client_id || this.getTokenClientId();
      this.setTokenClientId(client_id);
      if (!this.getRefreshToken()) return this.requestRefreshToken(credentials.username, credentials.password, client_id);else return this.requestAuthToken(this.getRefreshToken(), this.getTokenClientId());
    };
    tokenService.refreshToken = function () {
      if (!this.getRefreshToken() || !this.getTokenClientId()) return $q.reject({
        data: {
          error: "No refresh token or client_id available"
        }
      });
      return this.requestAuthToken(this.getRefreshToken(), this.getTokenClientId());
    };
    tokenService.getRequestTokenUrl = function () {
      return tokenRepository.api.tokenRequestUrl;
    };
    tokenService.registerTokenRequestedCallbacks = function (callback) {
      this.tokenRequestCallbacks.push(callback);
    };
    tokenService.registerUnauthorizedTokenRequestCallback = function (callback) {
      provider.registerUnauthorizedTokenRequestCallback(callback);
    };
    tokenService.getUnauthorizedTokenRequestCallbacks = function () {
      return unauthorizedCallbacks;
    };
    tokenService.getToken = function () {
      var temp = posStorage.get(provider.tokenStore);
      return temp;
    };
    tokenService.setToken = function (token) {
      if (!token) return posStorage.remove(provider.tokenStore);
      posStorage.set(provider.tokenStore, token);
    };
    tokenService.getRefreshToken = function () {
      console.log("Code:1003 tokenService.getRefreshToken refreshToken:" + provider.refreshTokenStore);
      return posStorage.get(provider.refreshTokenStore);
    };
    tokenService.setRefreshToken = function (token) {
      if (!token) return posStorage.remove(provider.refreshTokenStore);
      console.log("Code:1002 tokenService.setRefreshToken refreshToken:" + provider.refreshTokenStore + " - Token:" + token);
      posStorage.set(provider.refreshTokenStore, token);
    };
    tokenService.getTokenClientId = function () {
      return posStorage.get("client_id");
    };
    tokenService.setTokenClientId = function (clientid) {
      if ("serviceWorker" in navigator) {
        indexdb.serviceWorkerConstants.set("clientId", clientid);
      }
      return posStorage.set("client_id", clientid);
    };
    return tokenService;
  };
});
