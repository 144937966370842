"use strict";

pos.directive("toggleBackdrop", function ($rootScope, $compile, backdrop, $timeout) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function () {
        var backdropTarget = ".menu-backdrop",
          backdropElement = angular.element(".menu-backdrop"),
          backdropContainer = angular.element("#content__inner");
        if (backdrop.exists(backdropElement)) {
          backdropElement.remove();
        } else {
          $timeout(function () {
            backdropContainer.append($compile('<div class="' + backdropTarget.substring(1) + '" close-navigation="#globalNavigation" navigation-class="menu--open" remove-backdrop></div>')($rootScope));
          }, 0);
        }
      });
    }
  };
}).directive("addBackdrop", function ($rootScope, $compile, backdrop, $timeout) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function () {
        var backdropTarget = ".menu-backdrop",
          backdropContainer = angular.element("#content__inner");
        if (!backdrop.exists(backdropElement)) {
          $timeout(function () {
            backdropContainer.append($compile('<div class="' + backdropTarget.substring(1) + '" close-navigation="#globalNavigation" navigation-class="menu--open" remove-backdrop></div>')($rootScope));
          });
        }
      });
    }
  };
}).directive("removeBackdrop", function (backdrop) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.on("click", function () {
        var backdropElement = angular.element(".menu-backdrop");
        backdropElement.remove();
      });
    }
  };
});
