"use strict";

/**
 * A factory service to handle logic for the Clean Cash integration
 * @return {cleanCashService}
 */
pos.factory("cleanCashService", function (posConfig, backend, $q, cleanCashRepository, $rootScope, notification, $translate) {
  "use strict";

  var cleanCashService = {};
  this.isCleanCashActiveForSale = false;

  //Public methods

  /**
   * Is the clean cash feature enabled in the shop features.
   */
  cleanCashService.isCleanCashEnabled = function () {
    var ccOption = getCleanCashOptions();
    return isCleanCashEnabled(ccOption);
  };

  /**
   * Test if the selected connector can connect to clean cash.
   */
  cleanCashService.canConnectToCleanCash = function () {
    var cvr = posConfig.getCvr();
    var matchedOption = findCleanCashConnectorOption();
    if (!matchedOption) return $q.resolve(false);
    var status = false;
    return cleanCashRepository.api.cleanCashConnection(matchedOption.retailId, posConfig.shopId, cvr).then(function (response) {
      status = response.data;
      return status;
    }, function (errorResponse) {
      status = false;
      return status;
    });
  };

  /**
   * Will get at clean cash code based on the receipt object.
   * @param {receipt} receipt [Receipt object the clean cash code should be generated from]
   * @param {bool} generateNewCode [Should a new code be generated, or old code reused]
   */
  cleanCashService.getCleanCashCode = function (receipt, generateNewCode) {
    var cvr = posConfig.getCvr();
    var matchedOption = findCleanCashConnectorOption();
    var codeType = generateNewCode ? "N" : "C";
    return cleanCashRepository.api.cleanCash(receipt, matchedOption.retailId, codeType, cvr);
  };

  /**
   * Will get at clean cash code based on the receipt object, if clean cash is active for
   * the current sale
   * @param {receipt} receipt Receipt object the clean cash code should be generated from
   * @param {bool} generateNewCode Should a new code be generated, or old code reused
   */
  cleanCashService.getCleanCashCodeIfActive = function (receipt, generateNewCode) {
    if (cleanCashService.isCleanCashActive()) {
      // Remove MongoDb id to avoid type error when sending the receipt to the backend.
      var receiptId = receipt.id;
      receipt.id = 0;
      return cleanCashService.getCleanCashCode(receipt, generateNewCode).then(function (response) {
        receipt.id = receiptId;
        return response.data;
      });
    } else {
      return $q.when("");
    }
  };

  /**
   * Set the status of clean cash for the current sale.
   * @param {bool} status Status of clean cash for the current sale
   */
  cleanCashService.toogleCleanCashStatus = function (status) {
    this.isCleanCashActiveForSale = status;
  };

  /**
   * Check if clean cash is active for the current sale.
   */
  cleanCashService.isCleanCashActive = function () {
    return this.isCleanCashActiveForSale;
  };

  // Private methods

  function getCleanCashOptions() {
    return posConfig.getShopFeatures().cleanCash;
  }
  function isCleanCashEnabled(ccOption) {
    return ccOption !== undefined && ccOption !== null && ccOption.values !== null && ccOption.values.active;
  }
  function findCleanCashConnectorOption() {
    var ccOption = getCleanCashOptions();
    if (!isCleanCashEnabled(ccOption)) return undefined;
    var selectedConnector = backend.getCurrentDevice();
    var matchedOption = ccOption.values.connectorOptions.find(function (connectorOption) {
      return connectorOption.mac === selectedConnector.mac;
    });
    return matchedOption;
  }

  // Event handlers
  $rootScope.$on("clerklogin:logout", function () {
    cleanCashService.toogleCleanCashStatus(false);
  });
  $rootScope.$on("backend:setdefaultdevice", function (e, options) {
    if (!cleanCashService.isCleanCashEnabled()) return;
    cleanCashService.canConnectToCleanCash().then(function (canConnect) {
      if (canConnect) {
        cleanCashService.toogleCleanCashStatus(true);
      } else {
        backend.setDefaultDevice(options.oldDefaultDevice, true);
        notification.pop("error", $translate.instant("connectorchange/cleanCashModalTitle"), $translate.instant("connectorchange/cleanCashModalDescription"), 5000);
      }
    });
  });
  return cleanCashService;
});
