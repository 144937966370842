"use strict";

pos.directive("pricepromotionWidget", function (pricePromotionService, receipt, eventBus) {
  return {
    templateUrl: "views/widgets/widget-pricepromotion",
    link: function link(scope, ele, attr) {
      // Prepare receipt items
      scope.$watch(receipt.getReceipt, function (newVal) {
        scope.items = newVal.items;
        scope.payments = newVal.payments;
        scope.isReceiptEmpty = scope.items.length === 0;
      }, true);
      scope.$watch(function () {
        return pricePromotionService.isRequesting;
      }, function (isRequesting) {
        scope.isRequesting = isRequesting;
      });
      eventBus.subscribe(eventBus.events.pos.promotionBarcodeScanned, scope, pricePromotionService.promotionBarcodeScanned);
    }
  };
});
