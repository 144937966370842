"use strict";

pos.controller("DownPaymentShipmentController", function ($scope, $translate, $state, downpayOrderService) {
  "use strict";

  downpayOrderService.initShipment(true).then(function (shipping) {
    $scope.shipment = shipping;
  });
  var i18nShipmentSaved;
  $translate(["toast/shipment/saved"]).then(function (translations) {
    i18nShipmentSaved = translations["toast/shipment/saved"];
  });
  $scope.updateFee = function () {
    if ($scope.shipment.homeDelivery) {
      $scope.shipment.store = null;
    } else {
      $scope.shipment.address = null;
    }
    downpayOrderService.getShippingFee().then(function (fee) {
      $scope.shipment.fee = fee;
    });
  };
  $scope.selectAddress = function () {
    if ($scope.shipment.homeDelivery) {
      $state.go("root.split.downpayment-shipmentaddress");
    } else {
      $state.go("root.split.downpayment-shipmentstore");
    }
  };
});
