"use strict";

pos.service("quaggaService", function (notification, $timeout, posConfig, $log, eventBus) {
  var service = {};
  var barcodeCallback = function barcodeCallback() {};
  var delayReached = true;
  var beep = new Audio("/assets/audio/beep.wav");
  var quaggaConfig = null;
  var delay = 0;
  loadConfig();
  var onBarcodeDetected = function onBarcodeDetected(result) {
    if (barcodeCallback && delayReached) {
      beep.play();
      barcodeCallback(result.codeResult.code);
      delayReached = false;
      $timeout(function () {
        return delayReached = true;
      }, delay);
    }
  };
  service.start = function (elementId) {
    var onInitFinished = function onInitFinished(error) {
      if (error) {
        onError(error);
      } else {
        Quagga.onDetected(onBarcodeDetected);
        onStart();
      }
    };
    var onError = function onError(error) {
      notification.pop("error", error);
      $log.error(error);
    };
    var onStart = function onStart() {
      Quagga.start();
    };
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.querySelector(elementId)
      },
      decoder: {
        readers: ["ean_reader", "code_39_reader", "i2of5_reader"]
      },
      frequency: 1
    }, onInitFinished);
  };
  service.setCallback = function (cb) {
    barcodeCallback = cb;
  };
  service.stop = function () {
    Quagga.stop();
    Quagga.offDetected();
  };
  function loadConfig() {
    quaggaConfig = posConfig.getShopFeatures().quagga || {
      values: {
        scannerDelay: 1000
      }
    };
    delay = quaggaConfig.values.scannerDelay;
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
  return service;
});
