"use strict";

// require dependencies
global.jQuery = global.$ = require("jquery");
require("jquery-ui-bundle");
require("jquery-ui-touch-punch");
require("angular");
require("angular-ui-router");
require("angular-storage");
require("angular-animate");
require("angular-translate");
require("angular-translate-loader-static-files");
require("angular-cookies");
global.Decimal = require("decimal.js");
require("angular-sanitize");
require("angular-dynamic-locale");
require("angular-strap");
require("angular-iscroll");
require("angular-loading-bar");
require("angular-touch");
require("angularjs-datepicker");
require("chart.js");
require("angular-chart.js");
require("ddd-assets");
require("ng-toast");
require("google-libphonenumber");
global.StackTrace = require("stacktrace-js");
global.moment = require("moment");
global.signalR = require("@aspnet/signalr");
require("@babel/polyfill");
global.Quagga = require("quagga");
global._ = require("lodash");
global.Dexie = require("dexie");
global.LogRocket = require("logrocket");
var bulk = require("bulk-require");

// perequire module definitions before the app
require("./auth/token.service");

// require the rest of the app
bulk(__dirname, "**/*.js");

// Get the value of the "logRocketEnabled" cookie
var logRocketCookieValue = angular.injector(["ngCookies"]).get("$cookies").get("logRocketEnabled");

// Initialize LogRocket
if (!IN_DEBUG_MODE && logRocketCookieValue === "true") {
  console.log("Initializing LogRocket", logRocketCookieValue);
  global.LogRocket.init("LOGROCKET_IDENTIFIER");
}
