"use strict";

pos.factory("infrasecService", function (posConfig, backend, infrasecRepository, notification, $translate, eventBus, receipt, fluctuatingPropertiesHelper, saleRepository) {
  var service = {};
  var settings = null;
  posConfig.whenReady().then(function () {
    init();
    loadConfig();
  });
  function init() {
    eventBus.subscribe(eventBus.events.pos.newConfig, null, function () {
      return loadConfig();
    });
    eventBus.subscribe(eventBus.events.sale.reprint, null, function (ev, receiptData) {
      return onReprint(ev, receiptData);
    });
    eventBus.subscribe(eventBus.events.sale.reprintOffline, null, function (ev) {
      return onAttemptReprintOffline(ev);
    });
  }
  function loadConfig() {
    settings = posConfig.getShopFeatures().infrasec;
  }
  function onReprint(ev, receiptData) {
    if (isActive() && receiptData.reprinted > 0) {
      ev.preventDefault();
    }
    var copyCode = fluctuatingPropertiesHelper.getInfrasecCopyCode(receiptData);
    if (copyCode) {
      ev.preventDefault();
    }
  }
  function onAttemptReprintOffline(ev) {
    if (isActive()) {
      ev.preventDefault();
    }
  }
  function isActive() {
    if (settings && settings.values && settings.values.active) {
      return true;
    }
    return false;
  }
  function getMatchedOption() {
    var selectedConnector = backend.getCurrentDevice();
    var matchedOption = settings.values.connectorOptions.find(function (connectorOption) {
      return connectorOption.mac === selectedConnector.mac;
    });
    if (!matchedOption || !matchedOption.organizationNumber || !matchedOption.registerId) {
      notification.pop("error", $translate.instant("infrasec/error"), $translate.instant("infrasec/missingConfiguration"), 10000);
      return;
    }
    return matchedOption;
  }
  function getInfrasecData(matchedOption, receiptData, copy) {
    if (!isActive()) return;
    return infrasecRepository.api.signReceipt(receiptData, matchedOption.registerId, matchedOption.organizationNumber, matchedOption.storePayload, copy).then(function (response) {
      var infrasecResponse = response.data;
      if (!infrasecResponse) return;
      if (infrasecResponse.responseCode !== 0) {
        notification.pop("error", $translate.instant("infrasec/signingReceiptFialed"), infrasecResponse.responseMessage + " " + infrasecResponse.responseReason, 10000);
      }
      return infrasecResponse;
    });
  }
  service.isActive = function () {
    return isActive();
  };
  service.signReceipt = function (receiptData) {
    if (!isActive()) return;
    var matchedOption = getMatchedOption();
    if (!matchedOption) return;
    return getInfrasecData(matchedOption, receiptData, false).then(function (response) {
      if (response && response.responseCode === 0) {
        receipt.setInfrasecDetails(response.controlCode.ctuid, response.controlCode.code, matchedOption.registerId, response.requestID, matchedOption.organizationNumber);
        return true;
      }
    });
  };
  service.signReceiptCopy = function (receiptData) {
    if (!isActive()) return;
    var matchedOption = getMatchedOption();
    if (!matchedOption) return;
    return getInfrasecData(matchedOption, receiptData, true).then(function (response) {
      if (response && response.responseCode === 0) {
        fluctuatingPropertiesHelper.setInfrasecCopyCTUID(receiptData, response.controlCode.ctuid);
        fluctuatingPropertiesHelper.setInfrasecCopyCode(receiptData, response.controlCode.code);
        return saleRepository.api.updateFluctuatingProperties(receiptData).then(function (response) {
          return receiptData;
        });
      }
    });
  };
  return service;
});
