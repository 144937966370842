"use strict";

pos.controller("ConnectorXReportCtrl", function ($scope, receipt, backend, clerkLoginService, notification, $translate) {
  "use strict";

  $scope.disabled = false;
  var noDailyClosure = "";
  var unexpectedErrorTitle = "";
  $translate(["xreport/noDailyClosure", "toast/unexpectedErrorTitle"]).then(function (translations) {
    noDailyClosure = translations["xreport/noDailyClosure"];
  });
  $scope.print = function () {
    $scope.disabled = true;
    var localTime = moment(new Date()).format();
    backend.devices[backend.defaultDevice].post("/POSServiceHandler.aspx/PrintXReport", {
      localTime: localTime
    }).then(function () {
      return $scope.disabled = false;
    }, function (err) {
      $scope.disabled = false;
      if (err.status === 417) {
        notification.pop("warning", noDailyClosure);
      } else {
        notification.pop("error", unexpectedErrorTitle);
      }
    });
  };
});
