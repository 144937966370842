"use strict";

pos.factory("offlineChecker", function ($q, $log, notification, $timeout, $rootScope) {
  var offlineChecker = {
    responseError: function responseError(response) {
      response.config = response.config || {};
      // if the error is due to 401 Unauthorized or 403 Forbidden let auth interceptor handle it or it's not requested as a trackable device
      if (response.status === 401 || response.status === 403 || !response.config.device) {
        return $q.reject(response);
      }
      var device = response.config.device;
      if (response.status !== 404 && response.status !== -1) {
        if (!response.config.ignoreError && response.status === 500 && device.isOnline) {
          notification.pop("error", "Whoops", "It appears a request to " + response.config.url + " failed with status " + response.status);
        }
        return $q.reject(response);
      }

      // If the API is returning a status other than -1 it is not offline
      if (device.isApi && response.status !== -1) {
        return $q.reject(response);
      }
      if (device.isOnline) $rootScope.$broadcast("device:offline", device);else $rootScope.$broadcast("device:retry:failed", device);

      // Begin reconnect procedure for device
      if (response.config.reconnect || device.isOnline) {
        var random = Math.floor(Math.random() * 1000000000);
        var url = device.isApi ? "isonline?_" + random : "/POSServiceHandler.aspx/WakeUp";
        $timeout(function () {
          return device({
            method: device.isApi ? "GET" : "POST",
            url: url,
            data: {},
            reconnect: true,
            ignoreLoadingBar: true,
            dontTrack: true
          }, true);
        }, 10000);
      }
      device.isOnline = false;
      return $q.reject(response);
    },
    response: function response(_response) {
      if (!_response) return _response;
      _response.config = _response.config || {};
      var device = _response.config.device;
      if (device !== undefined) {
        if (!device.isOnline) $rootScope.$broadcast("device:retry:success", device);
        device.isOnline = true;
      }
      return _response;
    },
    request: function request(config) {
      if (config.device !== undefined && !config.device.isOnline) $rootScope.$broadcast("device:retry:start", config.device);
      return config;
    }
  };
  return offlineChecker;
});
pos.config(function ($httpProvider) {
  $httpProvider.interceptors.push("offlineChecker");
});
