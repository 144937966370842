"use strict";

pos.directive("trimitWidget", function (trimitService, receipt, $translate, notification, keypadManager) {
  return {
    templateUrl: "views/widgets/widget-trimit",
    link: function link(scope, ele, attr) {
      scope.trimit = {
        customer: null,
        customerFields: null,
        searchString: ""
      };
      scope.loading = {
        text: "",
        trimit: false,
        memberToggle: false
      };
      var i18nCustomerNotFound;
      var i18nFailedToChangeMembership;
      var i18nFailedToGetDiscounts;
      var i18nFetchingCustomer;
      var i18nCheckingDiscounts;
      var i18nMissingNumber;
      $translate(["widget/trimit/customernotfound", "widget/trimit/failedtochangestatus", "widget/trimit/failedtogetdiscounts", "widget/trimit/fetchingcustomer", "widget/trimit/checkingdiscounts", "widget/trimit/missingNumber"]).then(function (translations) {
        i18nCustomerNotFound = translations["widget/trimit/customernotfound"];
        i18nFailedToChangeMembership = translations["widget/trimit/failedtochangestatus"];
        i18nFailedToGetDiscounts = translations["widget/trimit/failedtogetdiscounts"];
        i18nFetchingCustomer = translations["widget/trimit/fetchingcustomer"];
        i18nCheckingDiscounts = translations["widget/trimit/checkingdiscounts"];
        i18nMissingNumber = translations["widget/trimit/missingNumber"];
      });
      trimitService.loadCustomer().then(function (response) {
        scope.trimit.customer = response;
        scope.trimit.customerFields = trimitService.customerFields;
      });
      scope.$watch(function () {
        return trimitService.getDiscountsOngoing;
      }, function (requestOngoing) {
        scope.loading.text = i18nCheckingDiscounts;
        scope.loading.trimit = requestOngoing;
      });
      scope.$watch(function () {
        return trimitService.customer;
      }, function (customer) {
        if (customer && !scope.trimit.customer && scope.isReceiptOpen()) {
          scope.trimit.customer = customer;
          scope.trimit.customerFields = trimitService.customerFields;
          scope.getTrimitDiscounts();
        }
      });
      scope.toggleTrimitMember = function () {
        scope.loading.memberToggle = true;
        trimitService.toggleMember(scope.trimit.customer.number, scope.trimit.customer.member)["catch"](function () {
          return notification.pop("error", i18nFailedToChangeMembership);
        })["finally"](function () {
          return scope.loading.memberToggle = false;
        });
      };
      scope.displayInSidebar = function (attribute) {
        var customerAttrib = scope.trimit.customerFields.find(function (attrib) {
          return attrib.fieldName === attribute;
        });
        return customerAttrib && customerAttrib.visibleLeftPanel;
      };
      scope.clearTrimitCustomer = function () {
        scope.trimit.customer = null;
        trimitService.clearCustomer();
      };
      scope.getTrimitCustomer = function (searchString) {
        if (!searchString) {
          notification.pop("info", i18nMissingNumber);
          return;
        }

        // Indicate loading
        scope.loading.text = i18nFetchingCustomer;
        scope.loading.trimit = true;
        trimitService.getCustomer(searchString).then(function () {
          scope.trimit.searchString = "";
          scope.loading.trimit = false;
          scope.getTrimitDiscounts();
        })["catch"](function () {
          notification.pop("info", i18nCustomerNotFound);
          scope.loading.trimit = false;
        });
      };
      scope.getTrimitDiscounts = function () {
        keypadManager.hide();
        if (!receipt.isEmpty() && scope.trimit.customer && !scope.loading.trimit) {
          scope.loading.trimit = true;
          trimitService.getDiscounts(scope.trimit.customer.number)["catch"](function () {
            return notification.pop("error", i18nFailedToGetDiscounts);
          })["finally"](function () {
            return scope.loading.trimit = false;
          });
        }
      };
      scope.isReceiptOpen = function () {
        return window.location.hash == "#/pos";
      };
    }
  };
});
