"use strict";

pos.factory("loyaltyStaticDiscountService", function ($rootScope, $timeout, receipt) {
  var service = {};
  var currentMember = null;
  var receiptItemCountWatcher = null;
  service.attachCustomer = function (member) {
    unwatchReceiptItemCount();
    cancelAllStaticDiscountsOnReceipt();

    // Only if the member has really changed, but NOT if 'attach' is called due to a Refresh/F5.
    if (memberHasChanged(member)) enableStaticDiscountAttachmentOnAllReceiptItems();
    if (!service.isStaticDiscountEngineMember(member)) return;
    currentMember = member; // Cache member
    setStaticDiscountOnReceiptItems(member);
    watchReceiptItemCount();
  };
  service.detachCustomer = function () {
    enableStaticDiscountAttachmentOnAllReceiptItems();
    cancelAllStaticDiscountsOnReceipt();
    unwatchReceiptItemCount();
    currentMember = null;
  };
  service.isStaticDiscountEngineMember = function (member) {
    return member && member.engineTypeIdentifier === 2;
  };
  function memberHasChanged(member) {
    return currentMember && currentMember.customerNumber != member.customerNumber;
  }
  function watchReceiptItemCount() {
    var receiptBon = receipt.getReceipt();
    receiptItemCountWatcher = $rootScope.$watch(function () {
      return receiptBon.items.length;
    }, function (newItemCount, oldItemCount) {
      if (newItemCount > oldItemCount) setStaticDiscountOnReceiptItems(currentMember);
    });
  }
  function unwatchReceiptItemCount() {
    if (receiptItemCountWatcher) receiptItemCountWatcher();
    receiptItemCountWatcher = null;
  }
  function setStaticDiscountOnReceiptItems(member) {
    var discount = member.staticDiscount.discountPercentage;
    var discountReason = {
      reason: member.staticDiscount.discountName
    };
    receipt.cancelAllDiscount(); // Only the Loyalty discount shall be applied and only ONCE!
    receipt.getReceipt().items.forEach(function (item, index) {
      if (item.transactionType !== "Return" && !item.discountRemovedByUser) {
        receipt.setLineDiscount(discount, discountReason, index);
        attachDiscountRemovalWatcher(item);
      }
    });
  }
  function cancelAllStaticDiscountsOnReceipt() {
    if (!service.isStaticDiscountEngineMember(currentMember)) return;

    // Only remove static Loyalty discounts (not any other manually given discount).
    var staticDiscountName = currentMember.staticDiscount.discountName;
    receipt.getReceipt().items.forEach(function (item, index) {
      if (item.discountReasonName === staticDiscountName) {
        unwatchDiscountRemoval(item);
        receipt.cancelLineDiscount(null, index);
      }
    });
  }
  function enableStaticDiscountAttachmentOnAllReceiptItems() {
    receipt.getReceipt().items.forEach(function (item) {
      delete item.discountRemovedByUser;
    });
  }

  /**
   * Creates a watcher that sets a flag, to indicate that the user has explicitly removed a static discount.
   * We use this flag to decide if we must auto-add a static discount when refreshing the page (F5).
   */
  function attachDiscountRemovalWatcher(receiptItem) {
    if (receiptItem.discountRemovalWatcher) return;
    receiptItem.discountRemovalWatcher = $rootScope.$watch(function () {
      return receiptItem.calculatedDiscount;
    }, function (calculatedDiscount) {
      if (!calculatedDiscount.isZero()) return;
      unwatchDiscountRemoval(receiptItem);
      markDiscountAsRemovedByUser(receiptItem);
    });
  }
  function markDiscountAsRemovedByUser(receiptItem) {
    receiptItem.discountRemovedByUser = true;
    receipt.persist(); // To get "discountRemovedByUser" after refreshing again.
  }
  function unwatchDiscountRemoval(receiptItem) {
    if (!receiptItem.discountRemovalWatcher) return;
    receiptItem.discountRemovalWatcher();
    delete receiptItem.discountRemovalWatcher;
  }
  return service;
});
