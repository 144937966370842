"use strict";

pos.value("webOrderStatusEnum", Object.freeze({
  reserved: 2,
  picked: 4,
  delivered: 6,
  returned: 8,
  returnedPayment: 10,
  skipped: 20
}));
pos.value("webOrderTypeEnum", Object.freeze({
  clickAndCollect: 0,
  orderInStore: 1
}));
