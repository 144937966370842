"use strict";

pos.factory("inputValidationService", function ($locale) {
  var service = {};
  var maxAmountAllowed = 9999999.99;
  var maxQuantityAllowed = 99999;
  service.decimalPlaces = 2;
  service.validateNewPhoneNumber = function (oldPhone, newPhone) {
    if (!newPhone) return true;
    if (newPhone === oldPhone) return true;
    var phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
    try {
      var phoneNumber = phoneUtil.parseAndKeepRawInput(newPhone, null);
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (e) {
      return false;
    }
  };
  service.convertToDecimal = function val(amount, unrounded) {
    // we know that toDecimalPlacesUnrounded() only exists on `Decimal`. This is a temporary workaround
    // till we have removed the constructor overload of Decimal in app.js. When that is removed we can
    // change this to use `if (amount instaceof Decimal) return amount;` for proper type checking
    if (typeof amount.toDecimalPlacesUnrounded === "function") return amount;
    // safe convertion to string
    amount = "" + amount;
    amount = amount.replace(/\s+/g, "");

    // Disallowing thousand separators because they only add complications and no value, who writes thousand separators
    // Allowing both dor or comma to be used as a decimal separator, so input is language agnostic
    // Only allowing two numeric inputs after decimal separator because we only work with two decimals
    if (!amount.match(/^[0-9]*(\.|,)?[0-9]{0,2}$/g)) throw "INVALID_FORMAT";
    if (amount.includes(",")) amount = amount.replace(",", ".");
    var value = new Decimal(amount);
    if (unrounded) return value;
    return service.roundToAllowedDecimalPlaces(value);
  };
  service.roundToAllowedDecimalPlaces = function (value) {
    return value.toDecimalPlacesUnrounded(service.decimalPlaces + 1).toDecimalPlaces(service.decimalPlaces);
  };
  service.validateAmount = function (amount) {
    return isWithinRange(amount, maxAmountAllowed);
  };
  service.validateQuantity = function (quantity) {
    return isWithinRange(quantity, maxQuantityAllowed);
  };

  /**
   * Converts machine culture to regional format.
   * Machine culture uses period as decimal separator and has no group separator
   */
  service.convertValueToRegionalFormat = function (machineCultureValue) {
    if (isNaN(Number(machineCultureValue))) throw "INVALID_ARGUMENT";
    var decimal = $locale.NUMBER_FORMATS.DECIMAL_SEP;
    return machineCultureValue.replace(".", decimal);
  };

  /**
   * Converts reginal format to machine culture
   */
  service.covertValueToMachineCulture = function (value) {
    var newVal = ("" + value).replace($locale.NUMBER_FORMATS.GROUP_SEP, "");
    newVal = ("" + newVal).replace($locale.NUMBER_FORMATS.DECIMAL_SEP, ".");
    return newVal;
  };
  function isWithinRange(val, range) {
    var decimalVal = service.convertToDecimal(val);
    return decimalVal.abs().lte(range);
  }
  return service;
});
