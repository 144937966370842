"use strict";

pos.directive("mpstockWidget", function ($rootScope, masterItemStockService, storeService) {
  return {
    templateUrl: "views/widgets/widget-mpstock",
    controller: function controller($scope) {},
    link: function link(scope, ele, attr) {
      scope.title = "";
      scope.getStock = function (vert, horz) {
        if (scope.response) {
          var stockItems = scope.response.stock.filter(function (o) {
            return o.vert == vert && o.horz == horz;
          });
          return stockItems ? stockItems.reduce(function (acc, obj) {
            if (!obj.active) {
              return;
            } else {
              if (!acc) return obj.quantity;else {
                return acc + obj.quantity;
              }
            }
          }, 0) : 0;
        }
      };

      //Stores
      storeService.getStores().then(function (response) {
        scope.stores = response;
        scope.selectedStore = scope.stores.length > 0 ? scope.stores[0].id : 0;
        generateStoreArray($rootScope.selectedItem.parameter3, masterItemStockService);
      });

      //Stock
      var generateStoreArray = function generateStoreArray(itemNo, masterItemStockService) {
        masterItemStockService.getByMasterItemStock(itemNo, scope.selectedStore).then(function (response) {
          scope.response = response;
          var title = response.verticalDimensions != null ? response.verticalDimensions.name : "";
          if (response.horizontalDimensions != null) {
            title = title.length > 0 ? title + " / " + response.horizontalDimensions.name : response.horizontalDimensions.name;
          }
          if (!title) title = $rootScope.selectedItem.parameter4;
          scope.title = title;
        });
      };
      scope.storeChanged = function () {
        generateStoreArray($rootScope.selectedItem.parameter3, masterItemStockService);
      };
    }
  };
});
