"use strict";

pos.directive("logoWidget", function () {
  return {
    templateUrl: "views/widgets/widget-logo",
    controller: function controller($scope, posStorage) {
      $scope.shop = posStorage.get("current_shop");
    }
  };
});
