"use strict";

pos.factory("fullscreenMode", function ($document) {
  var fullscreenMode = {};

  /**
   * Toggle fullscreen mode
   */
  fullscreenMode.toggle = function () {
    fullscreenMode.isEnabled() ? fullscreenMode.disable() : fullscreenMode.enable();
  };
  /**
   * Check if fullscreen mode is supported
   */
  fullscreenMode.isSupported = function () {
    var fullscreenSupportedDevices = $document[0].exitFullscreen || $document[0].msExitFullscreen || $document[0].mozCancelFullScreen || $document[0].webkitExitFullscreen;
    return fullscreenSupportedDevices ? true : false;
  };

  /**
   * Check if fullscreen mode is enabled
   */
  fullscreenMode.isEnabled = function () {
    var fullscreenElement = $document[0].webkitIsFullScreen || $document[0].msFullscreenElement || $document[0].mozFullScreen || $document[0].fullscreenElement;
    return fullscreenElement ? true : false;
  };

  /**
   * Enable fullscreen mode
   */
  fullscreenMode.enable = function () {
    if ($document[0].documentElement.requestFullscreen) {
      $document[0].documentElement.requestFullscreen();
    } else if ($document[0].documentElement.msRequestFullscreen) {
      $document[0].documentElement.msRequestFullscreen();
    } else if ($document[0].documentElement.mozRequestFullScreen) {
      $document[0].documentElement.mozRequestFullScreen();
    } else if ($document[0].documentElement.webkitRequestFullscreen) {
      $document[0].documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  };

  /**
   * Disable fullscreen mode
   */
  fullscreenMode.disable = function () {
    if ($document[0].exitFullscreen) {
      $document[0].exitFullscreen();
    } else if ($document[0].msExitFullscreen) {
      $document[0].msExitFullscreen();
    } else if ($document[0].mozCancelFullScreen) {
      $document[0].mozCancelFullScreen();
    } else if ($document[0].webkitExitFullscreen) {
      $document[0].webkitExitFullscreen();
    }
  };
  return fullscreenMode;
});
