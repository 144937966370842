"use strict";

pos.factory("discountReasons", function (posConfig, $timeout, receipt, eventBus) {
  "use strict";

  var service = {};
  loadConfig();
  service.overlay = false;
  service.receiptDiscountOverlay = false;
  service.discountToApply = 0;
  service.lineDefaultFunction = false;
  service.discountMethod = false;
  service.discountDetails = false;
  service.discountReasonsSelector = function (discountMethod, discountToApply, defaultFunction, discountDetails) {
    service.discountMethod = discountMethod;
    service.discountToApply = discountToApply;
    service.lineDefaultFunction = defaultFunction;
    service.discountDetails = discountDetails;
    turnOnOverlay(discountMethod);
  };
  service.applyDiscountReason = function (discountReason) {
    $timeout(function () {
      service.discountMethod(service.discountToApply, discountReason, service.discountDetails);
      service.lineDefaultFunction();
      service.overlay = false;
      service.receiptDiscountOverlay = false;
    });
  };
  service.getFilteredDiscountReasons = function (lineDisciount, percentageDiscount) {
    if (lineDisciount) {
      if (percentageDiscount) return service.items.filter(function (x) {
        return x.discountTypeSelection !== 2;
      });
      return service.items.filter(function (x) {
        return x.discountTypeSelection !== 1;
      });
    }
    if (percentageDiscount) return service.receiptReasonItems.filter(function (x) {
      return x.discountTypeSelection !== 2;
    });
    return service.receiptReasonItems.filter(function (x) {
      return x.discountTypeSelection !== 1;
    });
  };

  // Since dealing with two unsynced backends, it checks if posConfig has discountReasons for receipts
  // and uses them if available. If not, it falls back on using the older single array of discountReasons
  function turnOnOverlay(discountMethod) {
    if (service.receiptReasonItems.length && discountMethod == receipt.setDiscountOnAll) {
      service.receiptDiscountOverlay = true;
    } else {
      service.overlay = true;
    }
  }
  function loadConfig() {
    service.items = posConfig.getDiscountReasons();
    service.receiptReasonItems = posConfig.getReceiptDiscountReasons();
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
  return service;
});
