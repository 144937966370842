"use strict";

pos.controller("ReconcilePayCtrl", function ($scope, cashStatements, posConfig, eventBus) {
  "use strict";

  $scope.asideButtonsTemplate = "views/payment/paymentchoicebuttons";
  $scope.setPaymentChoice = function (choice) {
    var newSelectedPaymentType = $scope.paymentTypes.find(function (o) {
      return o.type == choice;
    });
    if (newSelectedPaymentType) {
      $scope.selectedPaymentType = newSelectedPaymentType.id;
      cashStatements.currentlyReconcilingPaymentType = newSelectedPaymentType;
    }
  };
  function loadConfig() {
    var extraPayments;
    if ($scope.paymentTypes) {
      extraPayments = $scope.paymentTypes.filter(function (x) {
        return x.addPayment;
      });
    }
    $scope.paymentTypes = posConfig.getPaymentTypeConfigs().filter(function (o) {
      return o.shouldReconcile;
    });

    // Some payment types are not added via posConfig
    if (extraPayments) {
      angular.forEach(extraPayments, function (element) {
        if (!$scope.paymentTypes.find(function (x) {
          return x.type == element.type;
        })) element.addPayment($scope.paymentTypes);
      });
    }
    if (!$scope.selectedPaymentType) {
      $scope.setPaymentChoice($scope.paymentTypes[0].type);
    }
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, $scope, loadConfig);
  loadConfig();
});
