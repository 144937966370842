"use strict";

pos.directive("mpeditcustomerWidget", function ($location, $translate, findCustomerService, notification, $log, posConfig) {
  return {
    templateUrl: "views/widgets/widget-mpeditcustomer",
    controller: function controller($scope) {
      $scope.customer = findCustomerService.getSelectedCustomer() || {};
      $scope.currencyCodeError = false;
      $scope.formSubmitted = false;
      $scope.dropdownExpanded = false;
      $scope.userCanChangeCurrencyCode = !$scope.customer.currencyCode;
      $scope.userCanChangeExternalUniqueId = $scope.customer.externalUniqueId === undefined;
      $scope.currencyCodes = Object.values(posConfig.getCurrencySetup()).map(function (x) {
        return x.name;
      });
      var minDate = new Date(1900, 1, 0); //starts with 0 eg month january = 0
      var m = moment(minDate);
      $scope.minDate = m.format("YYYY-MM-DD");
      $scope.maxDate = moment().format("YYYY-MM-DD");
      $scope.editMode = Object.keys($scope.customer).length > 0;
      $scope.emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    },
    link: function link(scope, el, attr, controller) {
      var i18nCheckLog;
      $translate(["toast/check log"]).then(function () {
        i18nCheckLog = $translate.instant("toast/check log");
      });
      scope.saveCustomer = saveCustomer;
      scope.toggleDropdown = function () {
        if (scope.userCanChangeCurrencyCode) {
          scope.dropdownExpanded = !scope.dropdownExpanded;
        }
      };
      scope.setCurrencyCode = function (currencyCode) {
        scope.dropdownExpanded = false;
        scope.customer.currencyCode = currencyCode;
      };
      function saveCustomer(formState) {
        scope.formSubmitted = true;
        if (!scope.customer.currencyCode) {
          scope.currencyCodeError = true;
          return;
        }
        if (!formState) return;
        scope.customer.isActive = true;
        if (findCustomerService.canUpdate(scope.customer)) findCustomerService.addUpdateCustomer(scope.customer).then(saveSuccess, saveFailed);
      }
      function saveSuccess(response) {
        if (response) {
          findCustomerService.setSelectedCustomer(response);
          $location.path("widget/mpcustomer");
        }
      }
      function saveFailed(errorResponse) {
        $log.debug("save customer error");
        $log.debug(errorResponse);
        notification.pop("error", i18nCheckLog);
      }
    }
  };
});
