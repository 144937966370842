"use strict";

pos.factory("addressService", function (addressRepository, notification, $translate) {
  var service = {};
  var i18nAddressInserted, i18nErrorAddressInsert;
  $translate(["toast/address_insert", "toast/address_not_inserted"]).then(function (translations) {
    i18nAddressInserted = translations["toast/address_insert"];
    i18nErrorAddressInsert = translations["toast/address_not_inserted"];
  });
  service.addAddress = function (address) {
    return addressRepository.api.add(address).then(function (response) {
      notification.pop("info", i18nAddressInserted);
      return response;
    })["catch"](function (response) {
      notification.pop("error", i18nErrorAddressInsert);
    });
  };
  return service;
});
