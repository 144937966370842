"use strict";

pos.directive("weborderModal", ["eventBus", function (eventBus) {
  var controller = function controller($scope, notification, $translate) {
    $scope.action = function (btn) {
      if (btn.closes) {
        btn.action($scope.content.orgWeborder);
        $scope.content.isVisible = false;
      }
    };
    $scope.defaultAction = function () {};
  };
  return {
    templateUrl: "views/posModal/weborderModal-directive",
    controller: controller,
    scope: {
      content: "="
    }
  };
}]);
