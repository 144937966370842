"use strict";

pos.controller("WidgetsCtrl", function ($scope, posConfig, Widgets, eventBus, backend) {
  loadConfig();
  if (!$scope.widgets || $scope.widgets.length === 0) {
    $scope.widgets = [{
      name: "teaser"
    }];
  }
  $scope.widgetsService = Widgets;
  $scope.activeWidget = 0;
  $scope.apiOnline = backend.api.isOnline;
  $scope.hideWidgets = function () {
    $scope.widgetsService.showWidgets = false;
    $("#center-content.center-content--downscale").removeClass("center-content--downscale");
  };

  // Set the active widget (on mobile)
  $scope.setActiveWidget = function (index) {
    $scope.activeWidget = index;
  };
  $scope.isActiveWidget = function (index) {
    return "w" + $scope.activeWidget === "w" + index;
  };
  $scope.$on("device:offline", function (event, device) {
    if (device.isApi) $scope.apiOnline = false;
  });
  $scope.$on("device:retry:success", function (event, device) {
    if (device.isApi) {
      $scope.apiOnline = true;
    }
  });
  $scope.mobileMode = function () {
    // The element with the "widget-tray__body--mobile" class
    // is only visible if the POS is in mobile-mode (width < 992)
    var elementArray = document.getElementsByClassName("widget-tray__body--mobile");
    return elementArray.length > 0;
  };
  function loadConfig() {
    $scope.widgets = posConfig.getWidgets();

    // If the logo widget is there, it should always be the first shown
    var logoWidgetIdx = $scope.widgets.findIndex(function (widget) {
      return widget.name == "logo";
    });
    if (logoWidgetIdx >= 0) {
      var logoWidget = $scope.widgets.splice(logoWidgetIdx, 1)[0];
      $scope.widgets.unshift(logoWidget);
    }
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, $scope, loadConfig);
});
