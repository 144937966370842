"use strict";

pos.controller("SellAsSetCtrl", function ($scope, $state, articles, notification, receipt, posConfig, $translate, keypadManager, setService, $timeout, inputValidationService, selectDimensionComboService) {
  var barcodePlaceholder, pricePlaceholder, i18nUnknownEan, i18nGrtThanTotal, i18nMoreThanOne, i18nSetPrice, i18nProperAmount;
  $scope.placeHolder = barcodePlaceholder;
  $scope.inputValue = "";
  var onehundred = new Decimal(100),
    selectedSetLine;
  loadConfig();
  $translate(["input/enter_barcode", "input/enter_amount", "toast/need ean", "toast/unknown ean", "toast/greater than combine total", "toast/morethanone", "toast/setPrice", "toast/proper amount"]).then(function (translations) {
    $scope.barcodePlaceholder = translations["input/enter_barcode"];
    $scope.pricePlaceholder = translations["input/enter_amount"];
    i18nUnknownEan = translations["toast/unknown ean"];
    i18nGrtThanTotal = translations["toast/greater than combine total"];
    i18nMoreThanOne = translations["toast/morethanone"];
    i18nSetPrice = translations["toast/setPrice"];
    i18nProperAmount = translations["toast/proper amount"];
  });
  $scope.$watch(function () {
    return setService.getSet().length;
  }, function () {
    if ($scope.instance) {
      $timeout(function () {
        $scope.instance.refresh();
        $scope.instance.scrollTo(0, $scope.instance.maxScrollY);
      });
    }
  });
  $scope.evaluateSet = function () {
    if (!$scope.price) return keypadManager.show(angular.element("#sellAsSetInput"), function () {
      $scope.setPrice($scope.price);
    }, true);
    $scope.addToReceipt();
  };
  $scope.resetField = function () {
    $scope.inputValue = "";
    setService.clearSet();
  };
  $scope.handleInput = function enterEAN(value) {
    $scope.displayItems(value);
    $scope.inputValue = "";
  };
  $scope.displayItems = function (value) {
    if (!value || !/^\d+$/.test(value))
      // check that there's no alphanumeric characters in the barcode
      return;else {
      articles.findArticle(value).then(function (response) {
        if (response.status == 226) {
          if (response.data.items.length > 1) {
            selectDimensionComboService.setMasterItemData(response.data);
            selectDimensionComboService.setCallBack(function (item) {
              setService.addSetItem(item);
              $state.go("root.split.sellasset");
            });
            $state.go("root.split.selectdimensioncombo");
          } else if (response.data.items.length == 1) {
            $scope.displayItems(response.data.items[0].barcode);
          }
        } else {
          setService.addSetItem(response.data);
          keypadManager.hide(); //Hide Keypad
        }
      }, function (errorResponse) {
        notification.pop("warning", i18nUnknownEan);
      });
    }
  };
  $scope.showLineDiscountPct = function (article) {
    return onehundred.minus(article.effectiveSalesPrice.times(onehundred).dividedBy(article.salesPrice));
  };
  $scope.isEffectiveLessThanSalesPrice = function (article) {
    if (article.effectiveSalesPrice.lessThan(article.salesPrice)) return true;
  };
  $scope.selectSetLine = function (index) {
    if (selectedSetLine === index) {
      //Work as Accordion
      selectedSetLine = -1;
    } else selectedSetLine = index;
  };
  $scope.selectedSetLine = function () {
    return selectedSetLine;
  };
  $scope.deleteSetItem = function (itemIndex) {
    setService.removeSetItem(itemIndex);
  };
  $scope.setPrice = function setSetPrice(price) {
    try {
      price = inputValidationService.convertToDecimal(price);
    } catch (err) {
      return notification.pop("info", i18nProperAmount);
    }
    if (price.isNaN() || price.isZero() || !inputValidationService.validateAmount(price)) {
      return notification.pop("info", i18nProperAmount);
    }
    keypadManager.hide();
    $scope.price = "";
    setService.setSetPrice(price);
    $scope.addToReceipt();
  };
  $scope.addToReceipt = function () {
    if (setService.getSetTotal() === setService.getSetPrice()) {
      return notification.pop("info", i18nSetPrice);
    }
    var added = setService.addSetToReceipt();
    if (!added) return notification.pop("info", i18nMoreThanOne);
    $scope.backToReceipt();
  };
  $scope.$watch(setService.getSet, function (set) {
    $scope.set = set;
  });
  $scope.getSetTotal = setService.getSetTotal;
  $scope.getSetPrice = setService.getSetPrice;
  $scope.backToReceipt = function () {
    $scope.resetField();
    $state.go("root.split.pos");
  };
  function loadConfig() {
    $scope.noteConfiguration = posConfig.noteConfiguration;
  }
});
