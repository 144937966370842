"use strict";

pos.directive("mpproductimageWidget", function () {
  return {
    templateUrl: "views/widgets/widget-mpproduct-image",
    controller: function controller($scope, $rootScope) {
      $scope.currentItem = null;
      $scope.$watch(function () {
        return $rootScope.selectedItem;
      }, function (item) {
        return onSelectedItemChange(item);
      });
      function onSelectedItemChange(item) {
        $scope.currentItem = item;
      }
    }
  };
});
