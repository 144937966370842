"use strict";

// Eager load the "loyaltyService" "loyalty" widget is in the widget list.
// This also loads all its dependencies as the "coreLoyaltyService" and therefore ensures
// that ALL THE watchers, POS manipulations...  get triggered, even in situations in which
// the Widget itself is not shown and loaded. Such a situation occurs for example, if the
// user refreshes (F5) on the "Payment View" in which the Widget is not shown, but the
// logic/watchers of the Loyalty services still needs to run.
pos.run(function (posConfig, $injector, $log, eventBus) {
  // we need to await that posConfig is ready, then we know the latest config is loaded
  loadConfig();
  function loadConfig() {
    posConfig.whenReady().then(function () {
      var loyatyWidgetEnabled = _.some(posConfig.getWidgets(), function (widget) {
        return widget.name === "loyalty";
      });
      if (loyatyWidgetEnabled) {
        $log.debug("eager loading  DdD Loyalty service");
        return $injector.get("loyaltyService");
      }
    });
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
});
pos.factory("loyaltyService", function ($rootScope, $q, $translate, loyaltyRepository, coreLoyaltyService, loyaltyStaticDiscountService, receipt, notification) {
  var service = {};
  var currentMember = null;
  var i18nMemberNotFound, i18nMemberLookupError, i18nMixedSalesWarning;
  translate();
  function translate() {
    $translate(["loyalty/service/toast/memberNotFound", "loyalty/service/toast/memberLookupError", "loyalty/service/toast/mixedSalesWarning"]).then(function (translations) {
      i18nMemberNotFound = translations["loyalty/service/toast/memberNotFound"];
      i18nMemberLookupError = translations["loyalty/service/toast/memberLookupError"];
      i18nMixedSalesWarning = translations["loyalty/service/toast/mixedSalesWarning"];
    });
  }

  // Detaches the customer from the receipt and cleans the current member cache.
  // This is needed after a sale has been completed/cancelled and a new sale gets started.
  $rootScope.$watch(function () {
    return receipt.getLoyaltyCustomerNumber();
  }, function (customerNumber) {
    if (!customerNumber) {
      service.detachCustomer();
    }
  });

  // This handles the change event of "States". For ex. when changing to "Payment State"
  $rootScope.$on("$stateChangeStart", function (event, toState) {
    preventMixedSalesOnLoyalty(event, toState);
  });

  // This handles event, when "Translations" have been loaded.
  // Needed when the language has changed on runtime (due to Clerk language change for ex).
  $rootScope.$on("$translateChangeSuccess", function () {
    translate(); // Update the translations cached in this service.
  });

  /**
   * Get the current currentMember. It will return the object cached in the currentMember variable
   * if it's available. If the variable is undefined (i.e. when the app is refreshed) we will get the customerNumber
   * from the receipt and try and find the customer from the number and then return that. Resolving the customer
   * from the customerNumber will also trigger the customer be be "reattached" (i.e. starting the payment watcher).
   * @return {promise} Promise which resolves the currentMember object
   */
  service.getLoyaltyCustomer = function () {
    var attachMemberToReceipt = true;
    var customerNumber = receipt.getLoyaltyCustomerNumber();
    if (!customerNumber) return $q.reject();
    if (currentMember && currentMember.customerNumber === customerNumber) return $q.resolve(currentMember);
    return service.lookupCustomer(customerNumber, attachMemberToReceipt).then(function (member) {
      return member;
    });
  };
  service.lookupCustomer = function (customerNumber, attachCustomerToReceipt) {
    return loyaltyRepository.api.lookupCustomer(customerNumber).then(function (response) {
      var member = response.data;
      if (attachCustomerToReceipt) attachCustomer(member);
      return member;
    }, function (errorResponse) {
      if (errorResponse.status === 400) notification.pop("warning", i18nMemberNotFound);else showErrorAndTrack("MemberLookupError", i18nMemberLookupError, errorResponse.data);
      return $q.reject(errorResponse);
    });
  };
  service.detachCustomer = function () {
    currentMember = null;
    coreLoyaltyService.detachCustomer();
    loyaltyStaticDiscountService.detachCustomer();
  };
  service.isStaticDiscountEngineMember = function (member) {
    return member && member.engineTypeIdentifier === 2;
  };
  function attachCustomer(member) {
    currentMember = member; // Cache member
    coreLoyaltyService.attachCustomer(member);
    loyaltyStaticDiscountService.attachCustomer(member);
  }
  function isMixedSale() {
    var receiptItems = receipt.getReceipt().items;
    var hasSaleItems = _.some(receiptItems, function (item) {
      return item.quantity >= 0;
    });
    var hasReturnItems = _.some(receiptItems, function (item) {
      return item.quantity < 0;
    });
    return hasSaleItems && hasReturnItems;
  }
  function preventMixedSalesOnLoyalty(event, toState) {
    var customerNumber = receipt.getLoyaltyCustomerNumber();
    if (customerNumber && toState.name == "root.split.payment" && isMixedSale()) {
      event.preventDefault(); // Prevent changing to "Payment area" state
      notification.pop("warning", i18nMixedSalesWarning);
    }
  }
  function showErrorAndTrack(internalTitle, publicTitle, errorMessage) {
    notification.pop("error", publicTitle);
  }

  // Calling "getLoyaltyCustomer" here on the load of the "loyaltyService" ensures that:
  // 1. The customer is get (if his 'CustomerNumber' is already on the receipt)
  // 2. Attached using the "coreLoyaltyService"
  // 3. Watcher for payment types gets initialized
  // The watcher is needed to have the Loyalty payment types in place, even if the user refreshes on the "Payment View".
  // Additionally see the "eager loading" of the "loyaltyService" on "pos.run()".
  service.getLoyaltyCustomer();
  return service;
});
