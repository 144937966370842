"use strict";

pos.controller("DownPaymentShipmentStoreController", function ($scope, $state, downpayOrderService) {
  "use strict";

  $scope.stores = [];
  downpayOrderService.getStores().then(function (data) {
    return $scope.stores = data;
  });
  var selectedIndex = -1;
  $scope.selectStore = function (index, store) {
    if (selectedIndex === index) {
      selectedIndex = -1;
      return;
    }
    selectedIndex = index;
    downpayOrderService.setShippingStore(store);
    downpayOrderService.saveShipping();
    $state.go("root.split.pos");
  };
});
