"use strict";

pos.directive("revideWidget", function (revideService, receipt, $translate, eventBus) {
  return {
    templateUrl: "views/widgets/widget-revide",
    link: function link(scope, ele, attr) {
      scope.revideCustomer = revideService.getSelectedCustomer();
      scope.revideCustomerNumber = "";
      scope.revideCustomerSelected = false;
      var i18nCustomerNumberEmpty;
      $translate(["toast/customer_number_empty"]).then(function (translations) {
        i18nCustomerNumberEmpty = translations["toast/customer_number_empty"];
      });
      eventBus.subscribe(eventBus.events.customer.attach, scope, function (ev, customer) {
        scope.revideCustomer = customer;
        revideService.setSelectedCustomer(customer);
        scope.revideCustomerSelected = true;
      });
      scope.deselectCustomer = function () {
        revideService.removeSelectedCustomer();
        scope.customerSelected = false;
        scope.revideCustomer = null;
        receipt.detachCustomer();
      };
    }
  };
});
