"use strict";

pos.directive("notificationMessage", function (notificationHelper) {
  return {
    restrict: "E",
    templateUrl: "views/partials/notification-message",
    scope: {
      message: "="
    },
    link: function link(scope, ele, attrs) {
      scope.getMessageBody = function () {
        return notificationHelper.getMessageBody(scope.message);
      };
      scope.getMessageTitle = function () {
        return notificationHelper.getMessageTitle(scope.message);
      };
      scope.getMessageTimestamp = function () {
        return notificationHelper.getMessageTimestamp(scope.message);
      };
    }
  };
});
