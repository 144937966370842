"use strict";

pos.factory("defaultVoucherPaymentType", function (receipt, sales, $q, giftCertificate, $log, voucherService) {
  var service = {};

  /**
   * Initialise a payment as a default payment type. The default payment type sends it's request to a Connector
   * And expects more or less the same values back
   * @param  {string} paymentType            The type of payment, e.g. 'Cash', 'ManualCard', etc.
   * @param  {Decimal|number} keyboardInput  The value passed in by the keyboard, typically a voucher number can also be an amount if it's a credit note
   * @param  {object} currency               A curreny object, usually fetch from posConfig.getCurrencySetup(name) or posConfig.getLocalCurrency()
   * @return {promise}                       $q promise which is rejected with a status or resolved with the makePayment response
   */
  service.makePayment = function makeVoucherPayment(paymentType, keyboardInput, currency) {
    var payments = receipt.getReceipt().payments;
    if (receipt.getTotal().minus(receipt.getPaymentsTotal()).isNegative()) {
      var amount = new Decimal(keyboardInput); // if it's a negative then it's an amount to pay out as creditnote or giftcertificate
      var expirationDate = getExpirationDateFromOldVoucher(payments);
      return payRemainderWithVoucher(paymentType, amount, currency, expirationDate);
    }
    var voucherNo = keyboardInput;
    if (voucherService.barcodeIsDefinedAsVoucher(voucherNo)) {
      return voucherService.createVoucherPayment(voucherNo);
    }

    // verify that the voucher is available and fetch the amount available
    return giftCertificate.verifyGiftCertificate(voucherNo).then(function (response) {
      var voucher = response.data;
      var flucProp = {
        expirationDate: voucher.expirationDate
      };
      if (giftCertificate.isExpired(voucher)) {
        // notification.pop("warning", "expired");
        return $q.reject("VOUCHER_EXPIRED");
      }

      // the voucher wont be redeemed until the sale is complete
      // so we need to make sure that we don't try to use the same voucher twice
      // since a new call to verify would give the impression that it's still available
      // as we haven't completed the sale yet
      for (var i = 0; i < payments.length; i++) {
        if (payments[i].voucherNumber) {
          if (payments[i].voucherNumber === voucher.voucherNumber || payments[i].voucherNumber === voucher.alternativeVoucherNumber) return $q.reject("VOUCHER_USED_ON_RECEIPT");
        }
      }
      // choice is either GiftCertificate or CreditVoucher
      return sales.makePayment(paymentType, voucher.amount, currency, voucherNo, flucProp);
    }, function (errorResponse) {
      switch (errorResponse.status) {
        case 404:
          return $q.reject("VOUCHER_NOT_FOUND");
        case 409:
          return $q.reject("VOUCHER_CONFLICT");
        // most likely a 500
        default:
          return $q.reject("VOUCHER_ERROR");
      }
    });
  };
  service.validatePaymentChoice = function (choice, remainder, currency) {
    return true;
  };

  /**
   * Pay out change as a credit note
   * @param  {Decimal} amount  Amount to pay out as credit note
   * @param  {object} currency A curreny object, usually fetch from posConfig.getCurrencySetup(name) or posConfig.getLocalCurrency()
   * @return {promise}         $q promise with the request response
   */
  function payRemainderWithVoucher(paymentType, amount, currency, expirationDate) {
    // Attempt to partially redeem the used voucher of the same type, otherwise create a new one
    var voucherNumber = findLastUsedVoucherNo(paymentType);
    if (!voucherNumber) {
      if (paymentType == "GiftCertificate") voucherNumber = giftCertificate.generateGiftCertificate();else if (paymentType == "CreditVoucher") voucherNumber = giftCertificate.generateCreditVoucher();
    }
    if (amount.greaterThan(receipt.getTotal().minus(receipt.getPaymentsTotal()).abs())) return $q.reject("INVALID_CREDITNOTE_AMOUNT");
    var flucProp = null;
    if (expirationDate) {
      flucProp = {
        expirationDate: expirationDate
      };
    }
    return sales.makePayment(paymentType, amount.neg(), currency, voucherNumber, flucProp);
  }
  function findLastUsedVoucherNo(paymentType) {
    var voucherPayments = receipt.getReceipt().payments.filter(function (p) {
      return p.paymentType === paymentType;
    });
    if (!voucherPayments || voucherPayments.length === 0) return null;
    return voucherPayments.reduce(function (p, cur) {
      return cur;
    }).voucherNumber;
  }
  service.isPaymentEnough = function isPaymentEnough(paymentState) {
    $log.debug("defaultvoucher: in isPaymentEnough");
    paymentState.itemsTotalAbs = new Decimal(receipt.getTotal().abs().toFixed(2));
    paymentState.paymentsTotalAbs = new Decimal(receipt.getPaymentsTotal().abs().toFixed(2));
    $log.debug("defaultvoucher: abs itemsTotal: " + paymentState.itemsTotalAbs.toString());
    $log.debug("defaultvoucher: abs paymentTotal: " + paymentState.paymentsTotalAbs.toString());
    paymentState.isPaymentEnough = paymentState.paymentsTotalAbs.eq(paymentState.itemsTotalAbs);
    return $q.resolve(paymentState);
  };
  function getExpirationDateFromOldVoucher(payments) {
    var expirationDate = payments.filter(function (payment) {
      return payment.paymentType == "CreditVoucher" || payment.paymentType == "GiftCertificate";
    }).map(function (payment) {
      return payment.fluctuatingProperties.expirationDate;
    }).sort(function (a, b) {
      return new Date(b) - new Date(a);
    })[0];
    return expirationDate;
  }
  return service;
});
