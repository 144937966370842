"use strict";

pos.factory("cleanCashRepository", function (backend) {
  var cleanCash = {};
  cleanCash.api = {
    cleanCash: function cleanCash(receipt, retailId, receiptType, cvr) {
      return backend.api.put("api/cleancash", {
        receipt: receipt,
        retailId: retailId,
        receiptType: receiptType,
        cvr: cvr
      });
    },
    cleanCashConnection: function cleanCashConnection(retailId, shopId, cvr) {
      return backend.api.get("api/cleancash/checkstatus/" + shopId + "/" + retailId + "/" + cvr);
    }
  };
  return cleanCash;
});
