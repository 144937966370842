"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
pos.controller("SelectDimensionComboCtrl", function ($scope, $state, receipt, articles, selectDimensionComboService) {
  "use strict";

  $scope.masterItem = selectDimensionComboService.getMasterItemData();

  // Fill with dimension names
  $scope.dimensions = $scope.masterItem.items.map(function (o) {
    return o.attributes;
  }).reduce(function (pre, cur) {
    return [].concat(_toConsumableArray(pre), _toConsumableArray(cur));
  }, []).map(function (o) {
    return o.dimensionName;
  }).reduce(function (acc, cur) {
    if (!(cur in acc)) acc[cur] = [];
    return acc;
  }, {});

  // Count no of dimensions so CSS grid can be setup correctly
  $scope.noOfDimensions = Object.keys($scope.dimensions).length;

  // Fill with dimension values
  Object.keys($scope.dimensions).forEach(function (dimension) {
    return $scope.masterItem.items.map(function (o) {
      return o.attributes;
    }).reduce(function (pre, cur) {
      return [].concat(_toConsumableArray(pre), _toConsumableArray(cur));
    }, []).filter(function (o) {
      return o.dimensionName == dimension;
    }).sort(function (o) {
      return o.valueSequence;
    }).map(function (o) {
      return o.value;
    }).forEach(function (dimensionValue) {
      if (!$scope.dimensions[dimension].includes(dimensionValue)) {
        $scope.dimensions[dimension].push(dimensionValue);
      }
    });
  });

  // Create a dictionary template of the dimensions
  var dictTemplate = Object.keys($scope.dimensions).reduce(function (acc, cur) {
    acc[cur] = null;
    return acc;
  }, {});
  $scope.selections = _objectSpread({}, dictTemplate);
  $scope.offsets = _objectSpread({}, dictTemplate);
  Object.keys($scope.offsets).forEach(function (key) {
    return $scope.offsets[key] = 0;
  });
  $scope.select = function (dimension, value) {
    if ($scope.isDisabled(dimension, value)) return;
    var selection = $scope.selections[dimension];
    if (selection == value) $scope.selections[dimension] = null;else $scope.selections[dimension] = value;
  };
  $scope.isSelected = function (dimension, value) {
    return $scope.selections[dimension] == value;
  };
  $scope.isReadyToProcceed = function () {
    return Object.values($scope.selections).reduce(function (pre, cur) {
      return pre && !!cur;
    }, true);
  };

  // Calculate maximum number of values in each column based on resolution
  var calculateMaxOnPage = function calculateMaxOnPage() {
    return $scope.maxOnPage = Math.floor(window.innerHeight / 160);
  };
  calculateMaxOnPage();
  $scope.$watch(function () {
    return window.innerHeight;
  }, calculateMaxOnPage);
  $scope.pageUp = function (dimension) {
    if ($scope.offsets[dimension] < $scope.dimensions[dimension].length - $scope.maxOnPage) $scope.offsets[dimension]++;else $scope.offsets[dimension] = 0;
  };
  $scope.pageDown = function (dimension) {
    if ($scope.offsets[dimension] > 0) $scope.offsets[dimension]--;else $scope.offsets[dimension] = $scope.dimensions[dimension].length - $scope.maxOnPage;
  };
  $scope.isDisabled = function (dimension, value) {
    var currentValidItems = $scope.masterItem.items.filter(function (item) {
      return Object.keys($scope.selections).filter(function (key) {
        return !!$scope.selections[key];
      }).reduce(function (pre, cur) {
        return pre && item.attributes.find(function (o) {
          return o.dimensionName == cur && o.value == $scope.selections[cur];
        });
      }, true);
    });
    return !currentValidItems.find(function (item) {
      return item.attributes.find(function (o) {
        return o.dimensionName == dimension && o.value == value;
      });
    });
  };
  $scope.ok = function () {
    // Find item with all matching dimension selections
    var item = $scope.masterItem.items.find(function (item) {
      return Object.keys($scope.selections).reduce(function (pre, cur) {
        return pre && item.attributes.find(function (o) {
          return o.dimensionName == cur && o.value == $scope.selections[cur];
        });
      }, true);
    });

    // Query for full article information and add to receipt
    articles.findArticle(item.barcode).then(function (articleResponse) {
      return selectDimensionComboService.callCallBack(articleResponse.data);
    });
    selectDimensionComboService.clearMasterItemData();
  };
});
