"use strict";

pos.factory("payPlazaCard", function (receipt, sales, $q, $rootScope, signalr, $injector, notification, $translate, inputValidationService, defaultPaymentType, $log) {
  var service = {};
  var i18nPayPlazaErrorTitle;
  var i18nPayPlazaErrorMessage;
  $translate(["toast/payplaza/unexpectedTitle", "toast/payplaza/unexpectedMessage"]).then(function (translations) {
    i18nPayPlazaErrorTitle = translations["toast/payplaza/unexpectedTitle"];
    i18nPayPlazaErrorMessage = translations["toast/payplaza/unexpectedMessage"];
  });
  signalr.posProxy.payPlazaHub.on("setDisplayText", function (paymentGuid, displayText) {
    var payment = getPayment(paymentGuid);
    if (payment !== undefined) {
      payment.displayText = displayText;
    }
  });
  signalr.posProxy.payPlazaHub.on("wakeUpTerminal", function (paymentGuid, terminalIPAddress, terminalPortNumber) {
    // Wakes up an ordinary payment terminal. Has no effect on the BluePad.
    try {
      new WebSocket("ws://" + terminalIPAddress + ":" + terminalPortNumber);
    } catch (error) {
      // PayPlaza uses the websocket like this and ignores error, the terminal only needs a "kick"
    }
  });
  function getPayment(uuid) {
    // look backwards for the payment
    var payments = receipt.getReceipt().payments;
    for (var i = payments.length - 1; i >= 0; i--) {
      var payment = payments[i];
      if (payment.uuid === uuid) {
        return payment;
      }
    }
  }

  /**
   * Initialise a payment as a default payment type. The default payment type sends it's request to a Connector
   * And expects more or less the same values back
   * @param  {string} paymentType         The type of payment, e.g. 'Cash', 'ManualCard', etc.
   * @param  {Decimal|number} amount      The amount passed in by the keyboard
   * @param  {object} currency            A curreny object, usually fetch from posConfig.getCurrencySetup(name) or posConfig.getLocalCurrency()
   * @param  {string} contextInfo         Any additional information this payment type may carry
   * @return {promise}                    $q promise which is rejected with a status or resolved with the makePayment response
   */
  service.makePayment = function (paymentType, amount, currency, contextInfo) {
    var payplazaService = $injector.get("payplazaService");
    if (!payplazaService.getEftId()) {
      $rootScope.$emit("payment:payplazaEftMissing");
      return $q.reject("EFT_CONFIG_MISSING");
    }
    try {
      amount = inputValidationService.convertToDecimal(amount);
    } catch (err) {
      return $q.reject(err);
    }
    var paymentStatus = receipt.getTotal().minus(receipt.getPaymentsTotal());
    // we require a correct format for the amount and if the receipt is a return (negative total)
    // then we do not allow the clerk to try and pay back more than the total amount, because the sale
    // has to sum to 0 when all is said and done
    if (defaultPaymentType.isAmountInvalid(amount)) {
      return $q.reject("INVALID_AMOUNT");
    }

    // we allow 0 payments if there's items on the receipt (e.g. an exchanged item is a return + a sold at same price)
    // we also allow payments without any items, if the clerk want to withdraw some money
    // we only require that there either must a value in the payment OR items on the reciept
    if (amount.isZero() && receipt.getReceipt().items.length === 0) {
      return $q.reject("INVALID_TYPE_AMOUNT");
    }
    if (receipt.getTotal().minus(receipt.getPaymentsTotal()).isNegative() && !amount.isNegative()) amount = amount.abs().neg(); // make sure that it's positive before we negate it

    if (amount.abs().gt(paymentStatus.abs())) {
      $rootScope.$emit("payment:payPlazaPaymentTooLarge", amount);
      return $q.reject("PAYMENT_AMOUNT_EXCEEDS_REMAINING_AMOUNT");
    }

    // we can't send in 'Card' right now, because the ARM will mistake it for a Nets terminal.
    // Can be changed to 'Card' when armgateway knows to check the terminal type before starting
    // anything
    return sales.makePayment("PayPlaza", amount, currency, contextInfo).then(function (response) {
      response.data.d.isPayPlaza = true;
      response.data.d.paymentType = "Card";
      response.data.d.completed = false;
      response.data.d.cardNumber = 600;
      return response;
    });
  };

  /**
   * A long poll request which triggers SignalR events that will do different required actions
   * e.g. ping the EFT and update displayText
   * @param  {object} paymentState The payment state
   * @return {promise}             $q promise
   */
  service.handlePayplazaTransaction = function handlePayplazaTransaction(paymentState) {
    $log.debug("payPlazaCard: in handlePayplazaTransaction");
    var payment = paymentState.response.data.d;
    var receiptId = receipt.getReceipt().receiptId;
    receipt.persist();
    payment.awaitingResponse = true;
    if (payment.retry) {
      var payplazaService = $injector.get("payplazaService");
      return payplazaService.getLastReceipt().then(function (response) {
        if (response.orderRef == receiptId && response.transactionSucceeded === "true") {
          $log.debug("payPlazaCard: previous transaction was successful");
          payment.completed = true;
          payment.awaitingResponse = false;
          payment.displayText = "SUCCESS";
          payment.cardName = response.appPreferredName;
          payment.cardReceipt = JSON.stringify(response.formattedReceipt);
          paymentState.payPlazaResponse = response;
          receipt.persist();
          payplazaService.notifyClerkIfSignatureRequired(response.data);
          return paymentState;
        }
        return sendPayPlazaTransaction(paymentState);
      }, function (errorResponse) {
        $log.debug(errorResponse);
      });
    }
    return sendPayPlazaTransaction(paymentState);
  };
  var sendPayPlazaTransaction = function sendPayPlazaTransaction(paymentState) {
    $log.debug("payPlazaCard: in sendPayPlazaTransaction");
    var payment = paymentState.response.data.d;
    var receiptId = receipt.getReceipt().receiptId;
    payment.awaitingResponse = true;
    var payplazaService = $injector.get("payplazaService");
    // Payplaza expects the decimals to be multiplied into the amount, hence *100
    return payplazaService.handlePayplazaTransaction(payment.uuid, receiptId, payment.currencyName, new Decimal(payment.amount).times(100)).then(function (response) {
      try {
        if (response.data.result !== "SUCCESS") return handleError(response);
        var payPlazaReceipt = angular.fromJson(response.data.receipt);
        payment.completed = true;
        payment.awaitingResponse = false;
        payment.displayText = response.data.result; // "SUCCESS"
        payment.cardName = payPlazaReceipt.printCommandMessage.appPreferredName;
        payment.cardReceipt = JSON.stringify(response.data.formattedReceipt);
        payplazaService.acknowledgePayment();
        // TODO: do we need to do anything else?
      } catch (err) {
        notification.pop("warning", "Something unexpected happend with PayPlaza", "You may need to manually reprint the terminal receipt for this transaction.");
      }
      receipt.persist();
      paymentState.payPlazaResponse = response;
      payplazaService.notifyClerkIfSignatureRequired(response.data);
      return paymentState;
    }, handleError);
    function handleError(errorResponse) {
      if (errorResponse.data) {
        // Updating terminal message on the pos
        if (errorResponse.data.result) {
          payment.displayText = errorResponse.data.result;
        }
        // Sending the terminal receipt for the failed transaction to the ARM for printing
        if (errorResponse.data.formattedReceipt && errorResponse.data.formattedReceipt.length > 0) {
          payplazaService.notifyClerkIfSignatureRequired(errorResponse.data);
          payplazaService.printPayPlazaTerminalReceipt(JSON.stringify(errorResponse.data.formattedReceipt));
        }
      }
      paymentState.payPlazaResponse = errorResponse;
      payment.awaitingResponse = false;
      receipt.persist();
      payplazaService.acknowledgePayment();
      return $q.reject(paymentState);
    }
  };
  service.cardPaymentRejected = function cardPaymentRejected(paymentState) {
    // just pass the error along if it didn't originate from the payplaza calls
    if (!paymentState.payPlazaResponse) return $q.reject(paymentState);
    $log.debug("payPlazaCard: in cardPayment rejected");
    var rejection = paymentState.payPlazaResponse;
    paymentState.cardRejected = true;
    return $q.reject(paymentState);
  };
  return service;
});
