"use strict";

pos.controller("RightMenuPayCtrl", function ($scope, $rootScope, toggleNavigation, posConfig, eventBus, backend, receipt, notification, $translate) {
  "use strict";

  var i18nExpensePaymentTypeNotAllowedBody = "",
    i18nExpensePaymentTypeNotAllowedTitle = "";
  $translate(["toast/expense-payment-not-allowed-title", "toast/expense-payment-not-allowed-body"]).then(function (translations) {
    i18nExpensePaymentTypeNotAllowedTitle = translations["toast/expense-payment-not-allowed-title"];
    i18nExpensePaymentTypeNotAllowedBody = translations["toast/expense-payment-not-allowed-body"];
  });
  $scope.asideButtonsTemplate = "views/payment/paymentchoicebuttons";
  loadConfig();
  $scope.setPaymentChoice = function (choice, currency, navigationElement) {
    navigationElement = navigationElement || false;
    if (receipt.getReceipt().transactionType == "Expense" && choice !== "Cash") {
      notification.pop("warning", i18nExpensePaymentTypeNotAllowedTitle, i18nExpensePaymentTypeNotAllowedBody);
      return;
    }
    if (choice !== "Currency" || currency) {
      if (navigationElement) {
        toggleNavigation.close(navigationElement, true);
      }
      if (typeof amountSuggestion === "function") amountSuggestion = amountSuggestion();
      return $rootScope.$broadcast("paymentChoice", choice, currency);
    }
    $scope.currency = !$scope.currency;
  };
  function loadConfig() {
    var extraPayments;
    if ($scope.paymentTypes) {
      extraPayments = $scope.paymentTypes.filter(function (x) {
        return x.addPayment;
      });
    }
    $scope.paymentTypes = posConfig.getPaymentTypeConfigs();

    // Remove card as a paymenttype if no terminal is configured on the Connector
    var currentDevice = backend.getCurrentDevice();
    if (!currentDevice.terminalType) {
      var idx = $scope.paymentTypes.findIndex(function (o) {
        return o.type == "Card";
      });
      if (idx > -1) {
        $scope.paymentTypes.splice(idx, 1);
      }
    }
    // Remove account as paymentType if no customer is attached to the receipt
    var currentCustomer = receipt.getCustomer();
    if (!currentCustomer || !currentCustomer.customerNumber) {
      var accountPaymentIndex = $scope.paymentTypes.findIndex(function (x) {
        return x.type === "Account";
      });
      if (accountPaymentIndex > -1) {
        $scope.paymentTypes.splice(accountPaymentIndex, 1);
      }
    }

    // Some payment types are not added via posConfig
    if (extraPayments) {
      angular.forEach(extraPayments, function (element) {
        if (!$scope.paymentTypes.find(function (x) {
          return x.type == element.type;
        })) element.addPayment($scope.paymentTypes);
      });
    }
    $scope.currencies = angular.copy(posConfig.getCurrencySetup());
    delete $scope.currencies[posConfig.getLocalCurrency().name];
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, $scope, loadConfig);
});
