"use strict";

pos.controller("JournalsCtrl", function ($scope, terminalActionService, notification) {
  $scope.fromPlaceholder = "From";
  $scope.toPlaceholder = "To";
  $scope.fromDate = new moment().format("D/MMM/YYYY");
  $scope.toDate = new moment().format("D/MMM/YYYY");
  $scope.maxDateInput = new Date().toDateString("dd/MMM/yyyy");
  $scope.printTerminalJournal = function () {
    if (!validDates()) return;
    var fromTerminal = moment($scope.fromDate).format("DD/MM/YYYY");
    var toTerminal = moment($scope.toDate).format("DD/MM/YYYY");
    terminalActionService.printTerminalJournal(fromTerminal, toTerminal).then(ok);
  };
  $scope.printReceiptJournal = function () {
    if (!validDates()) return;
    var from = moment($scope.fromDate).format("DD/MM/YYYY");
    var to = moment($scope.toDate).format("DD/MM/YYYY");
    terminalActionService.printReceiptJournal(from, to).then(ok);
  };
  function validDates() {
    if ($scope.toDate > $scope.maxDateInput) {
      $scope.toDate = $scope.maxDateInput;
    }
    if ($scope.fromDate > $scope.maxDateInput) {
      $scope.fromDate = $scope.maxDateInput;
    }
    if ($scope.fromDate && $scope.toDate) return true;
    notification.pop("error", "Incorrect date range", "Please select valid date ranges");
    return false;
  }
  function ok() {
    notification.pop("success", "Done!", "");
  }
});
