"use strict";

pos.directive("dddCashstatement", function ($compile, $http, $templateCache, $translate, $locale, notification, cashStatements) {
  // returns a promise which, when resolved, will return the partial's HTML
  var getTemplate = function getTemplate(contentType) {
    var templateLoader,
      baseUrl = "views/partials/",
      templateMap = {
        local: "cash-local",
        foreign: "cash-local",
        custom: "cash-local"
      };
    var templateUrl = baseUrl + templateMap[contentType];
    templateLoader = $http.get(templateUrl, {
      cache: $templateCache
    });
    return templateLoader;
  };
  var linker = function linker(scope, element, attrs) {
    var i18nRoundingHappened, i18nIllegalGroupSep;
    getTemplate(scope.content.type).then(function (response) {
      element.html($compile(response.data)(scope));
    });
    $translate(["label/entercash", "label/enterbank", "label/enterevening", "text/cashStatementRoundedValues", "label/cashUnit", "label/unitQuantity", "label/unitAmount"]).then(function (translations) {
      scope.enterCashPlaceholder = translations["label/entercash"];
      scope.enterBankPlaceholder = translations["label/enterbank"];
      scope.enterEveningPlaceholder = translations["label/enterevening"];
      scope.cashUnit = translations["label/cashUnit"];
      scope.unitAmount = translations["label/unitAmount"];
      scope.unitQuantity = translations["label/unitQuantity"];
      i18nRoundingHappened = translations["text/cashStatementRoundedValues"];
    });
    $translate("toast/illegalgroupseparator", {
      separator: $locale.NUMBER_FORMATS.DECIMAL_SEP
    }).then(function (translation) {
      i18nIllegalGroupSep = translation;
    });
    function replaceChar(value) {
      value = String(value).replace(".", $locale.NUMBER_FORMATS.DECIMAL_SEP);
      return value;
    }
    scope.setAmount = function (unit) {
      cashStatements.setAmountFromCount(unit);
      unit.count = replaceChar(unit.count);
      unit.amount = replaceChar(unit.amount);
      scope.updateCurrencyTotal();
    };
    scope.setCount = function (unit) {
      unit.amunt = removeGroupSeparator(unit.amount);
      var oldAmount = cashStatements.covertValueToMachineCulture(unit.amount);
      cashStatements.setCountFromAmount(unit);
      if (unit.amount.toNumber() < Number(oldAmount)) notification.pop("warning", i18nRoundingHappened);
      unit.count = replaceChar(unit.count);
      unit.amount = replaceChar(unit.amount);
      scope.updateCurrencyTotal();
    };
    scope.setBank = function () {
      scope.content.eveningAmount = removeGroupSeparator(scope.content.eveningAmount);
      var eveAmt = !scope.content.eveningAmount ? new Decimal(0) : new Decimal(scope.content.eveningAmount);
      var cashTotal = new Decimal(scope.content.statementTotal || 0);
      scope.content.bank = replaceChar(cashTotal.minus(eveAmt).toSD(15).toNumber());
    };
    scope.setEveningAmount = function () {
      scope.content.bank = removeGroupSeparator(scope.content.bank);
      var bankAmt = !scope.content.bank ? new Decimal(0) : new Decimal(scope.content.bank);
      var cashTotal = new Decimal(scope.content.statementTotal || 0);
      scope.content.eveningAmount = replaceChar(cashTotal.minus(bankAmt).toSD(15).toNumber());
    };
    scope.removeGroupSeparatorAndClearUnits = function () {
      scope.content.statementTotal = removeGroupSeparator(scope.content.statementTotal);
      scope.clearUnits();
    };
    function removeGroupSeparator(amount) {
      if (("" + amount).indexOf($locale.NUMBER_FORMATS.GROUP_SEP) < 0) return amount;
      // remove the GROUP_SEP (e.g. comma or dot depending on region)
      var newAmount = amount.replace(new RegExp("[" + $locale.NUMBER_FORMATS.GROUP_SEP + "]", "gi"), "");
      notification.pop("info", i18nIllegalGroupSep);
      return newAmount;
    }
    scope.updateCurrencyTotal = function () {
      var amount = new Decimal(0);
      angular.forEach(scope.content.unitCounts, function (unit) {
        if (unit.amount) amount = amount.plus(new Decimal(unit.amount));
      });
      scope.content.statementTotal = replaceChar(amount.toSD(15).toNumber());
    };
    scope.clearUnits = function () {
      angular.forEach(scope.content.unitCounts, function (unit) {
        unit.count = "0";
        unit.amount = "0";
      });
    };
    scope.reset = function () {
      angular.forEach(scope.content.unitCounts, function (unit) {
        unit.count = 0;
        unit.amount = 0;
      });
    };
    scope.getUnitDisplay = function (unit) {
      return unit.unit < 1 ? unit.unit.toFixed(2) : unit.unit.toFixed(0);
    };
    scope.getDenominationDisplay = function (unit, column) {
      var displayValue = scope.cashUnit + " " + scope.getUnitDisplay(unit).toString() + " ";
      if (column == "pieces") {
        return displayValue + scope.unitQuantity;
      }
      if (column == "amount") {
        return displayValue + scope.unitAmount;
      }
    };
    scope.$watch(function () {
      return scope.content.statementTotal;
    }, function (amount) {
      scope.content.eveningAmount = new Decimal(cashStatements.covertValueToMachineCulture(amount || 0)).minus(cashStatements.covertValueToMachineCulture(scope.content.bank || 0)).toSD(15).toNumber();
      scope.content.eveningAmount = replaceChar(scope.content.eveningAmount);
    });
    scope.selectNext = function (id) {
      angular.element("#" + id).focus();
    };
  };
  return {
    restrict: "E",
    scope: {
      content: "=",
      isselected: "=",
      currencyIndex: "=",
      iscrollInstance: "=",
      paymenttype: "="
    },
    link: linker
  };
});
