"use strict";

pos.controller("CardTerminalActionCtrl", function ($scope, backend, terminalActionService, receipt, sales, $log, cardTransactionStatus) {
  var partialsPath = "views/cardterminalactions/";
  $scope.isCompleted = false;
  $scope.$watch(function () {
    return backend.defaultDevice;
  }, function (index) {
    var armDevice = backend.devices[index];
    $scope.defaultArm = armDevice ? armDevice : {
      deviceName: "Select K3 Connector"
    };
  });
  function initiateAction(serviceMethod, success, error) {
    $scope.isCompleted = false;
    $scope.actionTemplate = partialsPath + "basic-feedback";
    function actionEventHandler(event) {
      $scope.action = event.name;
      $scope.awaitingResponse = true;
      terminalActionService[serviceMethod]().then(function (response) {
        var sessionId = response.data.d;
        var device = response.config.device;
        return getTerminalStatus(device.id, sessionId);
      }).then(success, error)["finally"](function () {
        $scope.awaitingResponse = false;
      });
    }
    return actionEventHandler;
  }
  function getTerminalStatus(deviceId, sessionId) {
    return terminalActionService.getTerminalStatus(deviceId, sessionId).then(function (response) {
      var status = response.data.d;
      if (!status) {
        $scope.actionFeedback = "Invalid response";
        return;
      }
      $scope.actionFeedback = status.displayText || $scope.actionFeedback;
      if (status.bonText) {
        return sales.printTerminalReceipt(status.bonText).then(function () {
          return getTerminalStatus(deviceId, sessionId);
        });
      }
      if (status.result >= 0) return response;
      return getTerminalStatus(deviceId, sessionId);
    });
  }
  function cancelTransaction(event) {
    $scope.actionTemplate = partialsPath + "basic-feedback";
    $scope.action = "cancelTransaction";
    $scope.awaitingResponse = true;
    $scope.isCompleted = false;
    var canceledReceipt;
    terminalActionService.terminalCancellationRequest().then(function (response) {
      var sessionId = response.data.d.sessionId;
      canceledReceipt = response.data.d.receipt;
      var device = response.config.device;
      return getTerminalStatus(device.id, sessionId);
    }, showError).then(function (response) {
      receipt.prepareReceipt();
      var newReceipt = receipt.getReceipt();
      newReceipt.items = canceledReceipt.items;
      newReceipt.payments = canceledReceipt.payments;
      receipt.loadReceipt(newReceipt);
      receipt.negateReceipt();
      return sales.saveSale().then(function (saveResponse) {
        sales.completeSaleOnDevice(response.config.device.id);
        setCompleted();
      })["finally"](receipt.prepareReceipt);
    })["finally"](function () {
      $scope.awaitingResponse = false;
    });
  }
  function showError(response) {
    $log.debug(response);
    $scope.actionFeedback = response.data.message;
  }
  function setCompleted() {
    $scope.isCompleted = true;
  }
  $scope.shouldDisableInput = function () {
    return $scope.awaitingResponse;
  };
  $scope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams, options) {
    if ($scope.shouldDisableInput()) event.preventDefault();
  });
  $scope.$on("terminal:reconciliation", initiateAction("terminalEndOfDay", setCompleted, showError));
  $scope.$on("terminal:emptybuffer", initiateAction("terminalEmptyPrintBuffer", setCompleted, showError));
  $scope.$on("terminal:canceltransaction", cancelTransaction);
  $scope.$on("terminal:xreport", initiateAction("terminalXReport", setCompleted, showError));
  $scope.$on("terminal:zreport", initiateAction("terminalZReport", setCompleted, showError));
  $scope.$on("terminal:sendofflinetrans", initiateAction("terminalSendOfflineTransactions", setCompleted, showError));
  $scope.$on("terminal:print:storedeottrans", initiateAction("terminalPrintStoresTransactions", setCompleted, showError));
  $scope.$on("terminal:download:app", initiateAction("terminalDownloadApplication", setCompleted, showError));
  $scope.$on("terminal:download:cardagreements", initiateAction("terminalDownloadCardAgreements", setCompleted, showError));
  $scope.$on("terminal:print:lastreceipt", initiateAction("terminalPrintLatestReceipt", function () {
    setCompleted();
    cardTransactionStatus.stop();
  }, showError));
});
