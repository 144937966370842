"use strict";

pos.factory("discountOptionsService", function (posConfig, $timeout, receipt, eventBus, discountOptionsRepository) {
  "use strict";

  var service = {};
  service.getDiscountOptions = function () {
    var discountOptions = discountOptionsRepository.api.getDiscountOptions();
    return discountOptions;
  };
  return service;
});
