"use strict";

window.pos = angular.module("pos", ["ui.router", "angular-storage", "ngAnimate", "pascalprecht.translate", "tmh.dynamicLocale", "ngCookies", "ngSanitize", "ddd-auth", "mgcrea.ngStrap", "720kb.datepicker", "angular-iscroll", "angular-loading-bar", "ngTouch", "chart.js", "dddAssets", "ngToast"]).run(function ($rootScope, $state, $stateParams, $interval, $animate, backend, posInfoRepository, versionService, idleChecker, healthChecker) {
  // just make sure that the version service is the very first thing being run
  versionService.getVersion();
  $rootScope.$state = $state;
  $rootScope.showKeys = false;
  $rootScope.showWidgets = false;
  $rootScope.isPaymentPage = false;
  $rootScope.$stateParams = $stateParams;
  $rootScope.selectedItem = false;
  $rootScope.selectedCustomer = false;
  $rootScope.isDownPaymentBooking = false;
  $rootScope.$watch(function () {
    return backend.defaultDevice;
  }, function (index) {
    var device = backend.getDeviceByIndex(index);
    if (device) {
      posInfoRepository.api.saveDeviceConnection(device);
    }
  });
  $rootScope.showLeftAside = function () {
    $rootScope.$broadcast("showLeftAside");
  };
  $rootScope.showRightAsideNotificationCenter = function () {
    $rootScope.$broadcast("showRightAsideNotificationCenter");
  };
  $rootScope.showRightAsideArmSelector = function () {
    $rootScope.$broadcast("showRightAsideArmSelector");
  };
  $rootScope.currentDate = new Date();
  var updateTime = $interval(function () {
    $rootScope.currentDate = new Date();
  }, 1000, 0); // every second
  healthChecker.init();
  // Logic below disallows users to have multiple tabs
  var pageOpenedOn = Date.now().toString();
  localStorage.setItem("openPage", pageOpenedOn);
  window.addEventListener("focus", function () {
    var latestPageOpenedOn = localStorage.getItem("openPage");
    if (latestPageOpenedOn !== pageOpenedOn) {
      $rootScope.$state.go("invalidTabs");
    }
  });
  $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
    if (fromState.name === "invalidTabs") {
      event.preventDefault();
      $rootScope.$state.go("invalidTabs");
    }
  });

  // Event for redirecting a route if the option is set.
  // It needs to be set manually, it does not happen automatically by ui-router
  $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
    if (IN_DEBUG_MODE) console.log("stateChangeError:");
    if (IN_DEBUG_MODE) console.log(error);
    if (error && error.routeTo) $state.go(error.routeTo);
  });
}).config(function ($stateProvider, $urlRouterProvider, $touchProvider, backendProvider) {
  backendProvider.apiUrl = "DDD_API_URL_PLACEHOLDER";
  //$qProvider.errorOnUnhandledRejections(false);

  $touchProvider.ngClickOverrideEnabled(true);
  var widgets = {
    templateUrl: "views/widgets/widget-list",
    controller: "WidgetsCtrl"
  };
  var selectConnector = {
    templateUrl: "views/payment/payment-widget",
    controller: "PayWidgetCtrl"
  };
  var navigation = {
    templateUrl: "views/partials/rightmenu-wrapper",
    controller: "RightMenuNavCtrl"
  };
  $urlRouterProvider.otherwise("/");
  $stateProvider.state("root", {
    "abstract": true,
    resolve: {
      init: function init(posConfig) {
        return posConfig.init();
      }
    },
    views: {
      header: {
        templateUrl: "views/shared/header",
        controller: "HeaderCtrl"
      }
    }
  }).state("root.split", {
    "abstract": true,
    views: {
      "content@": {
        templateUrl: "views/shared/threesplit",
        controller: function controller($log) {
          $log.debug("Creating three split view");
        }
      }
    }
  }).state("root.twosplit", {
    "abstract": true,
    views: {
      "content@": {
        templateUrl: "views/shared/twosplit",
        controller: function controller($log) {
          $log.debug("Creating twosplit split view");
        }
      }
    }
  }).state("root.login", {
    url: "/",
    views: {
      "content@": {
        templateUrl: "views/login/login",
        controller: "ClerkLoginCtrl"
      }
    }
  }).state("root.logout", {
    views: {
      "content@": {
        controller: function controller($state, clerkLoginService) {
          clerkLoginService.logout();
          $state.go("root.login");
        }
      }
    }
  }).state("root.split.pos", {
    url: "/pos",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/receipt/receipt",
        controller: "ReceiptCtrl"
      },
      right: navigation
    }
  }).state("root.split.selectdimensioncombo", {
    url: "/select-dimension-combo",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/selectDimensionCombo/selectDimensionCombo",
        controller: "SelectDimensionComboCtrl"
      },
      right: navigation
    }
  }).state("root.split.payment", {
    url: "/payment",
    params: {
      type: null
    },
    views: {
      left: {
        templateUrl: "views/payment/payment-widget",
        controller: "PayWidgetCtrl"
      },
      middle: {
        templateUrl: "views/payment/payment",
        controller: "PaymentCtrl"
      },
      right: {
        templateUrl: "views/partials/rightmenu-wrapper",
        controller: "RightMenuPayCtrl"
      }
    }
  }).state("root.split.paymentfinished", {
    url: "/payment-finished",
    views: {
      left: {
        templateUrl: "views/payment/payment-widget",
        controller: "PayWidgetCtrl"
      },
      middle: {
        templateUrl: "views/payment/payment-finished",
        controller: "PaymentFinishedCtrl"
      },
      right: {
        templateUrl: "views/partials/rightmenu-wrapper",
        controller: "RightMenuPayCtrl"
      }
    },
    params: {
      savedReceipt: false,
      rightMenuDisabled: true
    }
  }).state("root.split.payment.receipt", {
    views: {
      screenChangeAction: {
        templateUrl: "views/partials/payment-finish-button"
      }
    }
  }).state("root.about", {
    url: "/about/{name}",
    views: {
      "content@": {
        templateUrl: "views/about/index",
        controller: function controller($scope) {
          $scope.name = $scope.$stateParams.name;
        }
      }
    }
  }).state("root.split.itemgroup", {
    url: "/itemgroup",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/itemgroup/itemgroup",
        controller: "ItemgroupCtrl"
      },
      right: navigation
    }
  }).state("root.split.shortcuts", {
    url: "/item-shortcuts",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/itemshortcuts/item-shortcuts",
        controller: "ItemShortcutsCtrl"
      },
      right: navigation
    }
  }).state("root.split.giftcertificate", {
    url: "/giftcertificate",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/giftcertificate/giftcertificate",
        controller: "GiftCertificateCtrl"
      },
      right: navigation
    }
  }).state("root.split.downpaymentcertificate", {
    url: "/downpaymentcertificate",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/downpaymentcertificate",
        controller: "DownPaymentCertificateCtrl"
      },
      right: navigation
    }
  }).state("root.split.accountdeposit", {
    url: "/account-deposit",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/depositaccount/depositaccount",
        controller: "AccountDepositCtrl"
      },
      right: navigation
    }
  }).state("root.split.sellasset", {
    url: "/sellasset",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/sellasset/sellasset",
        controller: "SellAsSetCtrl"
      },
      right: navigation
    }
  }).state("root.split.searcharticle", {
    url: "/search/:context",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/searcharticle/searcharticle",
        controller: "SearchArticleCtrl"
      },
      right: navigation
    }
  }).state("root.split.expense", {
    url: "/expense",
    resolve: {
      emptyReceipt: function emptyReceipt(receipt, $state, $q, $translate, notification) {
        // allow the state change if the receipt is empty
        if (receipt.isEmpty()) return $q.resolve();
        // if receipt is not empty reject and redirect back to pos screen
        $translate("toast/claim message").then(function (translation) {
          notification.pop("warning", translation);
        });
        return $q.reject({
          routeTo: "root.split.pos"
        });
      }
    },
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/itemgroup/itemgroup",
        controller: "ExpenseCtrl"
      },
      right: navigation
    }
  }).state("root.split.xreport", {
    url: "/xreport",
    views: {
      left: {
        templateUrl: "views/payment/payment-widget",
        controller: "PayWidgetCtrl"
      },
      middle: {
        templateUrl: "views/xreport/xreport",
        controller: "ConnectorXReportCtrl"
      },
      right: navigation
    }
  }).state("root.split.cashstatement", {
    url: "/cashstatement",
    views: {
      left: {
        templateUrl: "views/payment/payment-widget",
        controller: "PayWidgetCtrl"
      },
      middle: {
        templateUrl: "views/cashstatement/drawer-selection",
        controller: "CashStatementCtrl"
      }
    }
  }).state("root.split.cashstatement.drawer", {
    url: "/:id",
    views: {
      "middle@root.split": {
        templateUrl: "views/cashstatement/cashstatement",
        controller: "CashStatementCtrl"
      },
      "right@root.split": {
        templateUrl: "views/partials/rightmenu-wrapper",
        controller: "ReconcilePayCtrl"
      }
    }
  }).state("root.split.morningamount", {
    url: "/morningamount",
    views: {
      left: {
        templateUrl: "views/payment/payment-widget",
        controller: "PayWidgetCtrl"
      },
      middle: {
        templateUrl: "views/cashstatement/drawer-selection",
        controller: "MorningAmountCtrl"
      }
    }
  }).state("root.split.morningamount.drawer", {
    url: "/:id",
    views: {
      "middle@root.split": {
        templateUrl: "views/cashstatement/morningamount",
        controller: "MorningAmountCtrl"
      }
    }
  }).state("root.split.terminalfunctions", {
    url: "/cardterminalactions",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/cardterminalactions/cardterminalaction",
        controller: "CardTerminalActionCtrl"
      },
      right: {
        templateUrl: "views/partials/rightmenu-wrapper",
        controller: "CardTerminalRightMenuCtrl"
      }
    }
  }).state("root.split.journals", {
    url: "/journals",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/cardterminalactions/journals",
        controller: "JournalsCtrl"
      },
      right: navigation
    }
  }).state("root.split.saft", {
    url: "/saft",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/cardterminalactions/saft",
        controller: "SaftCtrl"
      },
      right: navigation
    }
  }).state("root.split.parkedreceipts", {
    url: "/parked",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/receipt/parked-receipts",
        controller: "ParkedReceiptsCtrl"
      },
      right: navigation
    }
  }).state("root.split.findreceipt", {
    url: "/receipts",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/receipt/findreceipt",
        controller: "FindReceiptCtrl"
      },
      right: navigation
    }
  }).state("root.split.picklist", {
    url: "/picklist",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/picklist/picklist",
        controller: "PicklistCtrl"
      },
      right: navigation
    }
  }).state("root.split.customer-purchases", {
    url: "/receipts/:customerNo",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/receipt/findreceipt",
        controller: "FindReceiptCtrl"
      },
      right: navigation
    }
  }).state("root.split.widgetpane", {
    url: "/widget/{widgetName}",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/widgets/main-pane",
        cache: false,
        controller: "mainPaneWidgetCtrl"
      },
      right: navigation
    }
  }).state("root.split.revidefindcustomer", {
    url: "/findcustomer",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/revide/findcustomer",
        cache: false,
        controller: "RevideIntegrationController as revide"
      },
      right: navigation
    }
  }).state("debug", {
    url: "/debug",
    views: {
      "content@": {
        templateUrl: "views/debug/debug",
        controller: "DebugController"
      }
    }
  }).state("root.split.pickweborder", {
    url: "/pickweborder/{orderNo}",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/weborder/pickweborder",
        controller: "PickWeborderController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-searchcustomer", {
    url: "/downpayment/searchcustomer",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/search-customer",
        controller: "DownPaymentSearchCustomerController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-selectcustomer", {
    url: "/downpayment/selectcustomer",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/select-customer",
        controller: "DownPaymentSelectCustomerController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-editcustomer", {
    url: "/downpayment/editcustomer",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/edit-customer",
        controller: "DownPaymentEditCustomerController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-selectbillingaddress", {
    url: "/downpayment/selectbillingaddress",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/select-billingaddress",
        controller: "DownPaymentSelectBillingAddressController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-newaddress", {
    url: "/downpayment/newaddress",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/new-address",
        controller: "DownPaymenNewAddressController"
      },
      right: navigation
    },
    params: {
      isBillingAddress: false
    }
  }).state("root.split.downpayment-shipment", {
    url: "/downpayment/shipment",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/shipment",
        controller: "DownPaymentShipmentController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-shipmentaddress", {
    url: "/downpayment/shipmentaddress",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/shipment-address",
        controller: "DownPaymentShipmentAddressController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-shipmentstore", {
    url: "/downpayment/shipmentstore",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/shipment-store",
        controller: "DownPaymentShipmentStoreController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-articledetails", {
    url: "/downpayment/articledetails",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/article-details",
        controller: "DownPaymentArticleDetailsController"
      },
      right: navigation
    }
  }).state("root.split.check-downpayment", {
    url: "/downpayment/check",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/check-downpayment",
        controller: "CheckDownpaymentController"
      },
      right: navigation
    }
  }).state("root.split.downpayment-print", {
    url: "/downpayment/print",
    views: {
      "content@": {
        templateUrl: "views/downpayment/downpaymentprint",
        controller: "DownPaymentPrintController"
      }
    },
    params: {
      savedReceipt: false
    }
  }).state("root.split.downpayment-findbooking", {
    url: "/downpayment/bookings/:customerNo",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/downpaymentfindbooking",
        controller: "DownPaymentFindBookingController"
      },
      right: navigation
    }
  }).state("root.split.stock-lookup", {
    url: "/article/stocklookup",
    views: {
      left: widgets,
      middle: {
        templateUrl: "views/downpayment/stockLookup",
        controller: "StockLookupController"
      },
      right: navigation
    }
  }).state("invalidTabs", {
    url: "/invalidTabs",
    views: {
      "content@": {
        templateUrl: "views/invalidTabs"
      }
    }
  });
})
/*
.config(function($touchProvider) {
  $touchProvider.ngClickOverrideEnabled(true);
})
*/.config(function ($asideProvider) {
  angular.extend($asideProvider.defaults, {
    container: "body",
    html: true
  });
}).config(function ($logProvider) {
  $logProvider.debugEnabled(IN_DEBUG_MODE);
})

// region 3rd party module configuration, i18n, l10n, angular-snap
.config(function ($translateProvider, tmhDynamicLocaleProvider) {
  "use strict";

  tmhDynamicLocaleProvider.localeLocationPattern("assets/language/l10n/angular-locale_{{locale}}.js");
  $translateProvider.useStaticFilesLoader({
    prefix: "assets/language/i18n/json/",
    suffix: ".json"
  });
  $translateProvider.useSanitizeValueStrategy("escaped");
  $translateProvider.fallbackLanguage("en-gb");
  $translateProvider.preferredLanguage("en-gb");
}).config(function (ngToastProvider) {
  ngToastProvider.configure({
    animation: "slide",
    timeout: 5000,
    additionalClasses: "toasts"
  });
}).config(function ($animateProvider) {
  $animateProvider.classNameFilter(/ng-toast/);
});

//#region Directives
//#endregion

//#region Filters
pos.filter("getByProperty", function () {
  "use strict";

  return function (propertyName, propertyValue, collection) {
    var i = 0,
      len = collection.length;
    for (; i < len; i++) {
      if (collection[i][propertyName] == +propertyValue) {
        return collection[i];
      }
    }
    return null;
  };
});

// set the max precision in calculation - we use the same precision as C#'s decimal type
Decimal.config({
  precision: 30
});
Decimal.prototype.toDecimalPlacesUnrounded = toDecimalPlacesUnrounded;
function toDecimalPlacesUnrounded(dp) {
  var numberString = this.toString();
  var parts = numberString.split(".");
  // if there's no decimal point then there's nothing to do
  if (parts.length === 1) return new Decimal(this);
  var decimalPoints = parts[1];
  var unroundedDecimalPoints = decimalPoints.substr(0, dp);
  var unroundedDecimal = parts[0] + "." + unroundedDecimalPoints;
  return new Decimal(unroundedDecimal);
}
(function () {
  var decimal = Decimal;
  Decimal = function Decimal(value, base) {
    if (typeof value === "string") {
      value = value.replace(",", ".");
    }
    if (value === "" || isNaN(value)) {
      return new decimal(NaN, base);
    }
    var d = new decimal(value, base);
    return d;
  };
  Decimal.min = decimal.min;
  Decimal.max = decimal.max;
  Decimal.config = decimal.config;
  Decimal.exp = decimal.exp;
  Decimal.ln = decimal.ln;
  Decimal.log = decimal.log;
  Decimal.noConflict = decimal.noConflict;
  Decimal.pow = decimal.pow;
  Decimal.random = decimal.random;
  Decimal.sqrt = decimal.sqrt;
})();
moment.fn.toJSON = function () {
  return this.format();
};
if (typeof String.prototype.startsWith != "function") {
  String.prototype.startsWith = function (str) {
    return this.slice(0, str.length) == str;
  };
}
if (typeof String.prototype.endsWith != "function") {
  String.prototype.endsWith = function (str) {
    return this.slice(-str.length) == str;
  };
}
if (typeof Array.prototype.indexOfDecimal != "function") {
  Array.prototype.indexOfDecimal = function (decimal) {
    for (var i = 0; i < this.length; i++) {
      if (this[i].toNumber() == decimal.toNumber()) {
        return i;
      }
    }
    return -1;
  };
}
