"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
pos.factory("eventBus", ["$rootScope", "events", "$q", function ($rootScope, events, $q) {
  var eventBus = {};
  var eventNames = flatten(events);
  var asyncEventListeners = {};

  /**
   * Subscribe to an event when it is triggered on the event bus.
   * @param {string} eventName The event to subscribe to. These can be found in the eventBus.value.js file.
   * @param {scope} scope The $scope of the subscriber. This is used to destroy the event handler when the subscriber is destroyed.
   * @param {function} callback The actual function to call.
   */
  eventBus.subscribe = function (eventName, scope, callback) {
    return ifValidEvent(eventName, function () {
      var handler = $rootScope.$on(eventName, callback);
      if (scope) {
        scope.$on("$destroy", handler);
      } else {
        return handler;
      }
    });
  };
  eventBus.subscribeAsync = function (eventName, scope, callback) {
    return ifValidEvent(eventName, function () {
      if (!asyncEventListeners[eventName]) {
        asyncEventListeners[eventName] = [];
      }
      asyncEventListeners[eventName].push(callback);
      if (scope) {
        scope.$on("$destroy", function () {
          var idx = asyncEventListeners[eventName].indexOf(callback);
          asyncEventListeners[eventName].splice(idx, 1);
        });
      }
    });
  };

  /**
   * Notifies all subscribers of the given event.
   * @param {string} eventName The event to emit. This event must be registed in the eventBus.value.js file
   */
  eventBus.notify = function (eventName, args) {
    return ifValidEvent(eventName, function () {
      return $rootScope.$emit(eventName, args);
    });
  };
  eventBus.notifyAsync = function (eventName, args) {
    return ifValidEvent(eventName, function () {
      var callbacks = asyncEventListeners[eventName] || [];
      var cbPromises = callbacks.map(function (cb) {
        return cb(args);
      });
      return $q.all(cbPromises).then(function (resolved) {
        if (resolved) {
          return resolved.reduce(function (pre, curr) {
            return pre && curr;
          }, true);
        } else {
          return true;
        }
      });
    });
  };
  eventBus.events = events;

  /**
   * Flattens the given object, so you are left with an array containing all the property values of the object
   * @param {object} obj The object to flatten
   */
  function flatten(obj) {
    var values = [];
    var keys = Object.keys(obj);
    for (var i = 0; i < keys.length; i++) {
      if (_typeof(obj[keys[i]]) == "object") {
        values = values.concat(flatten(obj[keys[i]]));
      } else {
        values.push(obj[keys[i]]);
      }
    }
    return values;
  }

  /**
   * If the given eventname is valid, execute the callback.
   * @param {string} eventName The name of the event
   * @param {function} callback The callback if the event is valid
   */
  function ifValidEvent(eventName, callback) {
    if (eventNames.includes(eventName)) {
      return callback();
    } else {
      console.error("Unregistered event used:", eventName);
    }
  }
  return eventBus;
}]);
