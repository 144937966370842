"use strict";

pos.directive("loyaltyintersolveWidget", function (loyaltyIntersolveService, notification, $translate, $timeout, keypadManager, posConfig, clerkLoginService, $log) {
  return {
    templateUrl: "views/widgets/widget-loyaltyintersolve",
    link: function link(scope, ele, attr) {
      var i18nCardNotFound;
      var i18nCommsError;
      var i18nNoResults;
      var i18nMissingDetails;
      scope.firstname = "";
      scope.lastname = "";
      scope.zipcode = null;
      scope.town = "";
      scope.email = "";
      scope.street = "";
      scope.housenumber = null;
      scope.mobile = null;
      scope.searching = false;
      scope.i18nInputPlaceholder = "Placeholder";
      scope.i18nLastName = "Last name";
      scope.i18nTown = "Town";
      scope.i18nZipCode = "Zip code";
      scope.i18nEmail = "Email";
      scope.i18nFirstName = "First name";
      scope.i18nStreet = "Street";
      scope.i18nHouseNr = "House number";
      scope.i18nMobile = "Mobile number";
      scope.i18nAddDetails = "Add details";
      scope.i18nAddMembercard = "Add membercard";
      $translate(["widget/intersolve/inputplaceholder", "widget/intersolve/cardnotfound", "widget/intersolve/commserror", "widget/intersolve/noresults", "widget/intersolve/missingdetails", "widget/intersolve/lastname", "widget/intersolve/town", "widget/intersolve/zipcode", "widget/intersolve/email", "widget/intersolve/firstname", "widget/intersolve/street", "widget/intersolve/housenr", "widget/intersolve/mobile", "widget/intersolve/adddetails", "widget/intersolve/addmembercard"]).then(function (translations) {
        scope.i18nInputPlaceholder = translations["widget/intersolve/inputplaceholder"];
        i18nCardNotFound = translations["widget/intersolve/cardnotfound"];
        i18nCommsError = translations["widget/intersolve/commserror"];
        i18nNoResults = translations["widget/intersolve/noresults"];
        i18nMissingDetails = translations["widget/intersolve/missingdetails"];
        scope.i18nLastName = translations["widget/intersolve/lastname"];
        scope.i18nTown = translations["widget/intersolve/town"];
        scope.i18nZipCode = translations["widget/intersolve/zipcode"];
        scope.i18nEmail = translations["widget/intersolve/email"];
        scope.i18nFirstName = translations["widget/intersolve/firstname"];
        scope.i18nStreet = translations["widget/intersolve/street"];
        scope.i18nHouseNr = translations["widget/intersolve/housenr"];
        scope.i18nMobile = translations["widget/intersolve/mobile"];
        scope.i18nAddDetails = translations["widget/intersolve/adddetails"];
        scope.i18nAddMembercard = translations["widget/intersolve/addmembercard"];
      });
      scope.customers = [];
      loyaltyIntersolveService.loadAttachedCustomer().then(function (customer) {
        scope.loyaltyCustomer = customer;
      })["catch"]();
      scope.lookupCustomer = function (cardId) {
        scope.newMembercard = false;
        loyaltyIntersolveService.getLoyaltyCustomer(cardId).then(function (response) {
          scope.baseUrl = response.membercardUrl;
          scope.branch = response.branch;
          if (response.cardStatus === "Pending") {
            notification.pop("warn", "Card is not assigned to a user, click 'Add membercard'");
            scope.loyaltyCustomer = {
              customerId: cardId,
              customerDisplayName: "New card",
              email: "",
              balance: 0,
              currency: "",
              toGo: 0
            };
            scope.newMembercard = cardId;
          } else if (response.customerStatus === "Check") {
            notification.pop("warn", i18nMissingDetails);
            scope.missingDetails = true;
            scope.loyaltyCustomer = response;
          } else {
            scope.loyaltyCustomer = response;
          }
          $timeout(keypadManager.hide, 100);
        })["catch"](function (errorResponse) {
          if (errorResponse.status === 400) {
            notification.pop("info", i18nCardNotFound);
            $timeout(keypadManager.hide, 100);
          } else notification.pop("error", i18nCommsError, errorResponse.message);
        });
      };
      scope.searchForCustomer = function () {
        loyaltyIntersolveService.searchForLoyaltyCustomer(scope.firstname, scope.lastname, scope.street, scope.zipcode, scope.town, scope.housenumber, scope.email, scope.mobile).then(function (customers) {
          if (customers === null) {
            notification.pop("warn", i18nNoResults);
          } else {
            scope.customers = customers;
          }
        })["catch"](function (errorResponse) {
          notification.pop("error", i18nCommsError, errorResponse.message);
        });
      };
      scope.toggleCustomer = function (customer) {
        scope.activeCustomer = scope.activeCustomer == customer ? null : customer;
      };
      scope.selectCustomer = function (customer) {
        scope.cardId = customer.customerId;
        scope.lookupCustomer(customer.customerId);
        scope.clearSearch();
      };
      scope.clearSearch = function () {
        scope.searching = false;
        scope.activeCustomer = null;
        scope.customers = [];
      };
      scope.backToSearch = function () {
        scope.activeCustomer = null;
        scope.customers = [];
      };
      scope.clearCustomer = function () {
        scope.loyaltyCustomer = null;
        scope.missingDetails = false;
        scope.newMembercard = null;
        scope.cardId = "";
        loyaltyIntersolveService.clearCustomer();
      };
      scope.addMembercard = function () {
        scope.newMembercard = null;
        var url = getURL(scope.cardId);
        window.open(url, "_blank");
      };
      scope.addDetails = function () {
        scope.missingDetails = false;
        var url = getURL(scope.cardId);
        window.open(url, "_blank");
      };
      function getURL(membercard) {
        var url = scope.baseUrl + "?branch=" + scope.branch + "&pos=" + posConfig.posId + "&cashier=" + clerkLoginService.currentUser().identifier;
        if (membercard != "") url = url + "&membercard=" + membercard;
        $log.debug("Intersolve redirect URL: " + url);
        return url;
      }
    }
  };
});
