"use strict";

angular.module("ddd-auth").factory("tokenInterceptor", function ($injector, $q) {
  function replayRequest(config, deferredPromise) {
    var $http = $injector.get("$http");
    $http(config).then(function (response) {
      deferredPromise.resolve(response);
    }, function (replayRequestError) {
      deferredPromise.reject(replayRequestError);
    });
  }
  return {
    request: function request(config) {
      // only inject auth headers on requests sent to our own API or to Virtual Connector
      if (!config.device || !config.device.isApi && !config.device.isVirtual) {
        return config;
      }
      var tokenService = $injector.get("tokenService");
      config.headers = config.headers || {};
      var token = tokenService.getToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
      if (config.device.isVirtual) {
        config.headers["ConnectorId"] = config.device.id;
        config.headers["ReceiptPrinterMacAddress"] = config.device.virtualConfiguration.receiptPrinterMacAddress;
        config.headers["LabelPrinterMacAddress"] = config.device.virtualConfiguration.labelPrinterMacAddress;
      }
      return config;
    },
    responseError: function responseError(rejection) {
      var tokenService = $injector.get("tokenService");
      // stop here if:
      // 1: the status is not 401 or 403 (unauth codes)
      // 2: config.device is not set
      // 3: config.device is set, but it's not an API call (i.e. a call to an ARM device)
      if (rejection.status !== 401 && rejection.status !== 403 || !rejection.config.device || rejection.config.device && !rejection.config.device.isApi && !rejection.config.device.isVirtual) {
        return $q.reject(rejection);
      }
      if (rejection.config.url === tokenService.getRequestTokenUrl()) {
        return $q.reject(rejection);
      }
      var deferred = $q.defer();
      tokenService.registerTokenRequestedCallbacks(function () {
        replayRequest(rejection.config, deferred);
      });
      if (!tokenService.requestingToken) {
        tokenService.refreshToken().then(null, function (refreshError) {
          deferred.reject(refreshError);
        });
      }
      return deferred.promise;
    }
  };
}).config(function ($httpProvider) {
  $httpProvider.interceptors.push("tokenInterceptor");
});
