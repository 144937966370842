"use strict";

pos.factory("kegaService", function (kegaRepository) {
  var service = {};
  service.getWebOrders = function () {
    return kegaRepository.api.getWebOrders().then(function (response) {
      response.data.date = new Date(response.data.date);
      return response.data;
    });
  };
  return service;
});
