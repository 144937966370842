"use strict";

(function () {
  "use strict";

  angular.module("pos").controller("RevideIntegrationController", RevideIntegrationController);
  RevideIntegrationController.$inject = ["revideRepository", "receipt", "$state", "notification", "$translate", "keypadManager", "hideDivSuppressor", "eventBus"];
  function RevideIntegrationController(revideRepository, receipt, $state, notification, $translate, keypadManager, hideDivSuppressor, eventBus) {
    var vm = this;
    buildCustomerSearchParameters();
    vm.paginationSize = 20;
    vm.after = 0;
    vm.showFilter = true;
    vm.searchCustomer = searchCustomer;
    vm.buildCustomerSearchParameters = buildCustomerSearchParameters;
    vm.reset = function () {
      vm.showSearch = false;
      vm.searchResult = null;
      vm.searchParams.socialSecurityNumber.value = "";
      vm.searchParams.email.value = "";
      vm.searchParams.phonenumber.value = "";
    };
    function buildCustomerSearchParameters() {
      vm.searchParams = {
        socialSecurityNumber: {
          name: "Social Security Number",
          value: ""
        },
        email: {
          name: "Email",
          value: ""
        },
        phonenumber: {
          name: "Phone Number",
          value: ""
        }
      };
      $translate(["widget/customerFilter/ssn", "widget/customerFilter/searchEmail", "widget/customerFilter/phoneNumber"]).then(function (translations) {
        vm.searchParams.socialSecurityNumber.name = translations["widget/customerFilter/ssn"];
        vm.searchParams.email.name = translations["widget/customerFilter/searchEmail"];
        vm.searchParams.phonenumber.name = translations["widget/customerFilter/phoneNumber"];
      });
    }
    function findCustomer() {
      if (!vm.searchParams.socialSecurityNumber.value && !vm.searchParams.email.value && !vm.searchParams.phonenumber.value) {
        notification.pop("warning", "Please enter a search criteria.");
      }
      if (vm.searchParams.socialSecurityNumber.value) {
        vm.searchResult = revideRepository.api.findCustomerBySsn(vm.searchParams.socialSecurityNumber.value).then(handleCustomerResult);
      } else if (vm.searchParams.email.value) {
        revideRepository.api.findCustomerByEmail(vm.searchParams.email.value).then(handleCustomerResult);
      } else if (vm.searchParams.phonenumber.value) {
        revideRepository.api.findCustomerByPhoneNumber(vm.searchParams.phonenumber.value).then(handleCustomerResult);
      }
    }
    function searchCustomer() {
      keypadManager.hide();
      if (hideDivSuppressor.target) hideDivSuppressor.target.hide();
      vm.after = 0;
      vm.fetchFailed = false;
      vm.searchResult = null;
      findCustomer();
    }
    vm.attachCustomer = function (customer) {
      receipt.attachCustomer(customer.memberNumber, customer.email);
      $state.go("root.split.pos");
      eventBus.notify(eventBus.events.customer.attach, customer);
    };
    function handleCustomerResult(response) {
      if (response && response.data) {
        vm.showSearch = false;
        vm.searchResult = response.data;
      }
    }
  }
})();
