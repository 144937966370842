"use strict";

pos.factory("revideRepository", function (backendHelper, posConfig, eventBus) {
  var backend = backendHelper.backend;
  var repo = {};
  var params = null;
  loadConfig();
  repo.api = {
    findCustomerBySsn: function findCustomerBySsn(ssn) {
      return backend.api.get("api/revide/contact/ssn/" + encodeURIComponent(ssn), params);
    },
    findCustomerByEmail: function findCustomerByEmail(email) {
      return backend.api.get("api/revide/contact/email/" + encodeURIComponent(email), params);
    },
    findCustomerByPhoneNumber: function findCustomerByPhoneNumber(phoneNumber) {
      return backend.api.get("api/revide/contact/phonenumber/" + encodeURIComponent(phoneNumber), params);
    },
    postTransaction: function postTransaction(transaction) {
      return backend.api.post("api/revide/transaction", transaction, params);
    }
  };
  function loadConfig() {
    params = {
      headers: {
        apikey: posConfig.getShopFeatures().revide.values.apikey
      }
    };
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
  return repo;
});
