"use strict";

/**
 * Manager factory service for the on-screen keyboard. Inject this into your controller
 * if you want to show or hide the keyboard programatically. Registering inputs is primarily
 * done by the onscreen-keys directive, but can also be used from a controller if needed.
 * Register keypad is only done by the global keypad directive.
 * @param  {$timeout}                 angular's $timeout service
 * @return {keypadManager}            The keypadManager service for using in controllers
 */
pos.factory("keypadManager", function ($timeout, $parse, inputService, $window, $log, $rootScope) {
  var keypadManager = {};
  var keypad;

  /**
   * Register the keypads API methods
   * @param  {object} _keypad The functions we need exposed from the keypad to register which input it should use, etc.
   */
  keypadManager.registerKeypad = function (_keypad) {
    keypad = _keypad;
  };

  /**
   * Register the active input. Primarily used by the onscreen-keys directive
   * @param  {DOMElement}     input       Input DOM element
   * @param  {function}       onEnter     Callback function for the OK button
   * @param  {boolean}        forceNumpad
   */
  keypadManager.registerInput = function (input, onEnter, onDenomination) {
    keypad.registerInput(input, onEnter, onDenomination);
  };

  /**
   * Show the onscreen keyboard for an input element.
   * If called with no parameters it will use the last registered input element as
   * receiver of keypad input. If an inputElement is passed this will be saved as
   * the last used input element.
   * @param  {DOMELement|null|undefined} inputElement input to use as receiver of keypad buttons presses
   * @param  {function} onEnter      Callback function for the OK button
   */
  keypadManager.show = function (inputElement, onEnter, forceNumpad, isNumericOnly, onDenomination, denominationCurrency) {
    inputService.isDirty = false;
    if (isNumericOnly) $rootScope.IsNumericOnly = true;
    keypad.showKeys(inputElement, onEnter, forceNumpad, onDenomination, denominationCurrency);

    /**
     * Hide inputs below keypad, to prevent caret-bleeding
     */
    if (inputElement != null) {
      var $keypadElement = angular.element(".keyboard-wrap"),
        inputOffset = inputElement[0].getBoundingClientRect();

      // Add a 400ms delay to ensure the the keypad height has been rendered properly
      setTimeout(function () {
        // Validate the input position relative to the keypad.
        if ($window.innerHeight - $keypadElement.outerHeight() <= inputOffset.top) {
          inputElement.addClass("below-keypad");
        }
      }, 400);
    }
  };
  keypadManager.setVoucherInformation = function (voucher) {
    keypad.setVoucherInformation(voucher);
  };
  keypadManager.clearVoucherInformation = function () {
    keypad.clearVoucherInformation();
  };
  keypadManager.isPassword = function (isPassword) {
    keypad.isPassword(isPassword);
  };

  /**
   * Hides the keypad
   */
  keypadManager.hide = function (noEvent) {
    // hide disables global input, so we must only call hide if keypad was shown
    // otherwise we'll end up disabling the global input directive instead
    $rootScope.IsNumericOnly = false;
    if (keypadManager.isVisible()) {
      keypad.hideKeys(noEvent);
    }
  };

  /**
   * Returns keypad status
   */
  keypadManager.isVisible = function () {
    return keypad.isVisible();
  };

  /**
   * Get the start and end index of the current selection for an input element
   *
   * Chrome does not allow selection on number inputs so we try out different ways
   * if we have to change type to allow selection, we have to use $timeout and
   * allow the rest of the function to continue inside that timeout.
   *
   * slectionEnd wont be correct on the FIRST run unless we use the timeout
   * BUT! Only if the input is populated by any other source than the keypad
   * @param  {DOMElement} input           The input element to get selection on
   * @param  {function}   continueFunc    Callback which takes start and end
   *                                      index as parameters
   * @return {object}                     Has the selectionStart and selectionEnd properties
   */
  keypadManager.getSelection = inputService.getSelection;

  /**
   * Select range of text in an input element
   * @param  {Number}          start                zero-based index of start position of selection
   * @param  {Number}          end                  zero-based index of end position of selection
   * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
   */
  keypadManager.select = function (start, end, optionalUnboundInput) {
    inputService.select(start, end, optionalUnboundInput || keypad.getRegisteredInput());
  };
  /**
   * Select all the text in an input element
   * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
   */
  keypadManager.selectAll = function (optionalUnboundInput) {
    inputService.selectAll(optionalUnboundInput || keypad.getRegisteredInput());
  };

  /**
   * Used to return the id of the registeredInput
   */
  keypadManager.getRegisteredInput = function () {
    return keypad.getRegisteredInput();
  };

  /**
   * Used to return the reference to the current set onEnter function
   */
  keypadManager.getRegisteredOnEnter = function () {
    return keypad.getRegisteredOnEnter();
  };
  return keypadManager;
});

//
/**
 * Directive for enabling focus on an input field to activate the on-screen keyboard.
 *
 * Usage:
 * <input onscreen-keys[="onEnterFunc()"] [onscreen-keys-force-numpad='boolean']> or
 * <input onscreen-keys [on-enter="onEnterFunc()"] (this works along side ddd-capture-global-input
 * which also uses on-enter)
 *
 * If you need the input to register it self as the current active input (for showing the keypad
 * programatically without knowing the element) you can use the onscreen-keys-activator='variable' attribute
 *
 * <input onscreen-keys[="onEnterFunc()"] [onscreen-keys-activator='variable']>
 */
pos.directive("onscreenKeys", function (keypadManager, $parse, $document, $timeout) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      var isNumericOnly = attrs.isNumericOnly ? attrs.isNumericOnly.toLowerCase() === "true" : false;
      var forceNumpad = attrs.onscreenKeysForceNumpad ? attrs.onscreenKeysForceNumpad.toLowerCase() === "true" : false;
      var autoSelect = attrs.onscreenKeysAutoSelect ? attrs.onscreenKeysAutoSelect.toLowerCase() === "true" : false;
      var attrValue = attrs.onscreenKeys;
      var attrDenominationValue = attrs.onDenomination;
      ele.attr("readonly", "readonly");
      if (!attrValue || attrValue === "onscreen-keys") {
        attrValue = attrs.onEnter;
      }
      var hideKeypad = function hideKeypad() {
        $document[0].activeElement.blur();
      };
      var func = attrValue ? $parse(attrValue) : hideKeypad;
      var onEnter = !func ? null : function () {
        func(scope);
      };
      var hide = function hide() {
        $timeout(keypadManager.hide, 0);
      };
      var denominationFunc = attrDenominationValue ? $parse(attrDenominationValue) : hideKeypad;
      var onDenomination = !denominationFunc ? null : function () {
        denominationFunc(scope);
      };
      keypadManager.registerInput(ele, onEnter, onDenomination);
      ele.on("focus", function (event, data) {
        $timeout(function () {
          keypadManager.show(ele, onEnter, forceNumpad, isNumericOnly);
        }, 0);
        if (autoSelect) keypadManager.selectAll();
      });
      ele.on("blur", hide);
      ele.on("$destroy", hide);
      var activator = attrs.onscreenKeysActivator;
      if (activator) {
        scope[activator] = function () {
          keypadManager.show(ele, onEnter);
        };
      }
    }
  };
});
pos.directive("keypad", function ($state, versionService, posStorage) {
  return {
    restrict: "E",
    controller: function controller(inputService, $timeout, $q) {
      var input;
      var onEnter;
      var onDenomination;
      var lowercase = false;
      var version = versionService.getVersion();
      this.getRegisteredInput = function () {
        return input;
      };
      this.getRegisteredOnEnter = function () {
        return onEnter;
      };
      this.registerInput = function (inputElement, onEnterFunc, onDenominationFunc) {
        input = inputElement;
        onEnter = onEnterFunc;
        onDenomination = onDenominationFunc;
      };
      this.isExpanded = function () {
        if (!input) throw "Keypad not paired with an input element";
        return input.attr("type") === "text" || input.attr("type") === "password";
      };
      this.clearInput = function () {
        input.val("");
        input.trigger("change");
      };
      this.deleteChar = function () {
        if (input.val().length > 0) {
          input.val(input.val().substr(0, input.val().length - 1));
          input.trigger("change");
        }
      };
      this.enter = function () {
        if (onEnter) {
          onEnter();
        }
      };
      this.addCharacter = function (obj) {
        // convert decimal seperator function to string
        // the function binds on compile time so we need to pass in a function instead of a literal
        // since the localization happens after the directive is compiled
        if (typeof obj === "function") obj = obj();
        if (obj.length > 2) {
          obj = String.fromCharCode(obj.substr(2, obj.length));
        }
        if (lowercase) obj = obj.toLowerCase();
        inputService.insertString(obj, input);
      };
      this.denominationClick = function (obj) {
        inputService.setString(obj, input);
        if (onDenomination) {
          onDenomination();
        } else if (onEnter) {
          onEnter();
        }
      };
      this.isLowerCase = function () {
        return lowercase;
      };
      this.toggleCase = function () {
        lowercase = !lowercase;
      };
      this.keysClear = function () {
        if (input.val().length > 0) {
          input.val("");
          input.trigger("change");
        }
      };
      this.keysCopy = function () {
        if (!input.val().length == 0) {
          navigator.clipboard.writeText(input.val());
        }
      };
      this.keysClip = function () {
        if (!input.val().length == 0) {
          navigator.clipboard.writeText(input.val());
          input.val("");
          input.trigger("change");
        }
      };
      this.keysPaste = function () {
        navigator.clipboard.readText().then(function (text) {
          return inputService.insertString(text, input);
        });
      };
      if (!navigator.clipboard) {
        navigator.clipboard = {
          writeText: function writeText(text) {
            return posStorage.set("keysClipboard", text);
          },
          readText: function readText() {
            var defer = $q.defer();
            var text = posStorage.get("keysClipboard");
            defer.resolve(text);
            return defer.promise;
          }
        };
      }
    }
  };
});
pos.directive("overlay", function (keypadManager, $document, $timeout, inputService, $locale, $http, $templateCache, posConfig, $log, eventBus) {
  return {
    restrict: "A",
    scope: true,
    require: "^keypad",
    templateUrl: "views/partials/key-wrapper",
    link: function link(scope, ele, attrs, keypadCtrl) {
      loadConfig();
      $log.debug("Shop language: " + scope.shopLanguage);
      $http.get("views/keyboard/keyboard-" + scope.shopLanguage).then(function success(response) {
        $templateCache.put("views/keyboard/keyboard-" + scope.shopLanguage, response.data);
        scope.mainView = "views/keyboard/keyboard-" + scope.shopLanguage;
      }, function error(response) {
        scope.mainView = "views/keyboard/keyboard-da-dk";
      });
      scope.expandedKeys = false;
      scope.showKeys = false;
      scope.isPassword = false;
      scope.denominationCurrency = null;
      scope.voucher = null;
      scope.placeholder = "";
      var showKeysArguments = [];
      var focusSelfTriggered = false;
      ele.on("mousedown", function (event) {
        event.preventDefault();
      });
      var reflector;
      function toggleReflection(input) {
        if (typeof reflector === "function") {
          reflector();
        }
        if (!input) return;
        scope.placeholder = input.attr("placeholder");
        reflector = scope.$watch(function () {
          return input.val();
        }, function (value) {
          return scope.reflection = value;
        });
      }
      function groupDenominations(denominations) {
        if (!denominations || denominations.length == 0) return null;
        var groupSize = 5;
        var groupedDenominations = [];
        var index = 0;
        while (index < denominations.length) {
          groupedDenominations.push(denominations.slice(index, groupSize + index));
          index += groupSize;
        }
        return groupedDenominations;
      }
      var keypad = {
        showKeys: function showKeys(inputElement, onEnter, forceNumpad, onDenomination, denominationCurrency) {
          // when showKeys is triggered programatically from keypadManager, we'll use the focus event
          // to put the input in focus, which in return will end back here in showKeys. The only catch is that
          // in the second trip to showKeys we will have lost the original function arguments but the one from the
          // onscreen-keys directive. Therefore we'll keep track on whether this invocation is due to our own focus roundtrip
          // and then use the arguments from the previous invocation.

          if (!focusSelfTriggered) showKeysArguments = arguments;else focusSelfTriggered = false;
          var activeElementIsRegisteredInput = $document[0].activeElement === keypadCtrl.getRegisteredInput()[0];
          if (scope.showKeys && activeElementIsRegisteredInput) return;
          if (showKeysArguments[0] /* inputElement */) {
            keypadCtrl.registerInput(showKeysArguments[0] /* inputElement */, showKeysArguments[1] /* onEnter */, showKeysArguments[3] /* onDenomination */);
          }
          if (!activeElementIsRegisteredInput) {
            // let the next function invocation know that we triggered this ourselves
            focusSelfTriggered = true;
            keypadCtrl.getRegisteredInput().focus();
            scope.denominationCurrency = groupDenominations(denominationCurrency);
            return;
          }
          toggleReflection(keypadCtrl.getRegisteredInput());
          scope.expandedKeys = !(showKeysArguments[2] /* forceNumpad */ || !keypadCtrl.isExpanded());
          scope.showKeys = true;
          $timeout(function () {
            inputService.enableGlobalCapture(keypadCtrl.getRegisteredInput(), showKeysArguments[1] /* onEnter */);
          });
        },
        hideKeys: function hideKeys(noEvent) {
          if (!noEvent) {
            eventBus.notify(eventBus.events.keyboard.hidden);
          }
          toggleReflection();
          scope.showKeys = false;
          var input = keypadCtrl.getRegisteredInput() || {};
          inputService.disableGlobalCapture(input);
          $timeout(function () {
            var activeElementIsRegisteredInput = $document[0].activeElement === input[0];
            if (activeElementIsRegisteredInput) {
              $document[0].activeElement.blur();
            }
          });

          // Reset any input field, that has the below-keypad class.
          angular.element(".below-keypad").length && angular.element(".below-keypad").removeClass("below-keypad");
        },
        isVisible: function isVisible() {
          return scope.showKeys;
        },
        isPassword: function isPassword(_isPassword) {
          scope.isPassword = _isPassword;
        },
        setVoucherInformation: function setVoucherInformation(voucher) {
          scope.voucher = voucher;
        },
        clearVoucherInformation: function clearVoucherInformation() {
          scope.voucher = null;
        },
        registerInput: keypadCtrl.registerInput,
        getRegisteredInput: keypadCtrl.getRegisteredInput,
        getRegisteredOnEnter: keypadCtrl.getRegisteredOnEnter
      };
      keypadManager.registerKeypad(keypad);
      scope.toggleExpandedKeys = function () {
        scope.expandedKeys = !scope.expandedKeys;
      };
      scope.hideKeyboard = function () {
        return keypad.hideKeys();
      };
      scope.decimalSeparator = function () {
        return $locale.NUMBER_FORMATS.DECIMAL_SEP;
      };
      scope.enter = keypadCtrl.enter;
      scope.toggleCase = keypadCtrl.toggleCase;
      scope.keypadArray = keypadCtrl.addCharacter;
      scope.denominationClick = keypadCtrl.denominationClick;
      scope.deleteChar = keypadCtrl.deleteChar;
      scope.clearInput = keypadCtrl.clearInput;
      scope.isLowerCase = keypadCtrl.isLowerCase;
      scope.keysSelectAll = keypadCtrl.keysSelectAll;
      scope.keysClear = keypadCtrl.keysClear;
      scope.keysCopy = keypadCtrl.keysCopy;
      scope.keysClip = keypadCtrl.keysClip;
      scope.keysPaste = keypadCtrl.keysPaste;
      function loadConfig() {
        scope.shopLanguage = posConfig.getLocale();
      }
      eventBus.subscribe(eventBus.events.pos.newConfig, scope, loadConfig);
    }
  };
});
pos.directive("inview", function ($locale) {
  return {
    restrict: "A",
    require: "keypad",
    scope: {
      target: "=",
      onEnter: "&"
    },
    templateUrl: "views/partials/key-pad-inview",
    link: function link(scope, ele, attrs, keypadCtrl) {
      var input = angular.element(scope.target);
      keypadCtrl.registerInput(input, scope.onEnter);
      scope.decimalSeparator = function () {
        return $locale.NUMBER_FORMATS.DECIMAL_SEP;
      };
      scope.enter = keypadCtrl.enter;
      scope.toggleCase = keypadCtrl.toggleCase;
      scope.keypadArray = keypadCtrl.addCharacter;
      scope.deleteChar = keypadCtrl.deleteChar;
      scope.clearInput = keypadCtrl.clearInput;
      scope.getDotOrComma = keypadCtrl.getDotOrComma;
    }
  };
});
