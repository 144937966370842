"use strict";

pos.factory("toggleNavigation", function ($compile) {
  var toggleNavigation = {};

  /**
   * Navigation Menu
   */
  toggleNavigation.toggle = function (targetElement, activeClass) {
    targetElement.toggleClass(activeClass);
  };
  toggleNavigation.open = function (targetElement, activeClass) {
    if (directtargetElement) {
      targetElement.addClass(activeClass);
    } else if (!targetElement.hasClass(activeClass)) {
      targetElement.addClass(activeClass);
    }
  };
  toggleNavigation.close = function (targetElement, activeClass) {
    if (targetElement.hasClass(activeClass)) {
      targetElement.removeClass(activeClass);
    }
  };
  return toggleNavigation;
});
