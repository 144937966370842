"use strict";

pos.directive("voucheractionModal", ["eventBus", function (eventBus) {
  var controller = function controller($scope) {
    $scope.action = function (btn) {
      if (btn.cancel) {
        $scope.content.isVisible = false;
      }
      btn.action();
    };
  };
  return {
    templateUrl: "views/posModal/voucheractionModal-directive",
    controller: controller,
    scope: {
      content: "="
    }
  };
}]);
