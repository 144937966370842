"use strict";

pos.factory("picklistRepository", function (backend) {
  var picklist = {};
  picklist.api = {
    getPicklist: function getPicklist(type, order, printFromLastPrint, creationDate, fromDate, toDate) {
      var query = {};
      query.type = type;
      query.order = order;
      if (printFromLastPrint) query.printFromLastPrint = printFromLastPrint;
      query.creationDate = moment(new Date()).format();
      if (fromDate) query.fromDate = fromDate;
      if (toDate) query.toDate = toDate;
      return backend.api.post("api/picklist", query);
    },
    getPicklistTimestamp: function getPicklistTimestamp() {
      return backend.api.get("api/picklist/timestamp");
    }
  };
  return picklist;
});
