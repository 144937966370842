"use strict";

pos.directive("nextFocus", function ($timeout) {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      var next = angular.element(attrs.nextFocus);
      ele.on("blur", function () {
        $timeout(function () {
          next.trigger("focus");
        }, 0);
      });
    }
  };
});
pos.directive("focusOnClick", function ($timeout) {
  return {
    restrict: "EA",
    link: function link(scope, ele, attrs) {
      var next = angular.element(attrs.focusOnClick);
      var trigger = function trigger() {
        next.focus();
      };
      ele.on("click", trigger);
      ele.on("tap", trigger);
    }
  };
});
pos.directive("fullscreenChange", function (fullscreenMode) {
  return {
    restrict: "A",
    link: function link(scope, ele, attr) {
      // Act on F11
      ele.on("keydown", function (event) {
        if (event.which == 122) {
          event.preventDefault();
          fullscreenMode.toggle();
        }
      });
    }
  };
});
pos.directive("autoFocus", function () {
  return {
    restrict: "A",
    link: function link(scope, ele, attrs) {
      ele.focus();
    }
  };
});
pos.directive("dddInteger", function () {
  return {
    restrict: "A",
    require: "ngModel",
    link: function link(scope, ele, attr, ctrl) {
      ctrl.$parsers.unshift(function (viewValue) {
        return parseInt(viewValue);
      });
    }
  };
});
pos.factory("hideDivSuppressor", function () {
  return {
    isSuppressed: false
  };
});
pos.directive("dddHideDiv", function ($document, $timeout, hideDivSuppressor) {
  return {
    restrict: "A",
    scope: {
      target: "@"
    },
    link: function link(scope, ele, attr) {
      var divClicked = false;
      var suppress = false;
      var target = angular.element(scope.target);
      hideDivSuppressor.target = target;
      target.hide();
      ele.on("mousedown", function () {
        divClicked = true;
      });
      ele.on("click", function () {
        if (!suppress && $document[0].activeElement != target[0]) {
          $timeout(function () {
            target.show();
            target.addClass("is-visible");
            target.trigger("focus");
          }, 0);
        }
        suppress = divClicked = false;
      });
      target.on("click", function (event) {
        $timeout(hide, 0);
      });
      target.on("blur", function (event) {
        suppress = divClicked;
        $timeout(hide, 0);
      });
      function hide() {
        var suppressor = hideDivSuppressor.isSuppressed;
        hideDivSuppressor.isSuppressed = false;
        target.removeClass("is-visible");
        if (!suppressor) return target.hide();
        function focusBack() {
          suppressor.off("blur", focusBack);
          $timeout(function () {
            target.trigger("focus");
          }, 0);
        }
        suppressor.on("blur", focusBack);
      }
    }
  };
});
pos.directive("dddDontHideDiv", function (hideDivSuppressor) {
  return {
    restrict: "A",
    link: function link(scope, ele, attr) {
      function suppress() {
        hideDivSuppressor.isSuppressed = ele;
      }
      if (ele.is("input") || ele.is("select")) {
        ele.on("mousedown", suppress);
        ele.on("click", suppress);
        ele.on("tap", suppress);
        return;
      }
      ele.on("mousedown", function (event) {
        event.preventDefault();
      });
      ele.on("click", function (event) {
        event.stopPropagation();
      });
    }
  };
});
pos.directive("readonlyWhenVisible", function () {
  return {
    link: function link(scope, ele, attr) {
      var followElement = angular.element(attr.readonlyWhenVisible);
      scope.$watch(function () {
        return followElement.is(":visible");
      }, function (visible) {
        if (visible) ele.attr("readonly", "readonly");else ele.removeAttr("readonly");
      });
    }
  };
});
angular.forEach(["showWhenFocusOn", "hideWhenFocusOn"], function (directiveName) {
  pos.directive(directiveName, /*@ngInject*/function ($document) {
    var xnor = function xnor(a, b) {
      return !(a ^ b);
    };
    return {
      restrict: "A",
      link: function link(scope, ele, attr) {
        var elementStr = attr[directiveName];
        if (!elementStr.startsWith("#")) elementStr = "#" + elementStr;
        var checkElement = angular.element(elementStr)[0];
        scope.$watch(function () {
          return $document[0].activeElement;
        }, function (activeElement) {
          if (xnor(directiveName === "showWhenFocusOn", activeElement === checkElement)) ele.show();else ele.hide();
        });
      }
    };
  });
});
pos.directive("iscrollToMe", function ($interval) {
  return {
    link: function link(scope, ele, attr) {
      // we need to find the iscroll instance, but it might not be available yet when the directive is compiled
      // we'll keep looking for it until we find it
      var context = {};
      var iscroll;
      context.locateIscroll = $interval(function () {
        // use the passed in iscroll variable name or try and guess where it is
        iscroll = scope[attr.iscrollToMe] || scope.instance || scope.iscroll || scope.iscrollInstance;
        if (iscroll) $interval.cancel(context.locateIscroll);
      }, 200, 50); // try for 10 secs to find the iscroll instance then drop it

      // clean up if the user navigates away from the view quickly
      ele.on("$destroy", function () {
        $interval.cancel(context.locateIscroll);
      });

      // scroll to the element when it's focused
      ele.on("focus", function () {
        if (!iscroll) return;
        iscroll.scrollToElement(ele[0], 0, true, true);
      });
    }
  };
});
