"use strict";

pos.factory("strackTrace", function () {
  return StackTrace;
});
pos.factory("errorService", function (strackTrace, $injector, $log) {
  var service = {};
  var stringify = function stringify(stackframes) {
    var stringifiedStack = stackframes.map(function (sf) {
      return sf.toString();
    }).join("\n");
    return stringifiedStack;
  };
  var errback = function errback(err) {
    $log.debug(err.message);
  };
  service.getStackTrace = function (error) {
    var deferred = $injector.get("$q").defer();
    strackTrace.fromError(error).then(function (stackframes) {
      deferred.resolve(stringify(stackframes));
    })["catch"](deferred.reject);
    return deferred.promise;
  };
  return service;
});
