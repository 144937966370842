"use strict";

pos.factory("backdrop", function ($rootScope) {
  var backdrop = {};
  backdrop.exists = function (backdropElement) {
    return verifyBackdrop(backdropElement);
  };
  function verifyBackdrop(backdropElement) {
    var backdropBool = backdropElement.length == 1 ? true : false;
    return backdropBool;
  }
  return backdrop;
});
