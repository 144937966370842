"use strict";

pos.directive("kegaWidget", function (kegaService, receipt, $filter, $translate) {
  return {
    templateUrl: "views/widgets/widget-kega",
    controller: function controller($scope, Widgets) {
      $scope.widgetsService = Widgets;
      $scope.hideWidgets = function () {
        $scope.widgetsService.showWidgets = false;
        $("#center-content.center-content--downscale").removeClass("center-content--downscale");
      };
    },
    link: function link(scope, ele, attr) {
      scope.ordersVisible = false;
      scope.loadingOrders = false;
      scope.ordersInitiallyFetched = false;
      scope.error = false;
      var i18nWeborderPayment;
      $translate("widget/kega/weborderpayment").then(function (translation) {
        i18nWeborderPayment = translation;
      });
      scope.toggleOrder = function (order) {
        scope.activeOrder = scope.activeOrder == order ? null : order;
      };
      scope.getWebOrders = function () {
        // getWebOrders(); ordersVisible = !ordersVisible
        if (scope.ordersInitiallyFetched) {
          scope.ordersVisible = false;
        }
        scope.loadingOrders = true;
        scope.getWebOrders();
      };

      // Fetch available products
      scope.getWebOrders = function () {
        if (scope.ordersInitiallyFetched) {
          //scope.ordersVisible = false;
        }

        // Indicate loading
        scope.loadingOrders = true;

        // Fetch products
        kegaService.getWebOrders().then(function (response) {
          var ordersQuery = $filter("orderBy")(response, "-orderNr");
          angular.forEach(ordersQuery, function (item) {
            item.price = new Decimal(item.price);
          });

          // Check if the new orders are equal to the old
          if (scope.availableOrders != ordersQuery) {
            scope.availableOrders = ordersQuery;
            scope.ordersInitiallyFetched = true;
          }
          scope.loadingOrders = false;
          scope.ordersVisible = true;
          scope.error = false;
        }, function (error) {
          scope.loadingOrders = false;
          scope.error = error;
        });
      };

      // Add order to receipt
      scope.addToReceipt = function (weborder, index) {
        receipt.addWebOrder(weborder.price, i18nWeborderPayment, weborder.itemGroup, weborder.vat, weborder.orderNr, true);
        scope.activeOrder = null;

        // Close widget drawer
        scope.hideWidgets();
      };
      scope.isAddedToReceipt = function (weborder) {
        var items = receipt.getReceipt().items;
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          if (item.webOrderNumber === weborder.orderNr) return true;
        }
        return false;
      };
    }
  };
});
