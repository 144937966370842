"use strict";

pos.factory("offlineDevice", function ($q, $log) {
  function handleOfflineRequest(config) {
    var deferred = $q.defer();
    var urlParts = config.url.split("/");
    var func = urlParts.pop();
    $log.debug("default device route: " + func);
    switch (func) {
      case "AddPayment":
        deferred.resolve(handlePayment(config));
        break;
      case "PopReceiptQueue":
        deferred.resolve(response(config));
        break;
      case "OpenCashDrawer":
        deferred.resolve(response(config));
        break;
      case "WakeUp":
        deferred.resolve(response(config));
        break;
      case "TerminalAbortCardPayment":
        deferred.resolve(response(config));
        break;
      case "GetTerminalStatus":
        setTimeout(function () {
          return deferred.resolve(response(config, {
            CardName: "VISA",
            CardId: 601,
            DisplayText: "Great success",
            Result: 0,
            ResponseCode: "Z3"
          }));
        }, 3000);
        break;
      case "CompleteReceipt":
        deferred.resolve(response(config));
        break;
      case "TerminalPrintLatestReceipt":
        deferred.resolve(response(config));
        break;
      default:
        return $q.reject(response(config, {
          message: "Unsupported route"
        }, 500));
    }
    return deferred.promise;
  }
  function handlePayment(config) {
    var data = config.data.paymentRequest;
    var payment = {
      Quantity: 1,
      Position: 1,
      VoucherNumber: "",
      PaymentType: data.paymentType,
      Amount: data.amount,
      ResponseAmount: data.amount,
      Rounding: data.rounding,
      ResponseRounding: data.rounding,
      ExchangeRate: data.exchangerate,
      Message: "OK",
      Completed: data.paymentType === "Card" ? false : true,
      CardNumber: data.paymentType === "Card" ? 600 : 0,
      CardName: "",
      CardReceipt: "",
      CurrencyCode: data.foreignCurrencyCode,
      CurrencyName: data.currencyName,
      Cashregister: data.posId
    };
    var paymentType = payment.PaymentType.toLowerCase();
    if (["creditvoucher", "giftcertificate", "downpayment", "foreigngiftcertificate"].includes(paymentType)) payment.VoucherNumber = data.contextInfo;
    return response(config, payment);
  }
  function response(config, data, status) {
    return {
      status: status || 200,
      data: {
        d: data || {}
      },
      config: config || {}
    };
  }
  return handleOfflineRequest;
});
