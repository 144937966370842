"use strict";

pos.directive("productImageWidget", function () {
  return {
    templateUrl: "views/widgets/widget-product-image",
    controller: function controller($scope, $rootScope, eventBus) {
      $scope.currentItem = $rootScope.selectedItem;
      eventBus.subscribe(eventBus.events.pos.itemSelected, $scope, function (_, item) {
        return onSelectedItemChange(item);
      });
      eventBus.subscribe(eventBus.events.sale.completed, $scope, function () {
        return onSelectedItemChange(null);
      });
      function onSelectedItemChange(item) {
        $scope.currentItem = item;
      }
    }
  };
});
