"use strict";

// eager load the intersolve service if the loyaltyintersolve widget is in the widget list
// this ensures that all the services watchers and POS manipulations get's triggered
// immediately instead of waiting for the widget to load the service (due to the depdenency).
// Basically we're creating a "fake" dependency with the $injector service to eager load the intersolve service
pos.run(function (posConfig, $injector, $log, eventBus) {
  function loadConfig() {
    // we need to await that posConfig is ready, then we know the latest config is loaded
    posConfig.whenReady().then(function () {
      var widgets = posConfig.getWidgets();
      // search for loyaltyintersolve in the list of widgets
      for (var i = 0; i < widgets.length; i++) {
        var widget = widgets[i];
        if (widget.name === "payplaza") {
          // eager load the service by getting it with the $injector
          $log.debug("eager load payplaza");
          return $injector.get("payplazaService");
        }
      }
    });
  }
  loadConfig();
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
});
pos.factory("payplazaService", function (posStorage, payPlazaRepository, $q, receipt, $rootScope, notification, inputService, $window, $translate, paymentService, payPlazaCard, defaultPaymentType, posConfig, sales, $timeout, backend, $interval, $log) {
  var service = {
    // the connected state we get from PayPlaza from getConnectionState
    scannerConnected: false,
    // describes our intent - are we trying to have the scanner running or not
    scanning: false,
    // holds the request promise if getBarcode is running
    barcodePollPromise: null
  };
  // If CardTerminal is recovering and status is ongoing, we need to check again.
  var terminalRecovery = {
    isInitialized: false,
    isOngoing: false,
    retries: 0,
    maxRetries: 10,
    status: "",
    response: "",
    intervalDelay: 5000,
    blockedPaymentLineId: null
  };
  var intervalPromise;
  var noScanningStates = ["root.login"];
  var doingOnFocusCheck = false;
  var i18nNoAssociationId,
    // Cannot start scanner without association Id. Please reassociate the scanner
    i18nNoSucessActivation,
    // Scanner activation unsuccessful
    i18nBarcodeFailed,
    // Failed to get barcode from Pair2Pay. Service stopped.
    i18nAssFailedTitle,
    // Scanner assocation unsuccessful
    i18nAssFailedBody,
    // An error occured while attempting to pair the Pair2Pay Barcode Scanner. Please try again.
    i18nClrkSignatureRqrd,
    // Cashier signature is required
    i18nCustSignatureRqrd; // Customer signature is required

  var payplazaPaymentStrategy = {
    makePayment: payPlazaCard.makePayment,
    responseSteps: [{
      success: defaultPaymentType.addPaymentToReceipt
    }, {
      success: payPlazaCard.handlePayplazaTransaction
    }, {
      success: defaultPaymentType.isPaymentEnough,
      error: payPlazaCard.cardPaymentRejected
    }, {
      success: defaultPaymentType.doChange
    }, {
      success: defaultPaymentType.guaranteedFinishReceipt
    }]
  };
  paymentService.setPaymentStrategy("payPlazaCard", payplazaPaymentStrategy);
  var paymentTypeConfigs = posConfig.getPaymentTypeConfigs();
  for (var i = 0; i < paymentTypeConfigs.length; i++) {
    var paymentTypeConfig = paymentTypeConfigs[i];
    if (paymentTypeConfig.type !== "Card") continue;
    paymentTypeConfig.paymentStrategy = "payPlazaCard";
    break;
  }

  // when the service is loaded we'll try and see if there's an active scanning
  // connection we can resume
  tryResumeScanning();

  // when the service is loaded we'll try and see if there's an unAcknowledge
  // transaction that needs to be wrapped up
  recoverTerminalStatus();
  $translate(["toast/payplaza/noassociationid", "toast/payplaza/nosuccessactivation", "toast/payplaza/barcodefailed", "toast/payplaza/associationfailedtitle", "toast/payplaza/associationfailedbody", "toast/payplaza/cashiersignaturerequired", "toast/payplaza/customersignaturerequired"]).then(function (translations) {
    i18nNoAssociationId = translations["toast/payplaza/noassociationid"];
    i18nNoSucessActivation = translations["toast/payplaza/nosuccessactivation"];
    i18nBarcodeFailed = translations["toast/payplaza/barcodefailed"];
    i18nAssFailedTitle = translations["toast/payplaza/associationfailedtitle"];
    i18nAssFailedBody = translations["toast/payplaza/associationfailedbody"];
    i18nClrkSignatureRqrd = translations["toast/payplaza/cashiersignaturerequired"];
    i18nCustSignatureRqrd = translations["toast/payplaza/customersignaturerequired"];
  });

  // If we start the app and the nonce exists then it's because we need to continue the process
  // of obtaining an associationId - the nonce will be cleared once used
  // this only happens when the service is loaded which in turn only happens when
  // the widget has been loaded at least once
  if (typeof $window.onfocus !== "function") {
    $window.onfocus = function () {
      // iOS Safari apparently triggers onfocus twice when resuming the browser, so we do some dirty hacking
      // to make sure that the logic is only executed once
      if (doingOnFocusCheck) return;
      doingOnFocusCheck = true;
      $log.debug(moment().format("HH:mm:ss.SS") + ": window on focus fired - checking if nonce exists");
      if (getScannerNonce()) {
        $log.debug(moment().format("HH:mm:ss.SS") + ": nonce existed - getting association Id from webservice");
        payPlazaRepository.getAssociationId(getScannerNonce()).then(function (response) {
          var associationId = response.data.getAssociationIdResponse.associationId;
          setScannerNonce(null); // destroy after using to get associationId
          return payPlazaRepository.finalizeAssociation(associationId).then(function (response) {
            var data = response.data.finalizeAssociationResponse;
            var scanServiceBaseUrl = data.scanServiceBaseURL;
            var ecrId = data.ecrId;
            setScannerAssociationId(associationId);
            // only set the ECR ID if it has a truthy value so we don't risc removing it
            if (ecrId) service.setEftId(ecrId);
            payPlazaRepository.setScannerServiceBaseUrl(scanServiceBaseUrl);
            service.scannerConnected = true;
            service.startScannerPull();
          });
        })["catch"](scannerAssociationErrorHandler)["finally"](function () {
          doingOnFocusCheck = false;
        });
        // if there's no nonce but instead an associationId, the app probably has just been suspended
        // and we can continue pulling
      } else {
        tryResumeScanning();
      }
    };
  }

  // turn off the scanner on the login page and turn it on, on every other page
  // TODO: Fix this - it doesn't work right now
  $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
    if (noScanningStates.indexOf(toState.name) >= 0 && service.barcodePollPromise) stopGetBarcode();
    if (noScanningStates.indexOf(toState.name) === -1 && !service.barcodePollPromise && service.scannerConnected) tryResumeScanning();
  });

  // when the service is created we want to check if we already know an associationId and if so
  // we check if the connection with it is still active and begin polling the scanner
  function tryResumeScanning() {
    service.scanning = true;
    $log.debug(moment().format("HH:mm:ss.SS") + ": checking association Id to see if we should getConnectionState");
    if (getScannerAssociationId() && !isScanning()) {
      $log.debug(moment().format("HH:mm:ss.SS") + ": getting connection state");
      payPlazaRepository.getConnectionState().then(function (response) {
        var connectionState = response.data.connectionStateResponse;
        service.scannerConnected = connectionState.isActive && connectionState.terminalIsConnected;
        if (service.scannerConnected) service.startScannerPull();else {
          notification.pop("warn", "Scanner connection closed by PayPlaza service");
          service.disconnectScanner();
        }
      })["catch"](scannerAssociationErrorHandler);
    }
  }

  // Recovery
  // When the factory is initialized, the API is queried for unAcknowledged/ongoing transaction
  function recoverTerminalStatus() {
    $log.debug("Recovering terminal status");
    var eftid = posStorage.get("eftid");
    // If there is not eftID available, the API hasn't enough information to check the status. Exiting.
    if (!eftid) return;
    initializeTerminalRecovery();
    intervalPromise = $interval(function () {
      getTerminalStatus(eftid);
    }, terminalRecovery.intervalDelay);
  }
  function initializeTerminalRecovery() {
    terminalRecovery.isOngoing = true;
    // Check if there is a card payment that's not completed. Setting it's status to awaiting receipt
    // to ensure that the user doesn't use the retry or delete functions of the card payment line.
    // Putting status to awaitingResponse displays the cancel button on the payment line, but that is
    // blocked while terminalRecovery is ongoing.
    var payments = receipt.getReceipt().payments;
    for (var i = payments.length - 1; i >= 0; i--) {
      var payment = payments[i];
      if (!payment.completed) {
        terminalRecovery.blockedPaymentLineId = payment.uuid;
        payment.awaitingResponse = true;
        payment.displayText = "Recovering Card Terminal Action. Please wait...";
        break;
      }
    }
  }
  function finalizeTerminalRecovery(recoveredPaymentId) {
    terminalRecovery.isOngoing = false;
    $interval.cancel(intervalPromise);

    // If recovered payment is not the same as the paymentLine initially blocked (can this happen?)
    // changing the awaitingResponse on the blockedPaymentLine back to false
    if (recoveredPaymentId) {
      if (recoveredPaymentId != terminalRecovery.blockedPaymentLineId) {
        var blockedPaymentLine = getPayment(terminalRecovery.blockedPaymentLineId);
        blockedPaymentLine.awaitingResponse = false;
      }
    }
  }
  function getTerminalStatus(eftid) {
    payPlazaRepository.api.getTerminalStatus(eftid).then(function (success) {
      terminalRecovery.response = success.data;
      terminalRecovery.status = terminalRecovery.response.result;
      $log.debug("TerminalRecovery Status: " + terminalRecovery.status);
      resolveTerminalStatus();
    });
  }
  function resolveTerminalStatus() {
    switch (terminalRecovery.status) {
      case "READY":
        finalizeTerminalRecovery();
        break;
      case "ONGOING":
        if (terminalRecovery.retries === 0) {
          handleOngoingRecoveredPayment(terminalRecovery.response);
          notification.pop("warning", "Card Terminal Transaction is Ongoing", "Resolving...");
        }
        terminalRecovery.retries++;
        if (terminalRecovery.retries === terminalRecovery.maxRetries) {
          finalizeTerminalRecovery();
        }
        break;
      default:
        service.acknowledgePayment();
        $interval.cancel(intervalPromise);
        if (terminalRecovery.response) {
          resolveRecoveredPayment(terminalRecovery.response);
        }
    }
  }
  function resolveRecoveredPayment(terminalResponse) {
    $log.debug("Resolving recovered payment");
    var payments = receipt.getReceipt().payments;
    var receiptPayment = getPayment(terminalResponse.paymentId);

    // TODO: Can we use something else to determine if ther clerk has the payments
    // in front of him and we should do a payment wrapup?
    if (window.location.hash == "#/payment") {
      notification.pop("warning", "CardTerminal Action Recovered", "Finishing Transaction");

      // Fill in the relevant information to the payment
      var payPlazaReceipt = angular.fromJson(terminalResponse.receipt);
      receiptPayment.cardName = "";
      receiptPayment.completed = false;
      if (terminalResponse.result === "SUCCESS") {
        receiptPayment.cardName = payPlazaReceipt.printCommandMessage ? payPlazaReceipt.printCommandMessage.appPreferredName : "";
        receiptPayment.completed = true;
      }
      receiptPayment.awaitingResponse = false;
      receiptPayment.displayText = terminalResponse.result;
      receiptPayment.cardReceipt = JSON.stringify(terminalResponse.formattedReceipt);
      receipt.persist();
      var fakeResponse = {
        config: {
          device: backend.getDeviceByIndex(backend.defaultDevice)
        },
        data: {
          d: receiptPayment
        },
        payPlazaResponse: terminalResponse
      };
      if (terminalResponse.result !== "SUCCESS") {
        // If the payment did not go through and a receipt is in place, it is printed
        fakeResponse.cardRejected = true;
        if (terminalResponse.formattedReceipt && terminalResponse.formattedReceipt.length > 0) {
          service.printPayPlazaTerminalReceipt(JSON.stringify(terminalResponse.formattedReceipt));
        }
      }
      $rootScope.$emit("payment:card:recover", fakeResponse);
    } else {
      // TODO: Check if this is login screen - then login user and finalize the payment
      // to get the correct data to DdD backoffice.
      notification.pop("warning", "Card Terminal Recovery", "Receipt printed");
      // Sending the terminal receipt for the transaction to the ARM for printing
      if (terminalResponse.formattedReceipt && terminalResponse.formattedReceipt.length > 0) {
        service.printPayPlazaTerminalReceipt(JSON.stringify(terminalResponse.formattedReceipt));
      }
    }
    finalizeTerminalRecovery(receiptPayment.uuid);
  }
  function handleOngoingRecoveredPayment(terminalResponse) {
    // If there is an ongoing transaction, the paymentline is updated
    var payments = receipt.getReceipt().payments;
    var receiptPayment = getPayment(terminalResponse.paymentId);
    if (receiptPayment) {
      receiptPayment.completed = false;
      receiptPayment.awaitingResponse = true;
      receiptPayment.displayText = "Card Terminal Action is Ongoing. Please wait...";
    }
  }
  service.connectScanner = function () {
    setScannerNonce(null);
    setScannerAssociationId(null);
    payPlazaRepository.initializeAssociation().then(function (response) {
      var data = response.data.initializeAssociationResponse;
      var customUrl = data.customURL;
      var nonce = data.associationNonce;
      setScannerNonce(nonce);
      //var url = customUrl + '?associationNonce=' + nonce;
      var url = customUrl + "DdDPOS/getID?" + nonce;
      $log.debug(moment().format("HH:mm:ss.SS") + ": Opening deep link to: " + url);
      window.location = url;
    })["catch"](scannerAssociationErrorHandler);
  };
  service.disconnectScanner = function () {
    setScannerNonce(null);
    setScannerAssociationId(null);
    stopGetBarcode();
    service.scannerConnected = false;
  };
  service.startScannerPull = function () {
    var associationId = getScannerAssociationId();
    if (!associationId) return notification.pop("error", i18nNoAssociationId);
    payPlazaRepository.activateAssociation(associationId).then(function (response) {
      getBarcode(associationId);
    })["catch"](function (errorResponse) {
      $log.debug(errorResponse);
      notification.pop("warning", i18nNoSucessActivation);
    });
  };
  service.isScanning = isScanning;
  function isScanning() {
    if (!service.barcodePollPromise) return false;
    return !service.barcodePollPromise.isResolved;
  }
  function getBarcode(associationId) {
    $log.debug(moment().format("HH:mm:ss.SS") + ": polling barcode...");
    // save the promise so we can cancel it if needed
    service.barcodePollPromise = payPlazaRepository.getBarcode(associationId);
    // attach promise functions - note this cannot be a single line, because the promise from the last chain function will be saved then
    service.barcodePollPromise.then(function (response) {
      $log.debug(response);
      var data = response.data.barcodeResponse;
      if (!data || data === "GET_BARCODE_ERROR" || data.barcode === "No data") return getBarcode(associationId);
      if (response.status === -1 /* aborted */ || data.error === "NOT ACTIVE") return;
      inputService.insertString(data.barcode);
      inputService.doEnter();
      getBarcode(associationId);
    })["catch"](function (errorResponse) {
      // if the server returns a 500 Internal Server Error - then we just stop
      if (errorResponse.status === 500) {
        $log.debug(errorResponse);
        notification.pop("warning", i18nBarcodeFailed);
        return;
      }

      // if the error occured because we stopped scanning
      // (i.e. we forced an "early timeout" to abort) then don't restart it
      if (!service.scanning) return;

      // if we're still trying to be running, but something happend then just try again and again
      $timeout(function () {
        getBarcode(associationId);
      }, 1000);
    });
  }
  function stopGetBarcode() {
    if (service.barcodePollPromise) service.barcodePollPromise.abort();
    service.barcodePollPromise = null;
    service.scanning = false;
  }
  function scannerAssociationErrorHandler(errorResponse) {
    $log.debug(errorResponse);
    notification.pop("warning", i18nAssFailedTitle, i18nAssFailedBody);
    service.disconnectScanner();
  }
  $rootScope.$on("payment:cancelPayPlazaTransaction", function (event, payment) {
    if (terminalRecovery.isOngoing) {
      notification.pop("warning", "POS is recovering and checking terminal status");
    } else {
      service.cancelPayPlazaTransaction(payment);
    }
  });
  $rootScope.$on("payment:payplazaEftMissing", function (event) {
    notification.pop("error", "Missing EFT ID", "You need to configure the PayPlaza EFT ID before you can pay with card");
  });
  service.getLastReceipt = function () {
    var ecrId = service.getEftId();
    var deferred = $q.defer();
    payPlazaRepository.api.getLastReceipt(ecrId).then(function (response) {
      if (response.data && response.data.receipt) {
        deferred.resolve(angular.fromJson(response.data.receipt).printCommandMessage);
      }
    }, function (errorResponse) {
      $log.debug("getLastReceipt failed");
      deferred.reject();
    });
    return deferred.promise;
  };
  service.reprintReceipt = function () {
    var ecrId = service.getEftId();
    payPlazaRepository.api.getLastReceipt(ecrId).then(function (response) {
      if (response.data && response.data.formattedReceipt) {
        // Stringify-ing in order to have the terminalreceipt here in same format as in POS receipt
        service.printPayPlazaTerminalReceipt(JSON.stringify(response.data.formattedReceipt));
      }
    }, function (errorResponse) {
      $log.debug("getLastReceipt failed");
    });
  };
  service.printPayPlazaTerminalReceipt = function (receipt) {
    return sales.printTerminalReceipt(receipt);
  };

  /**
   * Service handler for sending payment requests to payment devices. It handles exchange rates and rounding.
   * @param  {String} type               Payment type, i.e. "Cash", "Change", "Card", etc.
   * @param  {Number} amount             Amount paid to be addede to the receipt
   * @param  {String} currencyIdentifier Same as currencyCode with added "Local".
   * @param  {String} contextInfo        Any additional info the request should carry
   * @return {promise}                   The request promise.
   */
  service.handlePayplazaTransaction = function (paymentId, receiptId, currencyName, amount) {
    var ecrId = service.getEftId(); //"ECR_K3MPOS1";//"ECR_DDDIPP";

    var deferred = $q.defer();
    payPlazaRepository.api.handleTransaction(paymentId, receiptId, currencyName, ecrId, amount).then(function (response) {
      if (response.data && response.data.result && response.data.result == "SUCCESS") {
        deferred.resolve(response);
      } else {
        deferred.reject(response);
      }
    }, deferred.reject);
    return deferred.promise;
  };
  service.cancelPayPlazaTransaction = function (payment) {
    var ecrId = service.getEftId();
    payPlazaRepository.api.cancelTransaction(ecrId, payment.uuid);
  };
  service.payPlazaCompleteSale = function () {
    var finishedReceipt = angular.copy(receipt.getReceipt());
    return payPlazaRepository.completeSale(finishedReceipt);
  };
  service.getEftId = function () {
    return posStorage.get("eftid");
  };
  service.setEftId = function (eftId) {
    posStorage.set("eftid", eftId);
  };
  service.removeEftId = function () {
    posStorage.remove("eftid");
  };
  service.acknowledgePayment = function () {
    var ecrId = service.getEftId();
    payPlazaRepository.api.acknowledgePayment(ecrId);
  };
  service.notifyClerkIfSignatureRequired = function (response) {
    try {
      var jsonReceipt = JSON.parse(response.receipt);
      if (jsonReceipt.printCommandMessage.merchantReceipt === "true") notification.pop("warning", i18nCustSignatureRqrd);
    } catch (err) {
      $log.debug("Parsing receipt not successful");
    }
    if (response.isRefund) notification.pop("warning", i18nClrkSignatureRqrd);
  };
  function getScannerAssociationId() {
    return posStorage.get("payplazaAssociationId");
  }
  function setScannerAssociationId(id) {
    posStorage.set("payplazaAssociationId", id);
  }
  function getScannerNonce() {
    return posStorage.get("payPlazaNonce");
  }
  function setScannerNonce(nonce) {
    posStorage.set("payPlazaNonce", nonce);
  }
  function getPayment(uuid) {
    // look backwards for the payment
    var payments = receipt.getReceipt().payments;
    for (var i = payments.length - 1; i >= 0; i--) {
      var payment = payments[i];
      if (payment.uuid === uuid) {
        return payment;
      }
    }
  }
  return service;
});
