"use strict";

pos.factory("portalService", function ($window) {
  var service = {};
  service.buildMessage = function (type, title, text) {
    var message = {
      title: title,
      text: text,
      type: type
    };
    return encodeURIComponent(JSON.stringify(message));
  };
  service.redirectToPortalWithMessage = function (type, title, text) {
    var message = this.buildMessage(type, title, text);
    if (IN_DEBUG_MODE) {
      console.warn("Debugging active => redirection to Portal aborted. Message was '" + title + "' and '" + text + "' ");
      return;
    }
    $window.location = "DDD_PORTAL_URL_PLACEHOLDER/#/?message=" + message;
  };
  return service;
});
