"use strict";

pos.factory("inputService", function ($document, $sniffer, $state, $timeout, versionService, $log, $rootScope) {
  var inputService = {};
  var doc = angular.element($document);
  var input;
  var onEnterFunc;
  var onDenominationFunc;
  var version = versionService.getVersion();
  var inputDelay;
  var inputBuffer = "";
  var inputPressedHandler = function inputPressedHandler(keyEvent) {
    var capturedInput = String.fromCharCode(keyEvent.charCode);
    if (!/[a-z0-9%.+-æøå]/i.test(capturedInput)) return;
    inputBuffer += capturedInput;
    cancelBufferToInputPromise();
    inputDelay = $timeout(shiftBufferToInput, 50);
  };
  function cancelBufferToInputPromise() {
    if (inputDelay) $timeout.cancel(inputDelay);
  }
  function cancelAndShiftToInput() {
    cancelBufferToInputPromise();
    shiftBufferToInput();
  }
  function shiftBufferToInput() {
    var inputString = inputBuffer;
    inputBuffer = "";
    inputService.insertString(inputString);
  }
  var inputDownHandler = function inputDownHandler(keyEvent) {
    switch (keyEvent.keyCode) {
      case 8:
        // Backspace
        cancelAndShiftToInput();
        keyEvent.preventDefault();
        inputService.doDelete(null, true);
        break;
      case 46:
        // Del
        cancelAndShiftToInput();
        inputService.doDelete();
        break;
      case 13:
        // Enter
        cancelAndShiftToInput();
        inputService.doEnter();
        break;
      case 32:
        // Space
        cancelAndShiftToInput();
        keyEvent.preventDefault();
        inputService.insertString(" ", input);
        break;
      case 27:
        // Escape
        cancelBufferToInputPromise();
        input.val("");
        input.trigger("change");
        break;
    }
  };
  inputService.isDirty = false;
  inputService.doDelete = function (inputElement, isBackspace) {
    var inputEle = inputElement || input;
    inputService.getSelection(inputEle, del);
    function del(selectionStart, selectionEnd) {
      var head = inputEle.val().substr(0, selectionStart);
      var tail = inputEle.val().substr(selectionEnd);
      if (selectionStart === selectionEnd) {
        if (isBackspace) head = head.substr(0, head.length - 1);else tail = tail.substr(1);
      }
      var newInput = head + tail;
      inputEle.val(newInput);
      inputEle.trigger("change");
    }
  };
  inputService.doEnter = function () {
    var data = {};
    data.buttonName = input.val();
    data.stateName = $state.$current.name;
    data.clientTime = new Date();
    data.posVersion = version;
    $timeout(onEnterFunc, 0);
  };
  inputService.doSpace = function (inputElement) {
    var head = inputElement.val();
    var tail = "";
    var newInput = head + " " + tail;
    inputElement.val(newInput);
    inputElement.trigger("change");
  };
  inputService.insertString = function (string, inputElement) {
    if (string) {
      var inputEle = inputElement || input;
      var currentValue = inputEle.val();
      var newValue;
      var regex = /^[0-9]*(\.|,)?[0-9]*$/;
      if ($rootScope.IsNumericOnly && !string.match(regex)) {
        $log.debug("Non allowed characters in value: " + string);
        string = "";
      }
      if (inputService.isDirty) {
        newValue = "".concat(currentValue).concat(string);
      } else {
        newValue = string.toString();
        inputService.isDirty = true;
      }
      inputEle.val(newValue);
      inputEle.trigger("change");
    }
  };
  inputService.setString = function (string, inputElement) {
    var inputEle = inputElement || input;
    inputEle.val(string);
    inputEle.trigger("change");
  };
  inputService.enableGlobalCapture = function (inputElement, onEnter, onDenomination) {
    if (inputElement[0].nodeName.toUpperCase() !== "INPUT") return;
    // disable the previous input capture if one was active
    inputService.disableGlobalCapture();

    // update the current input to the one passed here
    if (inputElement) input = inputElement;
    // update the on enter func to match the one with the input
    if (onEnter) onEnterFunc = onEnter;
    if (onDenomination) onDenominationFunc = onDenomination;
    doc.on("keypress", inputPressedHandler);
    doc.on("keydown", inputDownHandler);
  };
  inputService.disableGlobalCapture = function (inputElement) {
    // disable only if the input element matches the current one
    // or if disable is called without arguments
    if (inputElement !== input && inputElement) return;
    doc.off("keypress", inputPressedHandler);
    doc.off("keydown", inputDownHandler);
  };

  /**
   * Get the start and end index of the current selection for an input element
   *
   * Chrome does not allow selection on number inputs so we try out different ways
   * if we have to change type to allow selection, we have to use $timeout and
   * allow the rest of the function to continue inside that timeout.
   *
   * slectionEnd wont be correct on the FIRST run unless we use the timeout
   * BUT! Only if the input is populated by any other source than the keypad
   * @param  {DOMElement} input           The input element to get selection on
   * @param  {function}   continueFunc    Callback which takes start and end
   *                                      index as parameters
   * @return {object}                     Has the selectionStart and selectionEnd properties
   */
  inputService.getSelection = function (input, continueFunc) {
    var selectionStart, selectionEnd;
    try {
      selectionStart = input[0].selectionStart;
      selectionEnd = input[0].selectionEnd;
      if (continueFunc) continueFunc(selectionStart, selectionEnd);
    } catch (e) {
      var type = input.attr("type");
      input.attr("type", "text");
      $timeout(function () {
        selectionStart = input[0].selectionStart;
        selectionEnd = input[0].selectionEnd;
        input.attr("type", type);
        if (continueFunc) continueFunc(selectionStart, selectionEnd);
      }, 0);
    }
    return {
      selectionStart: selectionStart,
      selectionEnd: selectionEnd
    };
  };

  /**
   * Select range of text in an input element
   * @param  {Number}          start                zero-based index of start position of selection
   * @param  {Number}          end                  zero-based index of end position of selection
   * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
   */
  inputService.select = function (start, end, optionalUnboundInput) {
    var input = optionalUnboundInput || input;
    if (input) {
      $timeout(function () {
        setSelection(input, start, end);
      }, 0);
    }
  };

  /**
   * Select all the text in an input element
   * @param  {DOMElement|null} optionalUnboundInput An unbound input to use instead of the registered one
   */
  inputService.selectAll = function (optionalUnboundInput) {
    var input = optionalUnboundInput || input;
    if (input) {
      $timeout(function () {
        setSelection(input, 0, input[0].value.length);
      }, 0);
    }
  };

  /**
   * Private helper method used by select and selectAll
   * @param {DOMElement}  input    Input element to select on
   * @param {Number}      start    Zero-based start index
   * @param {Number}      end      Zero-based end index
   */
  function setSelection(input, start, end) {
    try {
      input[0].setSelectionRange(start, end);
    } catch (e) {
      var type = input.attr("type");
      input.attr("type", "text");
      input[0].setSelectionRange(start, end);
      input.attr("type", type);
    }
  }
  return inputService;
});
