"use strict";

pos.factory("labelTemplates", function (posConfig, $q, eventBus) {
  "use strict";

  var service = {};
  loadConfig();
  service.overlay = false;
  var labelTemplateDeffered;
  service.selectLabelTemplate = function () {
    labelTemplateDeffered = $q.defer();
    var promise = labelTemplateDeffered.promise;
    if (service.hasLabelTemplates() && service.items.length > 1) {
      service.overlay = true;
    } else {
      labelTemplateDeffered.resolve(service.items[0]);
      labelTemplateDeffered = null;
    }
    return promise;
  };
  service.printLabelTemplate = function (labelTemplate) {
    service.overlay = false;
    if (labelTemplateDeffered) {
      labelTemplateDeffered.resolve(labelTemplate);
      labelTemplateDeffered = null;
    }
  };
  service.hasLabelTemplates = function () {
    return service.items && service.items.length > 0;
  };
  service.dismissed = function () {
    service.overlay = false;
    if (labelTemplateDeffered) {
      labelTemplateDeffered.reject();
      labelTemplateDeffered = null;
    }
  };
  function loadConfig() {
    service.items = posConfig.getLabelTemplates();
  }
  eventBus.subscribe(eventBus.events.pos.newConfig, null, loadConfig);
  return service;
});
