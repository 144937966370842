"use strict";

/* Tracks now long a user has been idle.  secondsIdle can be polled 
   at any time to know how long user has been idle. */
pos.factory("idleChecker", [function () {
  var self = {
    idleTime: moment(new Date()).add(10, "minutes"),
    isIdle: function isIdle() {
      return moment(new Date()) >= self.idleTime;
    },
    reset: function reset() {
      self.idleTime = moment(new Date()).add(10, "minutes");
    },
    init: function init() {
      $(document).on("keydown", resetIdleTime);
      $(document).on("keypress", resetIdleTime);
      $(document).on("mousedown", resetIdleTime);
      $(document).on("mousemove", resetIdleTime);
    }
  };
  function resetIdleTime(event) {
    self.reset();
  }
  return self;
}]);
