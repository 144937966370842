"use strict";

pos.directive("widget", function ($compile, $interpolate) {
  return {
    template: "",
    scope: {
      options: "="
    },
    link: function link(scope, element, attributes) {
      element.append($compile("<div " + scope.options.name + '-widget class="widget-overflow"></div>')(scope));
    }
  };
}).directive("teaserWidget", function () {
  return {
    templateUrl: "views/widgets/teaser-widget"
  };
});
pos.directive("mpwidget", function ($compile, $interpolate) {
  return {
    template: "",
    scope: {
      options: "="
    },
    link: function link(scope, element) {
      element.append($compile("<" + scope.options.name + "-widget></" + scope.options.name + ">")(scope));
    }
  };
});
