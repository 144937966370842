"use strict";

pos.factory("receiptCompleterPaymentType", function ($q, backend) {
  var service = {};
  service.makePayment = function () {
    return $q.resolve({
      config: {
        device: backend.devices[backend.defaultDevice]
      }
    });
  };
  return service;
});
