"use strict";

pos.controller("DownPaymentSearchCustomerController", function ($scope, notification, $translate, $state, downpayOrderService) {
  "use strict";

  $scope.customerEmail = "";
  var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  var i18nCustomerEmailInvalid;
  $translate(["toast/invalidEmail"]).then(function (translations) {
    i18nCustomerEmailInvalid = translations["toast/invalidEmail"];
  });
  $scope.customerSearch = function (email) {
    if (email.length < 1 || !emailPattern.test(email)) {
      notification.pop("warning", i18nCustomerEmailInvalid);
      return false;
    }
    downpayOrderService.getCustomerByEmail(email).then(function (result) {
      if (result) {
        var customers = downpayOrderService.getCustomerSearch();
        if (customers.length > 1) {
          $state.go("root.split.downpayment-selectcustomer");
        } else {
          downpayOrderService.setSelectedCustomer(customers[0]);
          if (downpayOrderService.isBooking) {
            $state.go("root.split.downpayment-selectbillingaddress");
          } else {
            $state.go("root.split.pos");
          }
        }
      }
    });
  };
  $scope.customerSearchMembership = function (membership) {
    if (membership.length < 1) {
      notification.pop("warning", i18nCustomerEmailInvalid);
      return false;
    }
    downpayOrderService.getCustomerByMembership(membership).then(function (result) {
      if (result) {
        var customers = downpayOrderService.getCustomerSearch();
        if (customers.length > 1) {
          $state.go("root.split.downpayment-selectcustomer");
        } else {
          downpayOrderService.setSelectedCustomer(customers[0]);
          if (downpayOrderService.isBooking) {
            $state.go("root.split.downpayment-selectbillingaddress");
          } else {
            $state.go("root.split.pos");
          }
        }
      }
    });
  };
});
