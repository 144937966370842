"use strict";

pos.directive("autofocus", function ($timeout) {
  return {
    link: function link(scope, element, attrs) {
      $timeout(function () {
        element.focus();
      });
    }
  };
});
