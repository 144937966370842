"use strict";

pos.controller("CardTerminalRightMenuCtrl", function ($scope, $rootScope) {
  $scope.asideButtonsTemplate = "views/cardterminalactions/rightmenucardterminalNetsDK";
  $rootScope.reconciliation = function () {
    $rootScope.$broadcast("terminal:reconciliation");
  };
  $rootScope.emptybuffer = function () {
    $rootScope.$broadcast("terminal:emptybuffer");
  };
  $rootScope.canceltransaction = function () {
    $rootScope.$broadcast("terminal:canceltransaction");
  };
  $rootScope.xreport = function () {
    $rootScope.$broadcast("terminal:xreport");
  };
  $rootScope.zreport = function () {
    $rootScope.$broadcast("terminal:zreport");
  };
  $rootScope.sendofflinetrans = function () {
    $rootScope.$broadcast("terminal:sendofflinetrans");
  };
  $rootScope.printstoredeottrans = function () {
    $rootScope.$broadcast("terminal:print:storedeottrans");
  };
  $rootScope.printlastreceipt = function () {
    $rootScope.$broadcast("terminal:print:lastreceipt");
  };
  $rootScope.downloadapp = function () {
    $rootScope.$broadcast("terminal:download:app");
  };
  $rootScope.downloadcardagreements = function () {
    $rootScope.$broadcast("terminal:download:cardagreements");
  };
});
